import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Form } from "reactstrap";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Button, CheckBox, ControlledInput } from "../../ui";
import {
  useAddRoleMutation,
  useGetRoleByIdMutation,
  useUpdateRoleMutation,
} from "./roleApi";
import { responseToaster } from "../../helperFunctions";
import { IoIosCloseCircle } from "react-icons/io";
import { useRoleList } from "./roleSlice";

const parsePermissions = (permissions: any) => {
  const categories: any = {};
  const actionsSet: any = new Set();

  permissions?.forEach?.(({ value }: any) => {
    const parts = value?.split("-");
    const action = parts?.pop();
    const category = parts?.join("-");
    if (!categories[category]) {
      categories[category] = {};
    }
    categories[category][action] = true;
    actionsSet?.add?.(action);
  });

  return { categories, actions: [...actionsSet] };
};

const PermissionsTable = ({
  permissions,
  setSelectedPermissions,
  selectedPermissions,
  clearErrors,
  setIsAllSelected,
}: any) => {
  const { categories, actions } = parsePermissions(permissions);

  const handleChangePermission = (category: string, action: string) => {
    const permission = `${category}-${action}`;

    setSelectedPermissions((prevSelected: string[]) => {
      const isSelected = prevSelected.includes(permission);
      const newSelected = isSelected
        ? prevSelected.filter((p: string) => p !== permission)
        : [...prevSelected, permission];

      if (action === "list") {
        if (isSelected) {
          return newSelected.filter(
            (p: string) => !p.startsWith(`${category}-`) || p === permission
          );
        }
      } else {
        if (!isSelected && category !== "notification") {
          if (!newSelected.includes(`${category}-list`)) {
            newSelected.push(`${category}-list`);
          }
        }
      }

      const totalPermissions = permissions.length;
      const allSelected = newSelected.length === totalPermissions;
      setIsAllSelected(allSelected);
      return newSelected;
    });
  };

  useEffect(() => {
    if (selectedPermissions?.length) {
      clearErrors("permission");
    }
    // eslint-disable-next-line
  }, [selectedPermissions]);

  return (
    <table className="roleTbl" border={2}>
      <thead>
        <tr>
          <th>Module</th>
          {actions?.map?.((action: any) => (
            <th key={action} className="capitalize">
              {action}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {Object.keys?.(categories)?.map?.((category) => (
          <tr key={category}>
            <td>{category}</td>
            {actions?.map?.((action: any) => {
              const isListAction = action === "list";
              const isListChecked = selectedPermissions?.includes?.(
                `${category}-list`
              );
              let isDisabled = isListAction ? false : !isListChecked;

              if (category === "notification" && action === "create") {
                isDisabled = false;
              }
              if (category === "sales-product" && action === "report") {
                isDisabled = false;
              }

              return (
                <td key={action}>
                  <CheckBox
                    name="permission"
                    onChange={() => handleChangePermission(category, action)}
                    disabled={isDisabled || !categories?.[category]?.[action]}
                    checked={selectedPermissions.includes(
                      `${category}-${action}`
                    )}
                  />
                </td>
              );
            })}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

const RoleModal = (props: any) => {
  const { onHide, isOpen, onGet, roleData } = props;
  const [addCpisLoading, setaddCpIsLoading] = useState<boolean>(false);
  const [isAdmin, setIsAdmin] = useState<any>(true);
  const [addRole] = useAddRoleMutation();
  const [updateRole] = useUpdateRoleMutation();
  const [getRoleById] = useGetRoleByIdMutation();
  const [selectedPermissions, setSelectedPermissions] = useState<any>([]);
  const [isAllSelected, setIsAllSelected] = useState<boolean>(false);

  const roleList = useRoleList();

  const schema: any = yup.object({
    name: yup.string().required("Please enter a name"),
    permission: yup.array().min(1, "Please check a permission"),
  });

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    clearErrors,
    formState: { errors },
  }: any = useForm({
    resolver: yupResolver(schema),
  });

  const onClose = () => {
    reset();
    onHide();
    setIsAdmin(true);
    setSelectedPermissions([]);
    setIsAllSelected(false);
  };

  const getRole = async () => {
    try {
      const payload: any = {
        id: roleData?.id,
      };
      const res: any = await getRoleById(payload);
      if (res?.data?.status) {
        setFormData(res?.data);
      }
    } catch (error) {}
  };

  const setFormData = (roleData: any) => {
    if (roleData?.role?.id) {
      setValue("name", roleData?.role?.name);
      setSelectedPermissions(
        roleData?.permission?.map((value: any) => {
          return value?.name;
        })
      );
    }
  };

  useEffect(() => {
    if (isOpen) {
      if (roleData?.id) {
        getRole();
      }
    }
    // eslint-disable-next-line
  }, [isOpen, roleData?.id]);

  useEffect(() => {
    setValue("permission", selectedPermissions);
  }, [selectedPermissions, setValue]);

  const onSubmit = async (values: any) => {
    try {
      setaddCpIsLoading(true);
      const payload = {
        ...values,
        permission: selectedPermissions,
      };
      const res: any = roleData?.id
        ? await updateRole({ id: roleData?.id, ...payload }).unwrap()
        : await addRole(payload).unwrap();
      if (res?.status) {
        onClose();
        onGet?.();
      }
      responseToaster(res);
      setaddCpIsLoading(false);
    } catch (error) {
      setaddCpIsLoading(false);
    }
  };

  const preparePermissionOptions = () => {
    if (roleList?.permission?.length) {
      const roleOptions: any = roleList?.permission
        ?.map?.((val: { id: any; name: any }) => ({
          value: val?.name,
          label: val?.name,
        }))
        ?.sort((a: { name: string }, b: { name: any }) =>
          a?.name?.localeCompare?.(b?.name)
        );
      return roleOptions || [];
    }
  };

  const handleSelectAllPermissions = () => {
    const { categories, actions } = parsePermissions(
      preparePermissionOptions()
    );
    if (!isAllSelected) {
      const allPermissions = Object.keys?.(categories || {})
        ?.flatMap?.((category) =>
          actions?.map?.((action: any) => `${category}-${action}`)
        )
        ?.filter?.((perm: string) => {
          const parts = perm?.split("-");
          const action1: any = parts?.pop();
          const category1 = parts?.join("-");
          return categories?.[category1]?.[action1];
        });

      setSelectedPermissions(allPermissions);
    } else {
      setSelectedPermissions([]);
    }
    setIsAllSelected(!isAllSelected);
  };

  if (!isOpen) return null;
  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center">
      <div
        className="modal-overlay absolute w-full h-full bg-gray-900 opacity-50"
        onClick={() => {
          if (!addCpisLoading) {
            onClose();
          }
        }}
      />

      <div className="modal-container bg-chatlook-white border w-8/12 2xl:w-6/12 xl:w-7/12 lg:w-8/12  md:w-9/12 sm:w-10/12  mx-auto rounded-lg shadow-lg z-50 overflow-y-auto">
        <div className="modal-content p-6 text-left  max-h-[84vh]">
          <Form onSubmit={handleSubmit(onSubmit)}>
            <div className="modal-header flex justify-between">
              <h4 className="modal-title mb-4 font-bold capitalize">
                {`${roleData?.id ? "Update" : "Add"}`} Role
              </h4>
              {!isAdmin ? (
                <div onClick={() => onClose()} className="cursor-pointer">
                  <IoIosCloseCircle color="var(--ev-primary--)" size={22} />
                </div>
              ) : null}
            </div>
            <div className="modal-body">
              <div className="grid grid-cols-1 items-start gap-3 pt-2 pb-3">
                <ControlledInput
                  name="name"
                  label="Name"
                  placeholder="Enter Name"
                  type="text"
                  errors={errors}
                  control={control}
                />
                <div className="flex justify-between">
                  <label className={`text-sm text-ev-secondary`}>
                    Permission
                  </label>
                  <CheckBox
                    name="permission"
                    label="All Permission"
                    checked={isAllSelected}
                    onChange={handleSelectAllPermissions}
                  />
                </div>
                {/* {errors?.permission?.message ? (
                  <span className="text-red-500 text-sm">
                    {errors?.permission?.message}
                  </span>
                ) : null} */}
                <PermissionsTable
                  permissions={preparePermissionOptions()}
                  selectedPermissions={selectedPermissions}
                  setSelectedPermissions={setSelectedPermissions}
                  clearErrors={clearErrors}
                  setIsAllSelected={setIsAllSelected}
                />
                {errors?.permission?.message ? (
                  <span className="text-red-500 text-sm">
                    {errors?.permission?.message}
                  </span>
                ) : null}
              </div>
            </div>
            <div className="modal-footer pb-2">
              <div className="flex items-center justify-end gap-2">
                <Button
                  type="submit"
                  className="mt-[10px] !h-9 !text-sm"
                  isLoading={addCpisLoading}
                  isLoader={addCpisLoading}
                >
                  {roleData?.id ? "Update" : "Submit"}
                </Button>
                <Button
                  variant="Transaparent"
                  className="mt-[10px]  !text-sm !h-9"
                  disabled={addCpisLoading}
                  onClick={() => onClose()}
                >
                  Cancel
                </Button>
              </div>
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default RoleModal;
