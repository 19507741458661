import { api } from "../../../utills/api";

export const vendorApi = api.injectEndpoints({
  endpoints: (build) => ({
    getVendor: build.mutation<{ data: any }, void>({
      query: (params) => ({
        url: "/admin/vendor/get-vendor",
        body: params,
        method: "POST",
      }),
    }),
    updateVendor: build.mutation<{ data: any }, void>({
      query: (params) => ({
        url: "/admin/vendor/update-vendor",
        body: params,
        method: "POST",
      }),
    }),
    addVendor: build.mutation<{ data: any }, any>({
      query: (params) => ({
        url: "/vendor/category/add-category",
        params: params,
        method: "GET",
      }),
    }),
    getVendorById: build.mutation<{ data: any }, void>({
      query: (params) => ({
        url: "/admin/vendor/get-vendor-by-id",
        body: params,
        method: "POST",
      }),
    }),
    getShopCategory: build.mutation<{ data: any }, void>({
      query: (params) => ({
        url: "/admin/get-shop-category",
        body: params,
        method: "GET",
      }),
    }),
    deleteVendor: build.mutation<{ data: any }, void>({
      query: (params) => ({
        url: "/admin/vendor/delete-vendor",
        body: params,
        method: "POST",
      }),
    }),
    vendorMenu: build.mutation<{ data: any }, void>({
      query: (params) => ({
        url: "/admin/vendor/get-vendor-menu",
        body: params,
        method: "GET",
      }),
    }),
    vendorNote: build.mutation<{ data: any }, void>({
      query: (params) => ({
        url: "/admin/vendor/add-update-vendor-note",
        body: params,
        method: "POST",
      }),
    }),
    getVendorNote: build.mutation<{ data: any }, void>({
      query: (params) => ({
        url: "/admin/vendor/get-vendor-note-list",
        body: params,
        method: "POST",
      }),
    }),
    deleteVendorNote: build.mutation<{ data: any }, void>({
      query: (params) => ({
        url: "/admin/vendor/delete-vendor-note",
        body: params,
        method: "POST",
      }),
    }),
  }),
});

export const {
  useGetVendorMutation,
  useUpdateVendorMutation,
  useAddVendorMutation,
  useGetVendorByIdMutation,
  useGetShopCategoryMutation,
  useDeleteVendorMutation,
  useVendorMenuMutation,
  useVendorNoteMutation,
  useGetVendorNoteMutation,
  useDeleteVendorNoteMutation,
} = vendorApi;
export const {
  endpoints: {
    getVendor,
    addVendor,
    updateVendor,
    getVendorById,
    getShopCategory,
    deleteVendor,
    vendorMenu,
    vendorNote,
    getVendorNote,
    deleteVendorNote,
  },
} = vendorApi;
