import React, { Fragment, useEffect, useState } from "react";
import { FILTER } from "../../../constant";
import {
  Button,
  ControlledDatePicker,
  Icon,
  Input,
  Select,
  Table,
  TableRow,
} from "../../../ui";
import { useTransactionList } from "./transactionSlice";
import { useGetTransactionMutation } from "./transactionApi";
import {
  dateFormatter,
  prepareVendorOptions,
  responseToaster,
  seperator,
} from "../../../helperFunctions";
import StatusStyle from "../../common/StatusStyle";
import CustomeBreadcrumbs from "../../common/CustomeBreadcrumbs";

import { useForm } from "react-hook-form";
import { transactionStatus } from "./mock";
import { useRole } from "../../auth/authSlice";
import { useNavigate } from "react-router-dom";

const breadcrumbs = [{ label: "Subscriptions" }, { label: "Transaction" }];

const initialValues = {
  startDate: [null, null],
};

const Transaction = () => {
  const [getTransaction, { isLoading }] = useGetTransactionMutation();
  const transactionList = useTransactionList();
  const [tableData, setTableData] = useState<any>([]);
  const [filter, setFilter] = useState<any>(FILTER);
  const [showDiv, setShowDiv] = useState(false);
  const navigate = useNavigate();

  const settlementRole = useRole("settlement");
  const vendorDetailsRole = useRole("vendor-details");

  const role = [...settlementRole, ...vendorDetailsRole];

  if (!role.includes("list")) {
    navigate("/");
  }

  const columns = [
    {
      title: "Order Id",
      name: "order_id",
      sortable: true,
      sortableKey: "order_id",
    },
    {
      title: "Payment Id",
      name: "payment_id",
      sortable: true,
      sortableKey: "payment_id",
    },
    {
      title: "Vendor",
      name: "vendor",
      sortable: true,
      sortableKey: "vendor.name",
      Cell: (item: any) => {
        return <span>{item?.vendor?.name || "-"}</span>;
      },
    },
    {
      title: "Transaction Date",
      name: "created_at_ist",
      sortable: true,
      sortableKey: "created_at_ist",
      type: "date",
      Cell: (item: any) => {
        return <span>{item?.created_at_ist || "-"}</span>;
      },
    },
    {
      title: "Status",
      name: "status",
      sortable: true,
      sortableKey: "status",
      Cell: (item: any) => {
        return <StatusStyle status={item?.status || "-"} />;
      },
    },
    {
      title: "Payable Amount",
      name: "payable_amount",
      Cell: (item: any) => {
        return <span>{seperator(item?.payable_amount)}</span>;
      },
      sortable: true,
      sortableKey: "payable_amount",
    },
  ];

  const getTransactionList = async () => {
    try {
      const { startDate, vendor_id, order_id, status, ...rest } = filter;

      const payload: any = {
        ...rest,
        filter_data: {
          start_date: dateFormatter(startDate?.[0], "start"),
          end_date: dateFormatter(startDate?.[1], "end", startDate?.[0]),
          vendor_id,
          status,
          order_id,
        },
      };
      delete payload["isFilter"];
      delete payload["isClear"];
      const res: any = await getTransaction(payload);
      if (filter?.isFilter && !filter?.isClear) {
        responseToaster(res?.data);
      }
    } catch (error) {
      console.error("Error =-=>", error);
    }
  };
  const { register, handleSubmit, reset, control } = useForm<any>({
    defaultValues: {
      ...initialValues,
    },
  });

  useEffect(() => {
    setTableData(
      transactionList?.data?.length ? [...transactionList?.data] : []
    );
  }, [transactionList]);

  useEffect(() => {
    if (role.includes("list")) {
      getTransactionList();
    }
    // eslint-disable-next-line
  }, [filter]);

  const onSubmit = async (values: any) => {
    setFilter({ ...FILTER, ...values, isFilter: true });
  };
  return (
    <>
      <div className="flex mb-3 gap-2 justify-between sm:flex-wrap">
        <CustomeBreadcrumbs breadcrumbs={breadcrumbs} />
        <Button
          classNm={`!text-ev-white !border-0 appl-btn1`}
          onClick={() => {
            setShowDiv(!showDiv);
          }}
        >
          <Icon name="FilterIcon" />
        </Button>
      </div>
      {showDiv ? (
        <div className="cardcss mb-3">
          <form
            className="flex gap-2 sm:flex-wrap items-end"
            onSubmit={handleSubmit(onSubmit)}
          >
            <Input
              name="order_id"
              label="Search By Id"
              placeholder="Search Order Id"
              register={register}
            />
            <Select
              name="vendor_id"
              label="Vendor"
              placeholder="Select Vendor"
              options={prepareVendorOptions(transactionList?.vendorsList)}
              register={register}
            />
            <Select
              name="status"
              label="Status"
              placeholder="All"
              options={transactionStatus}
              register={register}
            />
            <ControlledDatePicker
              name="startDate"
              label="Transaction Date"
              placeholder="Select transaction Date"
              selectsRange
              control={control}
              maxDate={new Date()}
            />
            <Button type="submit" className="!text-sm">
              Apply
            </Button>
            <Button
              variant="Transaparent"
              className="!text-sm"
              onClick={() => {
                reset();
                setFilter({ ...FILTER, isFilter: true, isClear: true });
              }}
            >
              Clear
            </Button>
          </form>
        </div>
      ) : null}
      <div className="cardcss">
        <Table
          columns={columns}
          isExpendable={false}
          isLoading={isLoading}
          data={tableData || []}
          setData={setTableData}
          count={transactionList?.total_item || 0}
          pagination={filter}
          handlePaginationChange={(pagination) => {
            setFilter({
              ...filter,
              ...pagination,
              isFilter: false,
            });
          }}
        >
          {tableData?.map((item: any, index: number) => {
            return (
              <Fragment key={index}>
                <TableRow
                  columns={columns}
                  isExpendable={false}
                  item={item}
                  onClick={(item: any) => {}}
                ></TableRow>
              </Fragment>
            );
          })}
        </Table>
      </div>
    </>
  );
};

export default Transaction;
