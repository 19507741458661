import React, { memo } from "react";
import { IconProps } from ".";

export interface AuthLockProps extends IconProps {}
const AuthLock = (props: AuthLockProps) => {
  const {
    pathClassNames = {},
    size = 20,
    stroke = "currentColor",
    fill = "none",
    strokeWidth = 2,
    ...rest
  } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 48 48"
      width={size}
      height={size}
      fill={fill}
      stroke={stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
      {...rest}
    >
      <rect
        width="13.432"
        height="10.294"
        x="17.284"
        y="22.119"
        fill="none"
        stroke="black"
        stroke-linecap="round"
        stroke-linejoin="round"
        rx="1.177"
        ry="1.177"
      />
      <path
        fill="none"
        stroke="black"
        stroke-linecap="round"
        stroke-linejoin="round"
        className={`${pathClassNames?.[0] || ""}`}
        d="M20.056 22.12v-3.073a3.944 3.944 0 1 1 7.888 0v3.072"
      />
      <path
        fill="none"
        stroke="black"
        stroke-linecap="round"
        stroke-linejoin="round"
        className={`${pathClassNames?.[1] || ""}`}
        d="M24 43.5c6.914-1.65 16.116-9.964 16.116-20.127V11.561L24 4.5L7.884 11.56v11.813C7.884 33.536 17.086 41.85 24 43.5"
      />
    </svg>
  );
};

const MemoAuthIcon = memo(AuthLock);
export default MemoAuthIcon;
