import React, { Fragment, useEffect, useState } from "react";
import { Button, Table, TableRow } from "../../ui";
import { FILTER } from "../../constant";
import { useDeleteVideoMutation, useGetVideoMutation } from "./videoApi";
import { useVideoList, useVendorVideoList } from "./videoSlice";
import VideoModal from "./VideoModal";
import { MdDeleteOutline, MdEdit } from "react-icons/md";
import { Modal } from "../common";
import { prepareImageUrl, responseToaster } from "../../helperFunctions";
import Tooltip from "../../ui/elements/Tooltip";
import { useNavigate } from "react-router-dom";
import { useRole } from "../auth/authSlice";

const Video = () => {
  const [getVideo, { isLoading }] = useGetVideoMutation();
  const videoList = useVideoList();
  const videoVendorList = useVendorVideoList();
  const [deleteVideo, { isLoading: isDeleteLoading }] =
    useDeleteVideoMutation();
  const [isVideoModal, setIsVideoModal] = useState(false);
  const [tableData, setTableData] = useState<any>([]);
  const [filter, setFilter] = useState<any>(FILTER);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [deleteData, setDeleteData] = useState<any>();
  const [videoData, setVideoData] = useState({});
  const navigate = useNavigate();
  const role = useRole("video");

  if (!role.includes("list")) {
    navigate("/");
  }

  const handleAddVideo = () => {
    setIsVideoModal(true);
  };

  const columns = [
    {
      title: "ID",
      name: "id",
      sortable: true,
      sortableKey: "id",
    },
    {
      title: "Name",
      name: "title",
      sortable: true,
      sortableKey: "title",
    },
    {
      title: "Created",
      name: "created_at_ist",
      sortable: true,
      sortableKey: "created_at_ist",
      type: "date",
    },
    {
      title: "Photo",
      name: "photo",
      Cell:(data:any)=>{
        return (
          <span>
            <img
              src={prepareImageUrl(data?.photo)?.url}
              alt="img"
              width={30}
              height={30}
            />
          </span>
        );
      }
    },
    {
      title: "Video",
      name: "link",
      Cell: (data: any) => {
        return (
          <a
            href={data?.link}
            target="_blank"
            rel="noreferrer"
            className="text-sky-600"
          >
            {data?.link}
          </a>
        );
      },
    },
    {
      title: "Action",
      name: "action",
      Cell: (item: any) => {
        return (
          <div className="flex items-center gap-2">
            <Tooltip text="Edit">
              <Button
                className="!h-8 !w-8 !p-0"
                onClick={() => {
                  setIsVideoModal(true);
                  setVideoData(item);
                }}
                disabled={!role.includes("edit")}
              >
                <MdEdit />
              </Button>
            </Tooltip>

            <Tooltip text="Delete" className="right-0">
              <Button
                className="!h-8 w-8 !p-0"
                onClick={() => {
                  setIsDeleteModalOpen(true);
                  setDeleteData(item);
                }}
                disabled={!role.includes("delete")}
              >
                <MdDeleteOutline />
              </Button>
            </Tooltip>
          </div>
        );
      },
    },
  ];

  const onVideoDelete = async () => {
    try {
      const payload: any = {
        id: deleteData?.id,
      };
      const res = await deleteVideo(payload).unwrap();
      if (res) {
        setIsDeleteModalOpen(false);
        setDeleteData(false);
        setFilter({ ...filter, isFilter: false });
      }
      responseToaster(res);
    } catch (err) {
      console.error("Error =-=>", err);
    }
  };

  const getVideoList = async () => {
    try {
      const payload: any = {
        ...filter,
        filter_data: {
          vendor_id: filter?.vendor_id,
        },
      };

      delete payload["isFilter"];
      delete payload["isClear"];
      const res: any = await getVideo(payload);
      if (filter?.isFilter && !filter?.isClear) {
        responseToaster(res?.data);
      }
    } catch (error) {
      console.error("Error =-=>", error);
    }
  };
  useEffect(() => {
    if (role.includes("list")) {
      getVideoList();
    }
    // eslint-disable-next-line
  }, [filter]);

  useEffect(() => {
    setTableData(videoList?.data?.length ? [...videoList?.data] : []);
  }, [videoList, videoVendorList]);

  return (
    <>
      <div>
        {role.includes("create") ? (
          <div className="flex mb-3 gap-2 justify-end">
            <div className="flex justify-end">
              <Button onClick={() => handleAddVideo()}>Add Video</Button>
            </div>
          </div>
        ) : null}

        {role.includes("list") ? (
          <div className="cardcss">
            <Table
              columns={columns}
              className="videoTable"
              isExpendable={false}
              isLoading={isLoading}
              data={tableData || []}
              setData={setTableData}
              count={videoList?.total_item}
              pagination={filter}
              handlePaginationChange={(pagination) => {
                setFilter({
                  ...filter,
                  ...pagination,
                  isFilter: false,
                });
              }}
            >
              {tableData?.map((item: any, index: number) => {
                return (
                  <Fragment key={index}>
                    <TableRow
                      columns={columns}
                      isExpendable={false}
                      item={item}
                      onClick={(item: any) => {}}
                    ></TableRow>
                  </Fragment>
                );
              })}
            </Table>
          </div>
        ) : null}
      </div>
      <VideoModal
        isOpen={isVideoModal}
        onGet={getVideoList}
        videoData={videoData}
        onHide={() => {
          setIsVideoModal(false);
          setVideoData({});
        }}
      />
      <Modal
        isOpen={!!isDeleteModalOpen}
        isLoading={isDeleteLoading}
        doneText="Delete"
        content={
          <div className="flex flex-col gap-2">
            <p>Are you sure you want to delete this?</p>
          </div>
        }
        onDoneClick={onVideoDelete}
        onHide={() => {
          setIsDeleteModalOpen(false);
        }}
      />
    </>
  );
};

export default Video;
