import React, { useState } from "react";

interface ReadMoreLessProps {
  text: string;
  maxLength: number;
  hasPre?: boolean;
}

const ReadMoreLess: React.FC<ReadMoreLessProps> = ({
  text,
  maxLength,
  hasPre = false,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpanded = () => {
    setIsExpanded(!isExpanded);
  };

  const displayText = isExpanded ? text : text.slice(0, maxLength);

  return (
    <div>
      {hasPre ? (
        <p
          {...(hasPre
            ? { dangerouslySetInnerHTML: { __html: displayText } }
            : {})}
        />
      ) : (
        <p>{displayText}</p>
      )}

      {text.length > maxLength && (
        <div
          onClick={toggleExpanded}
          className="text-ev-primary cursor-pointer"
        >
          {isExpanded ? "Read less" : "Read more"}
        </div>
      )}
    </div>
  );
};

export default ReadMoreLess;
