import { createSlice } from "@reduxjs/toolkit";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../utills/store";
import { vendorApi } from "./vendorApi";

interface UserState {
  vendorList: any;
  vandorUserList: any;
  shopCategoryList: any;
  vendorMenu: any;
  noteList: any;
}

const initialState: UserState = {
  vendorList: {},
  vandorUserList: {},
  shopCategoryList: {},
  vendorMenu: {},
  noteList: {},
};

export const vendorSlice = createSlice({
  name: "vendor",
  initialState,
  reducers: {
    clearVendor: () => initialState,
    setVendors: (state, { payload }) => {
      state.vendorList = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      vendorApi.endpoints.getVendor.matchFulfilled,
      (state, { payload }) => {
        state.vendorList = payload || {};
      }
    );
    builder.addMatcher(
      vendorApi.endpoints.addVendor.matchFulfilled,
      (state, { payload }) => {
        state.vandorUserList = payload || {};
      }
    );
    builder.addMatcher(
      vendorApi.endpoints.getShopCategory.matchFulfilled,
      (state, { payload }) => {
        state.shopCategoryList = payload || {};
      }
    );
    builder.addMatcher(
      vendorApi.endpoints.vendorMenu.matchFulfilled,
      (state, { payload }) => {
        state.vendorMenu = payload || {};
      }
    );
    builder.addMatcher(
      vendorApi.endpoints.getVendorNote.matchFulfilled,
      (state, { payload }) => {
        state.noteList = payload || {};
      }
    );
  },
});

// Action creators are generated for each case reducer function
export default vendorSlice.reducer;
export const { clearVendor, setVendors } = vendorSlice.actions;

export const selectVendorList = (state: RootState) => state.vendor.vendorList;
export const useVendorList = () => {
  const vendorList = useSelector(selectVendorList);
  return useMemo(() => vendorList, [vendorList]);
};

export const selectVendorUserList = (state: RootState) =>
  state.vendor.vandorUserList;
export const useVendorUserList = () => {
  const vendorUserList = useSelector(selectVendorUserList);
  return useMemo(() => vendorUserList, [vendorUserList]);
};

export const selectShopCategoryList = (state: RootState) =>
  state.vendor.shopCategoryList;
export const useShopCategoryList = () => {
  const shopCategoryList = useSelector(selectShopCategoryList);
  return useMemo(() => shopCategoryList, [shopCategoryList]);
};

export const selectVendorMenu = (state: RootState) => state.vendor.vendorMenu;
export const useVendorMenu = () => {
  const vendorMenuList = useSelector(selectVendorMenu);
  return useMemo(() => vendorMenuList, [vendorMenuList]);
};

export const selectNoteMenu = (state: RootState) => state.vendor.noteList;
export const useNoteList = () => {
  const noteList = useSelector(selectNoteMenu);
  return useMemo(() => noteList, [noteList]);
};
