import { createSlice } from "@reduxjs/toolkit";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../utills/store";
import { salesProductReportApi } from "./salesProductReportApi";

interface UserState {
  subSalesProductReportList: any;
  subSalesProductReportListForChart: any;
}

const initialState: UserState = {
  subSalesProductReportList: {},
  subSalesProductReportListForChart: {},
};

export const salesProductReportSlice = createSlice({
  name: "salesProductReport",
  initialState,
  reducers: {
    clearSalesProductReport: () => initialState,
    setSalesProductReport: (state, { payload }) => {
      state.subSalesProductReportList = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      salesProductReportApi.endpoints.getSalesProductReport.matchFulfilled,
      (state, { payload }) => {
        state.subSalesProductReportList = payload || {};
      }
    );
    builder.addMatcher(
      salesProductReportApi.endpoints.getSalesProductReportForChart
        .matchFulfilled,
      (state, { payload }) => {
        state.subSalesProductReportListForChart = payload || {};
      }
    );
  },
});

// Action creators are generated for each case reducer function
export default salesProductReportSlice.reducer;
export const { clearSalesProductReport, setSalesProductReport } =
  salesProductReportSlice.actions;

export const selectSalesProductReportList = (state: RootState) =>
  state.salesProductReport.subSalesProductReportList;
export const useSalesProductReportList = () => {
  const salesProductReportList = useSelector(selectSalesProductReportList);
  return useMemo(() => salesProductReportList, [salesProductReportList]);
};

export const selectSalesProductReportChartList = (state: RootState) =>
  state.salesProductReport.subSalesProductReportListForChart;
export const useSalesProductReportChartList = () => {
  const salesProductReportChartList = useSelector(
    selectSalesProductReportChartList
  );
  return useMemo(
    () => salesProductReportChartList,
    [salesProductReportChartList]
  );
};
