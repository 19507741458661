import { useEffect, useState } from "react";
import { useGetOrderMutation } from "./ordersApi";
import {
  prepareImageUrl,
  responseToaster,
  seperator,
} from "../../helperFunctions";
import { useNavigate, useParams } from "react-router-dom";
import { Loader } from "../../ui";
import StatusStyle from "../common/StatusStyle";
import CustomeBreadcrumbs from "../common/CustomeBreadcrumbs";
import { paymentMethodOptions } from "../../constant";
import dummyImage from "../../assets/images/dummy-image.svg";
import { useRole } from "../auth/authSlice";

const OrdersDetail = () => {
  const [getOrder, { isLoading }] = useGetOrderMutation();
  const [order, setOrder] = useState<any>();
  const { id } = useParams();
  const navigate = useNavigate();

  const orderRole = useRole("order");
  const vendorDetailsRole = useRole("vendor-details");

  const role = [...orderRole, ...vendorDetailsRole];

  if (!role.includes("list")) {
    navigate("/");
  }
  const breadcrumbs = [
    { label: "Orders", link: "/order/get-order" },
    { label: order?.ord_id },
  ];

  const getOrdersDetail = async () => {
    try {
      const payload: any = {
        id,
      };
      const response = await getOrder(payload).unwrap();
      if (response) {
        setOrder(response?.data);
      }
      if (!Object.keys?.(response?.data)?.length) {
        responseToaster(response);
        navigate("/order");
        return;
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const formatAddress = (addressData: any) => {
    const formattedAddress = `${addressData?.address1} ${
      addressData?.landmark ? addressData?.landmark + ", " : ""
    }${addressData?.city}, ${addressData?.state} ${addressData?.pincode}`;

    return formattedAddress;
  };

  useEffect(() => {
    getOrdersDetail();
    // eslint-disable-next-line
  }, [id]);

  if (isLoading) {
    return (
      <>
        <div className="flex items-center justify-center loader_height">
          <Loader />
        </div>
      </>
    );
  }

  return (
    <div>
      <CustomeBreadcrumbs breadcrumbs={breadcrumbs} />
      <div className="main-content-wrap">
        <div className="wg-box !py-4 justify-between sm:flex-wrap mb-3 !pl-2">
          <div className="flex gap-1 items-center">
            <span className="body-title">Status : </span>
            <StatusStyle status={order?.status} />
          </div>
          <div>
            <span className="body-title">Payment Method : </span>
            <span className="body-text">
              {paymentMethodOptions?.[order?.payment_type]?.toUpperCase() ||
                "-"}
            </span>
          </div>
        </div>

        <div className="wg-order-detail">
          <div className="left grow">
            {order?.order_item?.length ? (
              <div className="wg-box flex-col mb-3">
                <div className="wg-table table-order-detail">
                  <ul className="flex flex-col">
                    {order?.order_item?.map((item: any, index: number) => {
                      return (
                        <li className="product-item gap-[14px]" key={index}>
                          <div className="image no-bg">
                            <img
                              src={
                                prepareImageUrl(
                                  item?.product?.photo?.split?.(",")?.[0]
                                )?.url
                              }
                              alt="img"
                              onError={(e: any) => {
                                e.target.onerror = null;
                                e.target.src = dummyImage;
                                e.target.width = 40;
                                e.target.height = 40;
                              }}
                            />
                          </div>
                          <div className="flex items-start justify-between flex-grow">
                            <div className="name">
                              <div className="text-tiny mb-1">Product Name</div>
                              <div className="body-title-2">
                                {item?.product?.title || "-"}
                              </div>
                            </div>
                            {item?.variant?.value ? (
                              <div className="name">
                                <div className="text-tiny mb-1">Variant</div>
                                <div className="body-title-2">
                                  {item?.variant?.value}
                                </div>
                              </div>
                            ) : null}
                            <div className="name">
                              <div className="text-tiny mb-1">Quantity</div>
                              <div className="body-title-2">
                                {item?.quantity}
                              </div>
                            </div>
                            <div className="name">
                              <div className="text-tiny mb-1">Price</div>
                              <div className="body-title-2">
                                {seperator(item?.product?.price)}
                              </div>
                            </div>
                            <div className="name">
                              <div className="text-tiny mb-1">Discount</div>
                              <div className="body-title-2">
                                {seperator(item?.discount)}
                              </div>
                            </div>
                            <div className="name">
                              <div className="text-tiny mb-1">Total</div>
                              <div className="body-title-2">
                                {seperator(item?.total)}
                              </div>
                            </div>
                          </div>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
            ) : null}
            <div className="wg-box flex-col mb-3">
              <div className="wg-table table-cart-totals">
                <ul className="table-title flex mb-4">
                  <li>
                    <div className="body-title">Cart Totals</div>
                  </li>
                  <li>
                    <div className="body-title">Price</div>
                  </li>
                </ul>
                <ul className="flex flex-col gap-3 total-desc">
                  <li className="cart-totals-item">
                    <span className="body-text">Total Amount:</span>
                    <span className="body-title-2">
                      {seperator(order?.total)}
                    </span>
                  </li>
                  <li className="cart-totals-item">
                    <span className="body-text">Discount:</span>
                    <span className="body-title-2">
                      {seperator(order?.discount)}
                    </span>
                  </li>
                  <li className="cart-totals-item">
                    <span className="body-text">Shipping Charge:</span>
                    <span className="body-title-2">
                    {seperator(order?.shipping_charge)}
                    </span>
                  </li>
                  <li className="cart-totals-item">
                    <span className="body-text">Coupon Discount:</span>
                    <span className="body-title-2">
                      {seperator(order?.coupon_discount)}
                    </span>
                  </li>
                  <li className="cart-totals-item">
                    <span className="body-title">Grand Total:</span>
                    <span className="body-title tf-color-1">
                      {seperator(order?.total_payment)}
                    </span>
                  </li>
                </ul>
              </div>
            </div>
            {!order?.is_quick ? (
              <div className="wg-box flex-col">
                <div className="wg-table table-cart-totals">
                  <ul className="flex flex-col gap-3 total-desc">
                    <li className="cart-totals-item">
                      <span className="body-text">Cancelled</span>
                      <span className="body-title-2">
                        <StatusStyle
                          status={order?.is_cancelled.toString()}
                          text={order?.is_cancelled ? "Yes" : "No"}
                        />
                      </span>
                    </li>
                    <li className="cart-totals-item">
                      <span className="body-text">Delivered</span>
                      <span className="body-title-2">
                        <StatusStyle
                          status={order?.is_delivered.toString()}
                          text={order?.is_delivered ? "Yes" : "No"}
                        />
                      </span>
                    </li>
                    <li className="cart-totals-item">
                      <span className="body-text">Retured</span>
                      <span className="body-title-2">
                        <StatusStyle
                          status={order?.is_return.toString()}
                          text={order?.is_return ? "Yes" : "No"}
                        />
                      </span>
                    </li>
                    <li className="cart-totals-item">
                      <span className="body-text">Settlement</span>
                      <span className="body-title-2">
                        <StatusStyle
                          status={order?.is_settlement.toString()}
                          text={order?.is_settlement ? "Yes" : "No"}
                        />
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            ) : null}
          </div>
          <div className="right">
            <div className="wg-box flex-col mb-3 gap-10">
              <div className="body-title">Summery</div>
              <div className="summary-item">
                <div className="body-text">Order ID</div>
                <div className="body-title-2">{order?.ord_id}</div>
              </div>
              <div className="summary-item">
                <div className="body-text">Date</div>
                <div className="body-title-2">{order?.created_at_ist}</div>
              </div>
              <div className="summary-item">
                <div className="body-text">Total</div>
                <div className="body-title-2 tf-color-1">
                  {seperator(order?.total_payment)}
                </div>
              </div>
            </div>
            {!order?.is_quick ? (
              <div className="wg-box flex-col mb-3 gap-10">
                <div className="body-title">Coupon Detail</div>
                <div className="summary-item">
                  <div className="body-text">Code</div>
                  <div className="body-title-2">
                    {order?.coupon_code || "-"}
                  </div>
                </div>
                <div className="summary-item">
                  <div className="body-text">Discount</div>
                  <div className="body-title-2">
                    {seperator(order?.coupon_discount) || "-"}
                  </div>
                </div>
              </div>
            ) : null}
            {!order?.is_quick ? (
              <div className="wg-box flex-col mb-3 gap-10">
                <div className="body-title">Customer Detail</div>
                <div className="summary-item">
                  <div className="body-text">Customer Name</div>
                  <div className="body-title-2">
                    {order?.user?.firstName || order?.user?.lastName
                      ? `${order.user.firstName} ${order.user.lastName}`
                      : "-"}
                  </div>
                </div>
                <div className="summary-item">
                  <div className="body-text">Contact</div>
                  <div className="body-title-2">
                    {order?.user?.contact || "-"}
                  </div>
                </div>
              </div>
            ) : null}
            {!order?.is_quick ? (
              <div className="wg-box flex-col mb-3 gap-10">
                <div className="body-title">Shipping Address</div>
                <div className="body-text">
                  {formatAddress(order?.shipping_address)}
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrdersDetail;
