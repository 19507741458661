import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Form } from "reactstrap";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Button, Pagination } from "../../../ui";
import {
  useDeleteVendorNoteMutation,
  useGetVendorNoteMutation,
  useVendorNoteMutation,
} from "./vendorApi";
import { responseToaster } from "../../../helperFunctions";
import { IoIosCloseCircle } from "react-icons/io";
import ControlledTextArea from "../../../ui/forms/ControlledTextArea";
import { useNoteList } from "./vendorSlice";
import { MdDeleteOutline, MdEdit } from "react-icons/md";
import ReadMoreLess from "../../common/ReadMoreLess";

const NoteModal = (props: any) => {
  const { onHide, isOpen } = props;
  const [addCpisLoading, setaddCpIsLoading] = useState<boolean>(false);
  const [filter, setFilter] = useState({ page_no: 1, limit: 5 });
  const [editedId, setEditedId] = useState(null);
  const [vendorNote] = useVendorNoteMutation();
  const [getVendorNote, { isLoading: noteLoading }] =
    useGetVendorNoteMutation();
  const [deleteVendorNote, { isLoading: noteDeleteLoading }] =
    useDeleteVendorNoteMutation();
  const noteList: any = useNoteList();

  const schema: any = yup.object({
    note: yup.string().required("Please enter a note"),
  });
  const {
    control,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  }: any = useForm({
    resolver: yupResolver(schema),
  });
  const onClose = () => {
    reset();
    onHide();
    setEditedId(null);
    setFilter({ page_no: 1, limit: 5 });
  };

  const getNote = async () => {
    try {
      const payload: any = {
        ...filter,
        filter_data: {
          vendor_id: isOpen,
        },
      };
      const res: any = await getVendorNote(payload);
      if (res?.data?.status) {
        setFormData(res?.data?.data);
      }
    } catch (error) {}
  };

  const setFormData = (noteData: any) => {
    if (noteData?.id) {
      setValue("vendor_id", noteData?.vendor_id);
      setValue("note", noteData?.note);
    }
  };

  useEffect(() => {
    if (isOpen) {
      getNote();
    }
    // eslint-disable-next-line
  }, [isOpen, filter]);

  const onSubmit = async (values: any) => {
    try {
      setaddCpIsLoading(true);
      const payload = {
        vendor_id: isOpen,
        ...values,
      };

      const res: any = editedId
        ? await vendorNote({ id: editedId, ...payload }).unwrap()
        : await vendorNote(payload).unwrap();
      if (editedId) {
        getNote();
        setEditedId(null);
        setValue("note", "");
      }
      if (res?.status && !editedId) {
        getNote();
        reset();
      }
      responseToaster(res);
      setaddCpIsLoading(false);
    } catch (error) {
      setaddCpIsLoading(false);
    }
  };

  if (!isOpen) return null;
  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center">
      <div
        className="modal-overlay absolute w-full h-full bg-gray-900 opacity-50"
        onClick={() => {
          if (!addCpisLoading) {
            onClose();
          }
        }}
      />

      <div className="modal-container relative p-6 max-h-[84vh]   bg-chatlook-white border w-8/12 2xl:w-6/12 xl:w-6/12 lg:w-8/12  md:w-9/12 sm:w-10/12  mx-auto rounded-lg shadow-lg z-50 overflow-y-auto">
        <div className="modal-content">
          <Form onSubmit={handleSubmit(onSubmit)}>
            <div className="modal-header flex justify-between">
              <h4 className="modal-title mb-4 font-bold capitalize">Note</h4>
              <div onClick={() => onClose()} className="cursor-pointer">
                <IoIosCloseCircle color="var(--ev-primary--)" size={22} />
              </div>
            </div>
            <div className="modal-body">
              <div className="pt-2 pb-3">
                <ControlledTextArea
                  name="note"
                  label="Note"
                  className="col-span-2 textareacss"
                  placeholder="Add Note..."
                  control={control}
                  errors={errors}
                />
                {editedId ? (
                  <div className="flex gap-3 justify-end items-center pt-3">
                    <Button
                      variant="Transaparent"
                      onClick={(e: { stopPropagation: () => void }) => {
                        e.stopPropagation();
                        setEditedId(null);
                        setValue("note", "");
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      type="submit"
                      isLoading={addCpisLoading}
                      isLoader={addCpisLoading}
                    >
                      Edit
                    </Button>
                  </div>
                ) : null}

                {!editedId ? (
                  <div className="flex justify-end gap-3">
                    <Button
                      variant="Transaparent"
                      className="mt-[10px]  !text-sm !h-9"
                      disabled={addCpisLoading}
                      onClick={() => reset()}
                    >
                      Clear
                    </Button>
                    <Button
                      type="submit"
                      className="mt-[10px] !h-9 !text-sm"
                      isLoading={addCpisLoading}
                      isLoader={addCpisLoading}
                    >
                      <div className="flex gap-1">Add</div>
                    </Button>
                  </div>
                ) : null}
              </div>
              <div className="border border-chatlook-Tertiary rounded-lg">
                {noteLoading ? (
                  <div className="flex justify-center">
                    <svg
                      aria-hidden="true"
                      className={` w-8 h-8 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600`}
                      viewBox="0 0 100 101"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                        fill="currentColor"
                      />
                      <path
                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                        fill="currentFill"
                      />
                    </svg>
                  </div>
                ) : (
                  noteList?.data?.map?.((note: any) => {
                    return (
                      <div className="flex justify-between border-b border-chatlook-Tertiary p-2 my-1">
                        <span className="!whitespace-break-spaces">
                          {<ReadMoreLess text={note?.note} maxLength={150} />}
                        </span>
                        <div className="flex gap-2">
                          <Button
                            className="!h-8 !w-8 !p-0"
                            onClick={(e: { stopPropagation: () => void }) => {
                              e.stopPropagation();
                              setValue("note", note?.note);
                              setEditedId(note?.id);
                              // setIsVendorModal(true);
                              // setVendorData(data);
                            }}
                          >
                            <MdEdit />
                          </Button>
                          <Button
                            className="!h-8 !w-8 !p-0"
                            isLoading={noteDeleteLoading}
                            isLoader={noteDeleteLoading}
                            onClick={async (e: {
                              stopPropagation: () => void;
                            }) => {
                              e.stopPropagation();
                              const payload: any = {
                                vendor_id: isOpen,
                                id: note?.id,
                              };
                              const res: any = await deleteVendorNote(payload);
                              if (res?.status) {
                                getNote();
                              }
                              responseToaster(res?.data);
                              // setIsVendorModal(true);
                              // setVendorData(data);
                            }}
                          >
                            <MdDeleteOutline />
                          </Button>
                        </div>
                      </div>
                    );
                  })
                )}
                {noteList?.total_item > filter?.limit ? (
                  <div className="py-2">
                    <Pagination
                      count={noteList?.total_item}
                      limit={filter?.limit}
                      data={noteList?.data}
                      page_no={filter?.page_no}
                      handlePageChange={(pagination: any) => {
                        setFilter({
                          ...filter,
                          page_no: pagination,
                        });
                      }}
                      hasInTable={false}
                      showLimit={false}
                    />
                  </div>
                ) : null}
              </div>
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default NoteModal;
