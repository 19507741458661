import { Fragment, useEffect, useState } from "react";
import { FILTER, statusOptions1 } from "../../constant";
import {
  Button,
  ControlledDatePicker,
  Icon,
  Input,
  Select,
  Table,
  TableRow,
} from "../../ui";
import { useOrdersList } from "./ordersSlice";
import { useDeleteOrderMutation, useGetOrdersMutation } from "./ordersApi";
import {
  dateFormatter,
  prepareUsersOptions,
  prepareVendorOptions,
  responseToaster,
} from "../../helperFunctions";
import { useLocation, useNavigate } from "react-router-dom";
import StatusStyle from "../common/StatusStyle";
import { useForm } from "react-hook-form";
import { useVendorList } from "../vendor/vendorList/vendorSlice";
import { statusOptionsForOrder } from "./mock";
import { useUsersList } from "../customers/usersSlice";
import { useGetUsersMutation } from "../customers/usersApi";
import { useGetVendorMutation } from "../vendor/vendorList/vendorApi";
import { useRole } from "../auth/authSlice";
import Tooltip from "../../ui/elements/Tooltip";
import { MdDeleteOutline } from "react-icons/md";
import { Modal } from "../common";

const initialValues = {
  startDate: [null, null],
};

const Orders = () => {
  const [getOrders, { isLoading }] = useGetOrdersMutation();
  const [deleteOrder, { isLoading: isDeleteLoading }] =
    useDeleteOrderMutation();
  const ordersList = useOrdersList();
  const [getUsers] = useGetUsersMutation();
  const [getVendorList] = useGetVendorMutation();
  const vendorList = useVendorList();
  const usersList = useUsersList();
  const navigate = useNavigate();
  const route = useLocation()?.pathname;
  const [tableData, setTableData] = useState<any>([]);
  const [filter, setFilter] = useState<any>(FILTER);
  const [showDiv, setShowDiv] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [deleteData, setDeleteData] = useState<any>();

  const orderRole = useRole("order");
  const vendorDetailsRole = useRole("vendor-details");

  const role = [...orderRole, ...vendorDetailsRole];

  if (!role.includes("list")) {
    navigate("/");
  }

  const onOrderDelete = async () => {
    try {
      const payload: any = {
        id: deleteData?.id,
      };
      const res = await deleteOrder(payload).unwrap();
      if (res) {
        setIsDeleteModalOpen(false);
        setDeleteData(false);
        setFilter({ ...filter, isFilter: false });
      }
      responseToaster(res);
    } catch (err) {
      console.error("Error =-=>", err);
    }
  };

  const columns = [
    {
      title: "Status",
      name: "status",
      sortable: true,
      sortableKey: "status",
      Cell: (data: any) => {
        return (
          <>{data?.status ? <StatusStyle status={data?.status} /> : "-"}</>
        );
      },
    },
    {
      title: "Order Id",
      name: "ord_id",
      sortable: true,
      sortableKey: "ord_id",
    },

    {
      title: "Customer Name",
      name: "shipping_address",
      sortable: true,
      sortableKey: "shipping_address.name",
      Cell: (data: any) => {
        return (
          <>
            {data?.shipping_address?.name && data?.shipping_address?.lname
              ? `${data?.shipping_address?.name || ""} ${
                  data?.shipping_address?.lname || ""
                }`
              : "Unknown"}
          </>
        );
      },
    },
    {
      title: "Vendor Name",
      name: "vendor_name",
      sortable: true,
      sortableKey: "vendors.name",
      Cell: (data: any) => {
        return <>{data?.vendors?.name || "-"}</>;
      },
    },

    {
      title: "Order Date",
      name: "created_at_ist",
      sortable: true,
      sortableKey: "created_at_ist",
      type: "date",
      Cell: (data: any) => {
        return <>{data?.created_at_ist}</>;
      },
    },
    {
      title: "Action",
      name: "action",
      Cell: (item: any) => {
        return (
          <div className="flex items-center gap-2">
            <Tooltip text="Delete" className="right-0">
              <Button
                className="!h-8 !w-8 !p-0"
                onClick={() => {
                  setIsDeleteModalOpen(true);
                  setDeleteData(item);
                }}
                disabled={!role.includes("delete")}
              >
                <MdDeleteOutline />
              </Button>
            </Tooltip>
          </div>
        );
      },
    },
  ];
  const { register, handleSubmit, reset, control } = useForm<any>({
    defaultValues: {
      ...initialValues,
    },
  });

  const getOrdersList = async () => {
    try {
      const {
        status,
        search,
        startDate,
        vendorId,
        is_quick,
        user_id,
        ...rest
      } = filter;

      const payload: any = {
        ...rest,
        filter_data: {
          status,
          vendorId,
          user_id,
          is_quick,
          search,
          start_date: dateFormatter(startDate?.[0], "start"),
          end_date: dateFormatter(startDate?.[1], "end", startDate?.[0]),
        },
      };
      delete payload["isFilter"];
      delete payload["isClear"];
      const res: any = await getOrders(payload);
      if (filter?.isFilter && !filter?.isClear) {
        responseToaster(res?.data);
      }
    } catch (error) {
      console.error("Error =-=>", error);
    }
  };

  useEffect(() => {
    setTableData(ordersList?.data?.length ? [...ordersList?.data] : []);
  }, [ordersList]);

  useEffect(() => {
    if (role.includes("list")) {
      getVendorList();
      getUsers();
    }
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    if (role.includes("list")) {
      getOrdersList();
    }
    // eslint-disable-next-line
  }, [filter]);

  if (!role.includes("list")) {
    navigate("/");
  }

  const onSubmit = async (values: any) => {
    setFilter({ ...FILTER, ...values, isFilter: true });
  };

  return (
    <>
      {role.includes("list") ? (
        <div className="flex mb-3 gap-2 justify-end">
          <Button
            classNm={`!text-ev-white !border-0 appl-btn1`}
            onClick={() => {
              setShowDiv(!showDiv);
            }}
            
          >
            <Icon name="FilterIcon" />
          </Button>
        </div>
      ) : null}
      {showDiv ? (
        <div className="cardcss mb-3">
          <form
            className="flex gap-2 items-end  sm:flex-wrap"
            onSubmit={handleSubmit(onSubmit)}
          >
            <Input
              name="search"
              label="Search"
              placeholder="Search"
              register={register}
            />
            <Select
              name="vendorId"
              label="Vendor"
              placeholder="Select Vendor"
              options={prepareVendorOptions(vendorList)}
              register={register}
            />

            <Select
              name="status"
              label="Status"
              placeholder="All"
              options={statusOptionsForOrder}
              register={register}
            />
            <Select
              name="is_quick"
              label="Quick order"
              placeholder="All"
              options={statusOptions1}
              register={register}
            />
            <Select
              name="user_id"
              label="Customer"
              placeholder="All"
              options={prepareUsersOptions(usersList)}
              register={register}
            />
            <ControlledDatePicker
              name="startDate"
              label="Order Date"
              placeholder="Select Order Date"
              selectsRange
              control={control}
              maxDate={new Date()}
            />
            <Button type="submit" className="!text-sm">
              Apply
            </Button>
            <Button
              variant="Transaparent"
              className="!text-sm"
              onClick={() => {
                reset();
                setFilter({ ...FILTER, isFilter: true, isClear: true });
              }}
            >
              Clear
            </Button>
          </form>
        </div>
      ) : null}
      {role.includes("list") ? (
        <div className="cardcss">
          <Table
            columns={columns}
            className="orderTable"
            isLoading={isLoading}
            data={tableData || []}
            setData={setTableData}
            count={ordersList?.total_item || 0}
            pagination={filter}
            handlePaginationChange={(pagination) => {
              setFilter({
                ...filter,
                ...pagination,
                isFilter: false,
              });
            }}
          >
            {tableData?.map((item: any, index: number) => {
              return (
                <Fragment key={index}>
                  <TableRow
                    columns={columns}
                    item={item}
                    isExpendable={false}
                    onEyeClick={(item: any) => {
                      navigate(`${route}/${item?.id}`);
                    }}
                  ></TableRow>
                </Fragment>
              );
            })}
          </Table>
        </div>
      ) : null}
      <Modal
        isOpen={!!isDeleteModalOpen}
        isLoading={isDeleteLoading}
        doneText="Delete"
        content={
          <div className="flex flex-col gap-2">
            <p>Are you sure you want to delete this?</p>
          </div>
        }
        onDoneClick={onOrderDelete}
        onHide={() => {
          setIsDeleteModalOpen(false);
        }}
      />
    </>
  );
};

export default Orders;
