import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Form } from "reactstrap";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Button, ControlledDropDown, ControlledInput } from "../../ui";
import { responseToaster } from "../../helperFunctions";
import { useUpdateTicketMutation } from "./ticketApi";

export const ticketStatusOptions = [
  {
    value: "Pending",
    label: "Pending",
  },
  {
    value: "Running",
    label: "Running",
  },
  {
    value: "Solved",
    label: "Solved",
  },
];

const StatusModal = (props: any) => {
  const { onHide, isOpen,onGet } = props;
  const [rmIsLoading, setRmIsLoading] = useState<boolean>(false);
  const [updateTicket] = useUpdateTicketMutation();

  const schema: any = yup.object({
    note: yup.string().required("Please enter a note"),
  });

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  }: any = useForm({
    resolver: yupResolver(schema),
  });
  useEffect(() => {
    if (isOpen?.id) {
      setValue("status", isOpen?.status);
      setValue("note", isOpen?.note);
    }
    // eslint-disable-next-line
  }, [isOpen, isOpen?.id]);

  const onClose = () => {
    reset();
    onHide();
  };

  const onSubmit = async (values: any) => {
    try {
      setRmIsLoading(true);
      const payload: any = {
        id: isOpen?.id,
        status: values?.status,
        note:values?.note
      };
      const res: any = await updateTicket(payload).unwrap();
      if (res?.status) {
        onGet?.()
        onClose();
      }
      responseToaster(res);
      setRmIsLoading(false);
    } catch (error) {
      setRmIsLoading(false);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center">
      <div
        className="modal-overlay absolute w-full h-full bg-gray-900 opacity-50"
        onClick={() => {
          if (!rmIsLoading) {
            onClose();
          }
        }}
      />

      <div className="modal-container bg-chatlook-white border w-8/12 2xl:w-5/12 xl:w-6/12 lg:w-8/12  md:w-9/12 sm:w-10/12  mx-auto rounded-lg shadow-lg z-50 overflow-y-auto">
        <div className="modal-content p-6 text-left  max-h-[84vh]">
          <Form onSubmit={handleSubmit(onSubmit)}>
            <div className="modal-header flex justify-between">
              <h4 className="modal-title mb-4 font-bold capitalize">
                Update Status
              </h4>
            </div>
            <div className="modal-body">
              <div className="grid grid-cols-1 items-start gap-3 pt-2 pb-3">
              <ControlledDropDown
                  name="status"
                  label="Status"
                  className="sm:col-span-2"
                  placeholder="Select Status"
                  options={ticketStatusOptions}
                  isDefaultOption={false}
                  control={control}
                  errors={errors}
                />
                <ControlledInput
                  name="note"
                  label="Note"
                  placeholder="Enter Note"
                  type="text"
                  className="sm:col-span-2"
                  errors={errors}
                  control={control}
                />
              </div>
            </div>
            <div className="modal-footer pb-1">
              <div className="flex items-center justify-end gap-2">
                <Button
                  type="submit"
                  className="mt-[10px] !h-9 !text-sm"
                  isLoading={rmIsLoading}
                  isLoader={rmIsLoading}
                >
                  Update
                </Button>
                <Button
                  variant="Transaparent"
                  className="mt-[10px]  !text-sm !h-9"
                  disabled={rmIsLoading}
                  onClick={() => onClose()}
                >
                  Cancel
                </Button>
              </div>
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default StatusModal;
