import { createSlice } from "@reduxjs/toolkit";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../utills/store";
import { salesReportApi } from "./salesReportApi";

interface UserState {
  subSalesReportList: any;
}

const initialState: UserState = {
  subSalesReportList: {},
};

export const salesReportSlice = createSlice({
  name: "salesReport",
  initialState,
  reducers: {
    clearSalesReport: () => initialState,
    setSalesReport: (state, { payload }) => {
      state.subSalesReportList = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      salesReportApi.endpoints.getSalesReport.matchFulfilled,
      (state, { payload }) => {
        state.subSalesReportList = payload || {};
      }
    );
  },
});

// Action creators are generated for each case reducer function
export default salesReportSlice.reducer;
export const { clearSalesReport, setSalesReport } = salesReportSlice.actions;

export const selectSalesReportList = (state: RootState) =>
  state.salesReport.subSalesReportList;
export const useSalesReportList = () => {
  const salesReportList = useSelector(selectSalesReportList);
  return useMemo(() => salesReportList, [salesReportList]);
};
