import React, { Fragment } from "react";
import { Table, TableRow } from "../../ui";
import { Link, useNavigate } from "react-router-dom";

const RecentOrder = ({ recentOrder }: any) => {
  const navigate = useNavigate();
  const columns = [
    {
      title: "ID",
      name: "id",
      sortable: true,
      sortableKey: "id",
      Cell: (product: any) => {
        return (
          <>
            <div className="flex items-center">
              <p className="text-gray-700">
                <p className="font-semibold">
                  {product?.user?.firstName || product?.user?.lastName
                    ? `${product?.user?.firstName} ${product?.user?.lastName}`
                    : product?.user?.guest || "Unknow"}
                </p>
                <p className="text-xs text-gray-500">
                  {product?.user?.contact}
                </p>
              </p>
            </div>
          </>
        );
      },
    },
    {
      title: "Order Id",
      name: "ord_id",
      sortable: true,
      sortableKey: "ord_id",
      Cell: (vendor: any) => {
        return (
          <>
            <div className="bg-chatlook-gray text-chatlook-secondary font-semibold px-3 py-1 rounded-md text-center">
              {vendor.ord_id}
            </div>
          </>
        );
      },
    },
  ];
  return (
    <div>
      <div className="flex justify-between items-center pb-2">
        <h2 className="text-lg font-semibold text-gray-800">Recent Orders</h2>
        <Link to={`/order/get-order`} className="text-ev-primary text-sm">
          View All
        </Link>
      </div>
      <div className="h-[400px] overflow-y-scroll hidden_scroll">
        <Table
          columns={columns}
          className="userTable"
          isExpendable={false}
          data={recentOrder || []}
          showHeader={false}
          count={recentOrder?.length}
        >
          {recentOrder?.map((item: any, index: number) => {
            return (
              <Fragment key={index}>
                <TableRow
                  columns={columns}
                  isExpendable={false}
                  item={item}
                  className="cursor-pointer"
                  onClick={(item: any) => {
                    navigate(`/order/get-order/${item?.id}`);
                  }}
                ></TableRow>
              </Fragment>
            );
          })}
        </Table>
      </div>
    </div>
  );
};

export default RecentOrder;
