import React, { memo } from "react";
import { IconProps } from ".";

export interface AuthIconProps extends IconProps {}
const Auth = (props: AuthIconProps) => {
  const {
    pathClassNames = {},
    size = 20,
    stroke = "currentColor",
    fill = "none",
    strokeWidth = 2,
    ...rest
  } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 48 48"
      width={size}
      height={size}
      fill={fill}
      stroke={stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
      {...rest}
    >
      <path
        fill="none"
        stroke="black"
        stroke-linecap="round"
        stroke-linejoin="round"
        className={`${pathClassNames?.[0] || ""}`}
        d="M43.5 24a3.285 3.285 0 0 1-3.285 3.285H29.69L24 17.428l5.262-9.113a3.285 3.285 0 0 1 4.488-1.203h0a3.285 3.285 0 0 1 1.203 4.488l-5.262 9.115h10.524A3.285 3.285 0 0 1 43.5 24"
      />
      <path
        fill="none"
        stroke="black"
        stroke-linecap="round"
        stroke-linejoin="round"
        className={`${pathClassNames?.[1] || ""}`}
        d="M33.75 40.888a3.285 3.285 0 0 1-4.488-1.202L24 30.572l-5.262 9.114a3.285 3.285 0 0 1-4.488 1.202h0a3.285 3.285 0 0 1-1.203-4.488l5.261-9.115H29.69l5.262 9.115a3.285 3.285 0 0 1-1.202 4.488M24 17.428l-1.92 3.325l-3.771-.038l-5.262-9.115a3.285 3.285 0 0 1 1.203-4.488h0a3.285 3.285 0 0 1 4.488 1.202z"
      />
      <path
        fill="none"
        stroke="black"
        stroke-linecap="round"
        stroke-linejoin="round"
        className={`${pathClassNames?.[2] || ""}`}
        d="m22.08 20.753l-3.771 6.532H7.785A3.285 3.285 0 0 1 4.5 24h0a3.285 3.285 0 0 1 3.285-3.285zm-3.771 6.532L24 17.428l5.691 9.857z"
      />
    </svg>
  );
};

const MemoAuthIcon = memo(Auth);
export default MemoAuthIcon;
