import React, { Fragment, useEffect, useState } from "react";
import { FILTER } from "../../../constant";
import { Button, ControlledDatePicker, Icon, Select } from "../../../ui";
import {
  dateFormatter,
  getFormattedWeekRanges,
  prepareVendorOptions,
  responseToaster,
} from "../../../helperFunctions";
import CustomeBreadcrumbs from "../../common/CustomeBreadcrumbs";
import { useForm } from "react-hook-form";
import { useRole } from "../../auth/authSlice";
import { useNavigate } from "react-router-dom";
import {
  useSalesProductReportChartList,
  useSalesProductReportList,
} from "./salesProductReportSlice";
import {
  useGetSalesProductReportForChartMutation,
  useGetSalesProductReportMutation,
} from "./salesProductReportApi";
import StatusCard from "./StatusCard";
import ReportChart from "./ReportChart";

const breadcrumbs = [{ label: "Report" }, { label: "Sales Report" }];

const initialDate = () => {
  const startDate = new Date();
  startDate.setDate(1);
  return {
    startDate: [startDate, new Date()],
  };
};

const SalesProductReport = () => {
  const [getSalesProductReport, { isLoading }] =
    useGetSalesProductReportMutation();
  const [getSalesProductReportForChart, { isLoading: chartLoading }] =
    useGetSalesProductReportForChartMutation();

  const { thisWeek, lastWeek } = getFormattedWeekRanges();

  const salesProductReportList = useSalesProductReportList();
  const salesProductReportListForChart = useSalesProductReportChartList();
  const startDate = new Date();
  startDate.setDate(1);
  const initialFilterState = {
    ...FILTER,
    startDate: [startDate, new Date()],
    type: "daily",
  };
  const initialFilterStateForChart = {
    ...FILTER,
    startDateChart: [thisWeek?.start, thisWeek?.end],
    type: "weekly",
  };
  const [filter, setFilter] = useState<any>(initialFilterState);
  const [filterForChart, setFilterForChart] = useState<any>(
    initialFilterStateForChart
  );
  const [showDiv, setShowDiv] = useState(false);
  const [currentTab, setCurrentTab] = useState(0);
  const initialValues = {
    ...initialDate(),
    type: "daily",
  };
  const navigate = useNavigate();

  const settlementRole = useRole("sales-product");
  const vendorDetailsRole = useRole("vendor-details");

  const role = [...settlementRole, ...vendorDetailsRole];

  if (!role.includes("list") && !role.includes("report")) {
    navigate("/");
  }

  const getSalesProductReportList = async () => {
    try {
      const { startDate, vendor_id, type, status, ...rest } = filter;

      const payload: any = {
        ...rest,
        filter_data: {
          start_date: dateFormatter(startDate?.[0], "start"),
          end_date: dateFormatter(startDate?.[1], "end", startDate?.[0]),
          vendor_id,
          type,
        },
      };
      delete payload["isFilter"];
      delete payload["isClear"];
      const res: any = await getSalesProductReport(payload);
      if (filter?.isFilter && !filter?.isClear) {
        responseToaster(res?.data);
      }
    } catch (error) {
      console.error("Error =-=>", error);
    }
  };
  const getSalesProductReportListForChart = async () => {
    try {
      const { startDateChart, vendor_id, type, ...rest } = filterForChart;

      const payload: any = {
        ...rest,
        filter_data: {
          start_date: dateFormatter(startDateChart?.[0], "start"),
          end_date: dateFormatter(
            startDateChart?.[1],
            "end",
            startDateChart?.[0]
          ),
          vendor_id,
          type,
        },
      };
      delete payload["isFilter"];
      delete payload["isClear"];
      const res: any = await getSalesProductReportForChart(payload);
      if (filter?.isFilter && !filter?.isClear) {
        responseToaster(res?.data);
      }
    } catch (error) {
      console.error("Error =-=>", error);
    }
  };
  const { register, handleSubmit, reset, control } = useForm<any>({
    defaultValues: {
      ...initialValues,
    },
  });

  useEffect(() => {
    if (role.includes("list") || role.includes("report")) {
      getSalesProductReportList();
    }
    // eslint-disable-next-line
  }, [filter]);

  useEffect(() => {
    if (role.includes("list") || role.includes("report")) {
      getSalesProductReportListForChart();
    }
    // eslint-disable-next-line
  }, [filterForChart]);

  const onSubmit = async (values: any) => {
    setFilter({ ...FILTER, ...values, isFilter: true });
  };
  return (
    <>
      <div className="flex mb-3 gap-2 justify-between sm:flex-wrap">
        <CustomeBreadcrumbs breadcrumbs={breadcrumbs} />
        <Button
          classNm={`!text-ev-white !border-0 appl-btn1`}
          onClick={() => {
            setShowDiv(!showDiv);
          }}
        >
          <Icon name="FilterIcon" />
        </Button>
      </div>
      {showDiv ? (
        <div className="cardcss mb-3 flex">
          <form
            className="flex gap-2 sm:flex-wrap items-end"
            onSubmit={handleSubmit(onSubmit)}
          >
            <Select
              name="vendor_id"
              label="Vendor"
              placeholder="Select Vendor"
              options={prepareVendorOptions(
                salesProductReportList?.vendorsList
              )}
              register={register}
            />
            <ControlledDatePicker
              name="startDate"
              label="Sales Report Date"
              placeholder="Select Sales Report Date"
              selectsRange
              control={control}
              maxDate={new Date()}
            />
            {/*  <Select
              name="type"
              label="Type"
              options={timeOptions}
              isDefaultOption={false}
              register={register}
              className="w-10"
            /> */}
            <Button type="submit" className="!text-sm">
              Apply
            </Button>
            <Button
              variant="Transaparent"
              className="!text-sm"
              onClick={() => {
                reset();
                setFilter({
                  ...initialFilterState,
                  isFilter: true,
                  isClear: true,
                });
              }}
            >
              Clear
            </Button>
          </form>
        </div>
      ) : null}
      <div className="cardcss mb-3">
        <div className="grid grid-cols-3 gap-4 sm:grid-cols-1 lg:grid-cols-3 mb-3">
          {[
            {
              label: "Approved Order",
              value: salesProductReportList?.summary?.approved_order || 0,
              color: "red",
            },
            {
              label: "Decline Order",
              value: salesProductReportList?.summary?.decline_order || 0,
              color: "red",
            },
            {
              label: "Pending Order",
              value: salesProductReportList?.summary?.pending_order || 0,
              color: "red",
            },
          ]?.map?.((item: any, index) => (
            <StatusCard
              key={index}
              color={item.color}
              icon={item.icon}
              label={item.label}
              percentage={item.value}
              value={item.value}
              threeDotsLoader={isLoading}
            />
          ))}
        </div>
        <div className="cardcss mb-3">
          <ReportChart
            data={salesProductReportList?.data?.data}
            isLoading={isLoading}
          />
        </div>
      </div>
      <div className="cardcss">
        <div className="flex justify-end items-start gap-2">
          <div className="w-[200px]">
            <Select
              name="vendors_id"
              // label="Vendor"
              placeholder="Select Vendor"
              options={prepareVendorOptions(
                salesProductReportList?.vendorsList
              )}
              onChange={(e) => {
                setFilterForChart({
                  ...filterForChart,
                  vendor_id: e.target.value,
                });
              }}
              selectClassName="h-[34px]"
            />
          </div>
          <div className="flex justify-between w-fit p-1 rounded-md overflow-hidden bg-chatlook-gray">
            {["This Week", "Last Week"]?.map((tab, index) => {
              return (
                <div
                  key={index}
                  className={`px-6 !h-[27px] flex items-center justify-center cursor-pointer week_tab ${
                    currentTab !== index ? " " : "active"
                  }`}
                  onClick={() => {
                    setCurrentTab(index);
                    setFilterForChart({
                      ...filterForChart,
                      startDateChart:
                        index === 0
                          ? [thisWeek?.start, thisWeek?.end]
                          : [lastWeek?.start, lastWeek?.end],
                    });
                  }}
                >
                  {tab}
                </div>
              );
            })}
          </div>
        </div>
        <ReportChart
          data={salesProductReportListForChart?.data?.data}
          isLoading={chartLoading}
        />
      </div>
    </>
  );
};

export default SalesProductReport;
