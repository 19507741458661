// PROJECT IMPORT
import authSlice from "../features/auth/authSlice";
import commonSlice from "../features/common/commonSlice";

import { api } from "./api";
import {
  logout,
  showToaster,
  underMaintenance,
  verifyingUser,
} from "../helperFunctions";

// THIRD - PARTY IMPORT
import storage from "redux-persist/lib/storage";
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import {
  Middleware,
  MiddlewareAPI,
  combineReducers,
  configureStore,
} from "@reduxjs/toolkit";
import vendorSlice from "../features/vendor/vendorList/vendorSlice";
import categorySlice from "../features/category/categorySlice";
import productSlice from "../features/product/productSlice";
import usersSlice from "../features/customers/usersSlice";
import couponSlice from "../features/coupon/couponSlice";
import notificationSlice from "../features/notification/notificationSlice";
import promotionSlice from "../features/promotion/promotionSlice";
import settlementSlice from "../features/settlement/settlementSlice";
import dashboardSlice from "../features/dashboard/dashboardSlice";
import ordersSlice from "../features/orders/ordersSlice";
import deliveredBySlice from "../features/delivered/deliveredBy/deliveredBySlice";
import ticketSlice from "../features/ticket/ticketSlice";
import transactionSlice from "../features/subscriptions/transaction/transactionSlice";
import subscriptionsSlice from "../features/subscriptions/plan/subscriptionsSlice";
import vendorPlanSlice from "../features/vendor/vendorPlan/vendorPlanSlice";
import deliveredPersonSlice from "../features/delivered/deliveredPerson/deliveredPersonSlice";
import tagSlice from "../features/master/tag/tagSlice";
import variantSlice from "../features/variant/variantSlice";
import videoSlice from "../features/video/videoSlice";
import subCategorySlice from "../features/master/subCategory/subCategorySlice";
import policySlice from "../features/policy/policySlice";
import subscribeSlice from "../features/subscribe/subscribeSlice";
import userSlice from "../features/user/userSlice";
import roleSlice from "../features/role/roleSlice";
import permissionSlice from "../features/permission/permissionSlice";
import ratingReviewSlice from "../features/RatingReview/ratingReviewSlice";
import courierPartnerSlice from "../features/courier/courierPartner/courierPartnerSlice";
import citySlice from "../features/courier/city/citySlice";
import pinCodeSlice from "../features/courier/pinCode/pinCodeSlice";
import supportLogsSlice from "../features/developer/supportLogs/supportLogsSlice";
import salesReportSlice from "../features/report/SalesReport/salesReportSlice";
import salesProductReportSlice from "../features/report/SalesProductReport/salesProductReportSlice";

const rootReducer = combineReducers({
  [api.reducerPath]: api.reducer,
  common: commonSlice,
  dashboard: dashboardSlice,
  auth: authSlice,
  vendor: vendorSlice,
  category: categorySlice,
  product: productSlice,
  users: usersSlice,
  subscriptions: subscriptionsSlice,
  coupon: couponSlice,
  notification: notificationSlice,
  promotion: promotionSlice,
  settlement: settlementSlice,
  orders: ordersSlice,
  deliveredBy: deliveredBySlice,
  ticket: ticketSlice,
  transaction: transactionSlice,
  vendorPlan: vendorPlanSlice,
  deliveredPerson: deliveredPersonSlice,
  tag: tagSlice,
  variant: variantSlice,
  video: videoSlice,
  subCategory: subCategorySlice,
  policy: policySlice,
  subscribe: subscribeSlice,
  user: userSlice,
  role: roleSlice,
  permission: permissionSlice,
  ratingReview: ratingReviewSlice,
  courierPartner: courierPartnerSlice,
  city: citySlice,
  pinCode: pinCodeSlice,
  supportLogs: supportLogsSlice,
  salesReport:salesReportSlice,
  salesProductReport:salesProductReportSlice
});

const persistConfig = {
  key: "root",
  storage,
  blacklist: ["api"],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const middleware: Middleware =
  (api: MiddlewareAPI) => (next) => (action) => {
    // RTK Query uses `createAsyncThunk` from redux-toolkit under the hood, so we're able to utilize these matchers!
    const payload = action?.payload;
    const { dispatch } = api;
    if (payload?.data) {
      if (payload?.data?.status === false) {
        if (payload?.data?.message === "Two-factor authentication required.") {
          verifyingUser(dispatch, true);
          return;
        }
      } else {
        verifyingUser(dispatch, false);
      }
    }
    if (payload?.data) {
      if (payload?.data?.is_under_maintenance) {
        underMaintenance(dispatch, payload?.data?.is_under_maintenance);
        return;
      } else {
        underMaintenance(dispatch, false);
      }
    }

    if (
      payload?.data?.status === false &&
      !payload?.data?.is_under_maintenance
    ) {
      showToaster(payload?.data?.message || "", "Error");
      if (payload?.data?.message === "Unauthorized") {
        logout(dispatch);
      }
    }
    return next(action);
  };
export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    })
      .concat(api.middleware)
      .concat(middleware),
});

export const persistor = persistStore(store);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
