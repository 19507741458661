import React, { Fragment, useMemo } from "react";
import { Table, TableRow } from "../../ui";
import { prepareImageUrl, simpleEncrypt } from "../../helperFunctions";
import user from "../../assets/images/user.svg";
import { Link, useNavigate } from "react-router-dom";

const TopVendor = ({ topVendors }: any) => {
  const navigate = useNavigate();
  const columns = useMemo(
    () => [
      {
        title: "ID",
        name: "id",
        sortable: true,
        sortableKey: "id",
        Cell: (vendor: any) => {
          return (
            <>
              <div className="flex items-center">
                <div className="h-12 w-12 rounded-full overflow-hidden bg-gray-200 mr-4">
                  {vendor.photo ? (
                    <img
                      src={prepareImageUrl(vendor.photo)?.url}
                      alt={vendor.name}
                      className="h-full w-full object-cover"
                      onError={(e: any) => {
                        e.target.onerror = null;
                        e.target.src = user;
                        e.target.width = 40;
                        e.target.height = 40;
                      }}
                    />
                  ) : (
                    <img
                      src={user}
                      alt="vendor"
                      className="h-full w-full object-cover"
                    />
                  )}
                </div>

                <div className="text-gray-700">
                  <p className="font-semibold">{vendor?.name}</p>
                  <p className="text-xs text-gray-500">{vendor?.contact}</p>
                </div>
              </div>
            </>
          );
        },
      },
      {
        title: "Name",
        name: "name",
        sortable: true,
        sortableKey: "name",
        Cell: (vendor: any) => {
          return (
            <>
              <div className="bg-green-100 text-green-600 font-semibold px-3 py-1 rounded-md text-center">
                Sold : {vendor.total_sales}
              </div>
            </>
          );
        },
      },
    ],
    []
  );
  return (
    <div>
      <div className="flex justify-between items-center pb-2">
        <h2 className="text-lg font-semibold text-gray-800">Top Vendors</h2>
        <Link
          to={`/vendor/vendor/get-vendor?keyId=${simpleEncrypt(
            "bestSelling",
            "bsproduct"
          )}`}
          className="text-ev-primary text-sm"
        >
          View All
        </Link>
      </div>
      <div className="h-[400px] overflow-y-scroll hidden_scroll">
        <Table
          columns={columns}
          className="userTable"
          isExpendable={false}
          data={topVendors || []}
          showHeader={false}
          count={topVendors?.length}
        >
          {topVendors?.map((item: any, index: number) => {
            return (
              <Fragment key={index}>
                <TableRow
                  columns={columns}
                  isExpendable={false}
                  item={item}
                  className="cursor-pointer"
                  onClick={(item: any) => {
                    navigate(`/vendor/vendor/get-vendor/${item?.id}`);
                  }}
                ></TableRow>
              </Fragment>
            );
          })}
        </Table>
      </div>
    </div>
  );
};

export default TopVendor;
