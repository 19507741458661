import React, { memo } from "react";
import { IconProps } from ".";

export interface SalesProductsReportsIconProps extends IconProps {}
const SalesProductsReports = (props: SalesProductsReportsIconProps) => {
  const {
    className = "",
    size = 20,
    stroke = "currentColor",
    fill = "none",
    ...rest
  } = props;
  return (
    <svg
      className={`${className}`}
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        fill={stroke}
        d="M22 21H2V3h2v16h2v-2h4v2h2v-3h4v3h2v-2h4zm-4-7h4v2h-4zm-6-8h4v3h-4zm4 9h-4v-5h4zM6 10h4v2H6zm4 6H6v-3h4z"
      />
    </svg>
  );
};

const MemoSalesProductsReportsIcon = memo(SalesProductsReports);
export default MemoSalesProductsReportsIcon;
