import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Form } from "reactstrap";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {
  Button,
  ControlledDropDown,
  ControlledInput,
  Password,
} from "../../ui";
import { useAddUserMutation, useUpdateUserMutation } from "./userApi";
import { responseToaster } from "../../helperFunctions";
import { IoIosCloseCircle } from "react-icons/io";
import { useUserList } from "./userSlice";

const UserModal = (props: any) => {
  const { onHide, isOpen, onGet, userData, currentTab, pwdData } = props;
  const [addCpisLoading, setaddCpIsLoading] = useState<boolean>(false);
  const [isAdmin, setIsAdmin] = useState<any>(true);
  const [addUser] = useAddUserMutation();
  const [updateUser] = useUpdateUserMutation();
  const userList = useUserList();

  const schema: any = yup.object({
    name: yup.string().required("Please enter a name"),
    password: yup.string().required("Please enter a password"),
    contact: yup.string().required("Please enter a contact number"),
    role_id: yup.string().required("Please select a role"),
    email: yup
      .string()
      .matches(/^[^\s@]+@[^\s@]+\.[^\s@]+$/, "Invalid email address")
      .required("Please enter a Email"),
  });
  const schema2: any = yup.object({
    name: yup.string().required("Please enter a name"),
    contact: yup.string().required("Please enter a contact number"),
    role_id: yup.string().required("Please select a role"),
    email: yup
      .string()
      .matches(/^[^\s@]+@[^\s@]+\.[^\s@]+$/, "Invalid email address")
      .required("Please enter a Email"),
  });

  const schema1: any = yup.object({
    password: yup.string().required("Please enter a password"),
  });

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    register,
    trigger,
    formState: { errors },
  }: any = useForm({
    resolver: yupResolver(
      !!pwdData ? schema1 : userData?.id ? schema2 : schema
    ),
  });

  const onClose = () => {
    reset();
    onHide();
    setIsAdmin(true);
  };
  useEffect(() => {
    if (isOpen) {
      if (userData?.id) {
        setValue("name", userData?.name);
        setValue("email", userData?.email);
        setValue("contact", userData?.contact);
        setValue("role_id", userData?.role_id);
        setValue(
          "city",
          userData?.admin_city?.map?.((city: any) => {
            return city?.city_id;
          })
        );
      }
    }
    // eslint-disable-next-line
  }, [isOpen, userData?.id]);

  const onSubmit = async (values: any) => {
    try {
      setaddCpIsLoading(true);
      const payload = {
        ...values,
        role_id: +values?.role_id,
      };
      const payload1 = {
        password: values?.password,
      };

      const res: any =
        userData?.id || !!pwdData?.id
          ? await updateUser({
              id: userData?.id || pwdData?.id,
              ...(!!pwdData ? payload1 : payload),
            }).unwrap()
          : await addUser(payload).unwrap();

      if (res?.status) {
        onClose();
        onGet?.();
      }
      responseToaster(res);
      setaddCpIsLoading(false);
    } catch (error) {
      setaddCpIsLoading(false);
    }
  };

  const prepareRoleOptions = () => {
    if (userList?.roles?.length) {
      const roleOptions: any = userList?.roles
        ?.map?.((val: { id: any; name: any }) => ({
          value: val?.id,
          label: val?.name,
        }))
        ?.sort((a: { name: string }, b: { name: any }) =>
          a?.name?.localeCompare?.(b?.name)
        );
      return roleOptions || [];
    }
  };
  const prepareCityOptions = () => {
    if (userList?.city?.length) {
      const roleOptions: any = userList?.city
        ?.map?.((val: { id: any; name: any }) => ({
          value: val?.id,
          label: val?.name,
        }))
        ?.sort((a: { name: string }, b: { name: any }) =>
          a?.name?.localeCompare?.(b?.name)
        );
      return roleOptions || [];
    }
  };

  if (!isOpen) return null;
  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center">
      <div
        className="modal-overlay absolute w-full h-full bg-gray-900 opacity-50"
        onClick={() => {
          if (!addCpisLoading) {
            onClose();
          }
        }}
      />

      <div
        className={`modal-container bg-chatlook-white border ${
          !!pwdData
            ? "w-8/12 2xl:w-4/12 xl:w-4/12 lg:w-6/12  md:w-7/12 sm:w-10/12"
            : "w-8/12 2xl:w-6/12 xl:w-6/12 lg:w-8/12  md:w-9/12 sm:w-10/12"
        }   mx-auto rounded-lg shadow-lg z-50 overflow-y-auto`}
      >
        <div className="modal-content p-6 text-left  max-h-[84vh]">
          <Form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
            <div className="modal-header flex justify-between">
              <h4 className="modal-title mb-4 font-bold capitalize">
                {!!pwdData
                  ? `Update password for ${pwdData?.name}`
                  : `${
                      userData?.id
                        ? currentTab === "Vendor"
                          ? "View"
                          : "Update"
                        : "Add"
                    } `}{" "}
                User
              </h4>
              {!isAdmin ? (
                <div onClick={() => onClose()} className="cursor-pointer">
                  <IoIosCloseCircle color="var(--ev-primary--)" size={22} />
                </div>
              ) : null}
            </div>
            <div className="modal-body">
              <div
                className={`grid ${
                  !!pwdData
                    ? "grid-cols-1"
                    : "md:grid-cols-2 lg:grid-cols-2 2xl:grid-cols-2 xl:grid-cols-2 sm:grid-cols-1"
                } items-start gap-3 pt-2 pb-3`}
              >
                {!pwdData ? (
                  <>
                    <ControlledInput
                      name="name"
                      label="Name"
                      placeholder="Enter Name"
                      type="text"
                      className="sm:col-span-2"
                      errors={errors}
                      control={control}
                    />
                    <ControlledInput
                      name="email"
                      label="Email"
                      placeholder="Enter Email"
                      autoComplete="email"
                      type="text"
                      className="sm:col-span-2"
                      errors={errors}
                      control={control}
                    />
                    <ControlledDropDown
                      name="city"
                      label="City"
                      placeholder="Select City"
                      isMulti
                      options={prepareCityOptions()}
                      control={control}
                      errors={errors}
                      isDisabled={!isAdmin}
                    />
                    <ControlledInput
                      name="contact"
                      label="Contact Number"
                      placeholder="Enter Contact Number"
                      className="sm:col-span-2"
                      onChange={(e) => {
                        if (
                          e?.target?.value?.length <= 10 &&
                          (e?.target?.value === "" ||
                            /^\d+$/.test(e?.target?.value))
                        ) {
                          setValue("contact", e?.target?.value);
                          trigger("contact");
                        }
                      }}
                      errors={errors}
                      control={control}
                    />
                    {!userData?.id ? (
                      <Password
                        name="password"
                        className="sm:col-span-2 w-full"
                        inputClassName="h-[36px]"
                        placeholder="Password"
                        label="Password"
                        autoComplete="new-password"
                        errors={errors}
                        register={register}
                      />
                    ) : null}
                    <ControlledDropDown
                      name="role_id"
                      label="Role"
                      placeholder="Select Role"
                      options={prepareRoleOptions()}
                      control={control}
                      errors={errors}
                      isDisabled={!isAdmin}
                    />
                  </>
                ) : (
                  <Password
                    name="password"
                    className="sm:col-span-2 w-full"
                    inputClassName="h-[36px]"
                    placeholder="Password"
                    label="Password"
                    autoComplete="off"
                    errors={errors}
                    register={register}
                  />
                )}
              </div>
            </div>
            <div className="modal-footer pb-2">
              <div className="flex items-center justify-end gap-2">
                <Button
                  type="submit"
                  className="mt-[10px] !h-9 !text-sm"
                  isLoading={addCpisLoading}
                  isLoader={addCpisLoading}
                >
                  {userData?.id ? "Update" : !!pwdData ? "Update" : "Submit"}
                </Button>
                <Button
                  variant="Transaparent"
                  className="mt-[10px]  !text-sm !h-9"
                  disabled={addCpisLoading}
                  onClick={() => onClose()}
                >
                  Cancel
                </Button>
              </div>
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default UserModal;
