import React, { useState } from "react";
import { Label } from "reactstrap";
import Picker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const DatePicker1 = (props: any) => {
  const {
    className = "",
    label,
    icon: Icon,
    selected,
    selectsRange,
    onChange,
    startDate,
    endDate,
    placeholder,
    isKeyDown = true,
    maxDate,
    minDate,
    width = "100%",
    isFilter,
    dateFormat = "dd/MM/yyyy",
    ...rest
  } = props;

  const [isOpen, setIsOpen] = useState(false);

  return (
    <div
      className={`${className} react-date-picker d-flex flex-column select-div`}
      onMouseLeave={() => setIsOpen(false)}
    >
      {label ? <Label>{label}</Label> : null}
      <div
        className="d-flex align-items-center flatpicker-main-div"
        style={{ width: width }}
        onClick={() => setIsOpen(true)}
      >
        <Picker
          // className="datepicker"
          selected={selected}
          onChange={(e) => {
            onChange?.(e);
          }}
          {...(isKeyDown && {
            onKeyDown: (e) => {
              if (e.key === "Backspace" || e.key === "Delete") {
                onChange?.(selectsRange ? [null, null] : null);
              }
            },
          })}
          selectsRange={selectsRange}
          startDate={startDate}
          endDate={endDate}
          placeholderText={placeholder}
          maxDate={maxDate}
          minDate={minDate}
          autoComplete="off"
          dateFormat={dateFormat}
          open={isOpen}
          {...rest}
        />
      </div>
    </div>
  );
};

export default DatePicker1;
