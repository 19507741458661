import React from "react";
import { IoMdCloseCircle } from "react-icons/io";
import { RiUploadCloud2Line } from "react-icons/ri";

interface SingleImagePickerProps {
  setImage: any;
  setValue: any;
  setImage1: any;
  clearErrors: any;
  image: any;
  errors: any;
  name: any;
  watch: any;
  label: any;
  disabled?: any;
  className?: any;
  imgClassName?:any
}

const SingleImagePicker = (props: SingleImagePickerProps) => {
  const {
    setImage,
    setValue,
    setImage1,
    clearErrors,
    image,
    errors,
    name,
    watch,
    label,
    disabled,
    className,
    imgClassName
  } = props;

  const message = errors?.[name]?.message || "";

  const closeImage = (e: any) => {
    e.stopPropagation();
    e.preventDefault();
    setValue(name, "");
    setImage("");
    setImage1("");
  };

  return (
    <div
      className={`w-full flex flex-col justify-center relative ${className}`}
    >
      <label className={`text-sm text-ev-secondary`}>{label}</label>
      <div className="file-input">
        <>
          <input
            type="file"
            id="file-input"
            accept=".jpg, .jpeg, .png"
            disabled={disabled}
            className="file-input__input"
            onChange={(e: any) => {
              try {
                const file: any = e.target.files?.[0];
                if (file) {
                  setImage(URL.createObjectURL(file));
                  setValue(name, file);
                  setImage1(file);
                  clearErrors(name);
                }
                e.target.value = null;
              } catch (err) {
                console.error("======", err);
              }
            }}
          />
          <label
            className={`file-input__label1 ${imgClassName}`}
            htmlFor="file-input"
            style={{
              backgroundImage: `url(${image})`,
              backgroundPosition: "center",
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              border: message ? "1px solid rgba(239, 68, 68, 1)" : "",
            }}
            onClick={(e) => {
              if (image) {
                e.stopPropagation();
                e.preventDefault();
              }
            }}
          >
            <span className="font-semibold pt-2 text-ev-primary text-xs">
              {watch(name) ? `` : "Click to Upload Image"}{" "}
              {image ? null : (
                <RiUploadCloud2Line className="w-10 h-10 m-auto" />
              )}
            </span>
            {image && !disabled ? (
              <IoMdCloseCircle
                size={25}
                className="absolute right-1 top-1"
                style={{ color: "var(--ev-primary--)" }}
                onClick={(e: any) => closeImage(e)}
              />
            ) : null}
          </label>
        </>
      </div>
      {message ? <span className="text-red-500 text-sm">{message}</span> : null}
    </div>
  );
};

export default SingleImagePicker;
