import React from "react";
import ThreeDotsLoader from "../../ui/elements/ThreeDotsLoader";

const getBg: any = {
  total_orders: "#dcfaf8",
  pending_order: "text-blue-500",
  new_delivery: "#ffe0eb",
  approved_order: "text-green-500",
  total_user: "#e7edff",
  today_delivery_orders: "text-yellow-500",
  total_vendor: "#92cace",
  total_amount: "#ffe0eb",
  decline_order: "text-red-500",
};

const StatCardCompact = ({
  label,
  value,
  icon,
  dataKey,
  summaryLoading,
}: any) => {
  return (
    <div className="flex items-center justify-between border border-gray-200 rounded-lg p-4 shadow-sm w-full hover:shadow-md transition-shadow duration-300 ease-in-out">
      <div className="flex items-center gap-2">
        <img src={icon} alt={label} className="w-12 mr-2" />

        <span className="text-gray-600 text-lg font-semibold">{label}</span>
      </div>
      {summaryLoading ? (
        <ThreeDotsLoader className="!justify-end" />
      ) : (
        <span className={`text-2xl font-bold ${getBg?.[dataKey]}`}>
          {value}
        </span>
      )}
    </div>
  );
};

export default StatCardCompact;
