import React, { Fragment, useEffect, useState } from "react";
import {
  setCurrTab,
  setCurrentProduct,
  setProduct,
  useCurrentTab,
  useProductList,
  useUnApprovedProductList,
  useVendorProductList,
} from "./productSlice";
import { FILTER, statusOptions } from "../../constant";
import {
  useDeleteProductMutation,
  useGetProductMutation,
  useUpdateProductMutation,
} from "./productApi";
import {
  Button,
  ControlledDropDown,
  Icon,
  Select,
  Table,
  TableRow,
} from "../../ui";
import DeleteModal from "../common/DeleteModal";
import { useDispatch } from "react-redux";
import {
  prepareMainCategoryOptions,
  prepareVendorOptions,
  responseToaster,
  seperator,
  simpleDecrypt,
} from "../../helperFunctions";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { MdEdit, MdDeleteOutline } from "react-icons/md";
import Switch from "../../ui/forms/Switch";
import Tooltip from "../../ui/elements/Tooltip";
import { IoEye } from "react-icons/io5";
import BulkUploadModal from "./BulkUploadModal";
import { useForm } from "react-hook-form";

import CustomeBreadcrumbs from "../common/CustomeBreadcrumbs";
import { useRole, useVendorId } from "../auth/authSlice";
import ReadMoreLess from "../common/ReadMoreLess";

const initialValues = {
  vendor_id: null,
  main_category: null,
};
interface ProductTabs {
  label: string;
  Component?: any;
}

const Product = () => {
  const [getProduct, { isLoading }] = useGetProductMutation();
  const [updateProduct] = useUpdateProductMutation();
  const [deleteProduct, { isLoading: isDeleteLoading }] =
    useDeleteProductMutation();
  const productList = useProductList();
  const currentTab = useCurrentTab();
  const productVendorList = useVendorProductList();
  const unApprovedProductList = useUnApprovedProductList();
  const [tableData, setTableData] = useState<any>([]);
  const [filter, setFilter] = useState<any>(FILTER);
  const [statusLoading, setStatusLoading] = useState(false);
  const [currKey, setCurrKey] = useState("");
  const [deleteId, setDeleteId] = useState<any>("");

  const [bulkUploadModal, setBulkUploadModal] = useState<boolean>(false);
  const [showDiv, setShowDiv] = useState(false);
  const hasVendorId = useVendorId();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const decryptedKey = simpleDecrypt(searchParams?.get("keyId"), "bsproduct");

  const productRole = useRole("product");
  const vendorDetailsRole = useRole("vendor-details");

  const role = [...productRole, ...vendorDetailsRole];
  // const vendorDetail = useRole("vendor-details");
  if (!role.includes("list")) {
    navigate("/");
  }
  const route = useLocation()?.pathname;
  const breadcrumbs = [
    { label: "Master" },
    { label: "Product" },
    { label: currentTab },
  ];

  const ProductTab: ProductTabs[] = [
    { label: "Admin", Component: Product },
    { label: "Vendor", Component: Product },
    { label: "UnApproved", Component: Product },
  ];

  const onUpdateStatus = async (payload: any, key: any) => {
    try {
      setStatusLoading(payload?.id);
      setCurrKey(key);
      const formData = new FormData();
      formData.append("title", payload?.title);
      formData.append("id", payload?.id);
      formData.append("price", payload?.price);
      formData.append("discount_price", payload?.discount_price);
      formData.append("description", payload?.description);
      formData.append("category_id", payload?.category_id);
      payload?.photo?.split(",")?.forEach((images: any, index: number) => {
        formData.append(`photo${index}`, images);
      });
      formData.append("photo_count", payload?.photo?.split(",")?.length);
      formData.append("vendor_id", payload?.vendor_id);
      formData.append("stock", payload?.stock);
      formData.append("status", payload?.status);
      formData.append("is_approved", payload?.is_approved);
      formData.append("child", payload?.child);

      const res = await updateProduct(formData).unwrap();

      if (key === "is_approved") {
        const filteredData = tableData.filter(
          (item: { id: any }) => item?.id !== payload?.id
        );
        dispatch(
          setProduct({ ...productList, data: filteredData, currentTab })
        );
      } else {
        const index = tableData?.findIndex(
          (item: { id: any }) => item?.id === payload?.id
        );
        if (index > -1) {
          const newData = [...tableData];
          newData[index] = {
            ...newData[index],
            [key]: payload?.[key],
          };
          dispatch(setProduct({ ...productList, data: newData, currentTab }));
        }
      }
      responseToaster(res);
      setStatusLoading(false);
      setCurrKey("");
    } catch (err) {
      setStatusLoading(false);
      setCurrKey("");
    }
  };

  const columns = [
    {
      title: "ID",
      name: "id",
      sortable: true,
      sortableKey: "id",
    },
    {
      title: "Title",
      name: "title",
      sortable: true,
      sortableKey: "title",
      Cell: (data: any) => {
        return data?.title ? (
          <ReadMoreLess text={data?.title} maxLength={50} />
        ) : (
          " -"
        );
      },
    },
    {
      title: "Category",
      name: "main category",
      sortable: true,
      sortableKey: "category_details.title",
      Cell: (data: any) => {
        return (
          <span className="w-full break-words">
            {data?.category_details?.title || "-"}
          </span>
        );
      },
    },
    {
      title: "MRP",
      name: "price",
      sortable: true,
      sortableKey: "price",
      Cell: (data: any) => {
        return (
          <span className="w-full break-words">{seperator(data?.price)}</span>
        );
      },
    },
    {
      title: "Discount Price",
      name: "discount_price",
      sortable: true,
      sortableKey: "discount_price",
      Cell: (data: any) => {
        return <span>{seperator(data?.discount_price)}</span>;
      },
    },
    {
      title: "Status",
      name: "status",
      showColumn: currentTab !== "UnApproved",
      Cell: (data: any) => (
        <Switch
          checked={data?.status === "active"}
          isLoading={statusLoading === data?.id && currKey === "status"}
          isDisabled={!role.includes("edit") || statusLoading}
          onChange={async (value: any, e: any) => {
            e.stopPropagation();
            try {
              const payload = {
                ...data,
                status: value ? "active" : "inactive",
                id: data?.id,
              };
              await onUpdateStatus(payload, "status");
            } catch (err) {}
          }}
        />
      ),
      sortable: true,
      sortableKey: "status",
    },
    {
      title: "Approved",
      name: "is_approved",
      Cell: (data: any) => (
        <Switch
          checked={data?.is_approved}
          isLoading={statusLoading === data?.id && currKey === "is_approved"}
          isDisabled={!role.includes("edit") || statusLoading}
          onChange={async (value: any, e: any) => {
            e.stopPropagation();
            try {
              const payload = {
                ...data,
                is_approved: value ? 1 : 0,
                id: data?.id,
              };
              await onUpdateStatus(payload, "is_approved");
            } catch (err) {}
          }}
        />
      ),
    },
    {
      title: "Action",
      name: "action",
      Cell: (data: any) => (
        <>
          <div className="flex gap-2">
            {data?.is_admin ? (
              <Tooltip text="Edit">
                <Button
                  className="!h-8 !w-8 !p-0"
                  disabled={!role.includes("edit")}
                  onClick={() => {
                    dispatch(setCurrentProduct(data));
                    navigate(`${route}/${data?.id}`);
                  }}
                >
                  <MdEdit />
                </Button>
              </Tooltip>
            ) : (
              <Tooltip text="Preview">
                <Button
                  className="!h-8 !w-8 !p-0"
                  onClick={() => {
                    dispatch(setCurrentProduct(data));
                    navigate(`${route}/${data?.id}`);
                  }}
                >
                  <IoEye />
                </Button>
              </Tooltip>
            )}
            <Tooltip text="Delete" className="right-0">
              <Button
                className="!h-8 !w-8 !p-0"
                disabled={!role.includes("delete")}
                onClick={(e: any) => {
                  e.stopPropagation();
                  e.preventDefault();
                  setDeleteId(data?.id);
                }}
              >
                <MdDeleteOutline />
              </Button>
            </Tooltip>
          </div>
        </>
      ),
    },
  ];

  const getProductList = async () => {
    try {
      const { vendor_id, status, main_category, ...rest } = filter;
      const payload: any = {
        ...rest,
        vendor_id: vendor_id,
        filter_data: {
          status: status,
          category_id: main_category,
          ...(decryptedKey === "bestSelling" && { type: "bestselling" }),
        },
      };

      if (currentTab === "Vendor") {
        payload.filter_data.is_admin = 0;
        payload.filter_data.is_approved = 1;
      } else if (currentTab === "Admin") {
        payload.filter_data.is_admin = 1;
        payload.filter_data.is_approved = 1;
      } else if (currentTab === "UnApproved") {
        payload.filter_data.is_approved = 0;
      }

      delete payload["isFilter"];
      delete payload["isClear"];
      const res: any = await getProduct(payload);
      if (filter?.isFilter && !filter?.isClear) {
        responseToaster(res?.data);
      }
    } catch (error) {
      console.error("Error =-=>", error);
    }
  };

  useEffect(() => {
    if (currentTab === "Admin") {
      setTableData(productList?.data?.length ? [...productList?.data] : []);
    }
    if (currentTab === "Vendor") {
      setTableData(
        productVendorList?.data?.length ? [...productVendorList?.data] : []
      );
    }
    if (currentTab === "UnApproved") {
      setTableData(
        unApprovedProductList?.data?.length
          ? [...unApprovedProductList?.data]
          : []
      );
    }
  }, [productList, productVendorList, unApprovedProductList, currentTab]);

  useEffect(() => {
    if (role.includes("list")) {
      getProductList();
    }
    // eslint-disable-next-line
  }, [filter, currentTab]);

  const { register, handleSubmit, reset, control, watch } = useForm<any>({
    defaultValues: {
      ...initialValues,
    },
  });

  const onDelete = async () => {
    try {
      const payload: any = {
        id: deleteId,
      };

      const res = await deleteProduct(payload).unwrap();
      responseToaster(res);
      setFilter({
        ...filter,
      });
      setDeleteId("");
    } catch (err) {
      console.error("Error =-=>", err);
      setDeleteId("");
    }
  };
  const onSubmit = async (values: any) => {
    setFilter({ ...FILTER, ...values, isFilter: true });
  };

  const prepareMainCategoryOptions1 = () => {
    const options: any = [];
    productList?.categoryList?.forEach((category: any) => {
      prepareCategoryOptionsRecursive(category, options);
    });
    return options;
  };

  const prepareCategoryOptionsRecursive = (
    category: { title: any; children: any[]; id: any },
    options: { value: any; label: any }[],
    level = 1
  ) => {
    if (category.children && category.children.length > 0 && level !== 2) {
      category.children.forEach((child) => {
        prepareCategoryOptionsRecursive(child, options, level + 1);
      });
    } else {
      if (level === 2) {
        options.push({
          value: category?.id,
          label: category.title,
        });
      }
    }
  };

  return (
    <div>
      {role.includes("create") ? (
        <div className="flex sm:flex-wrap gap-2 justify-between mb-3">
          <CustomeBreadcrumbs breadcrumbs={breadcrumbs} />
          <div className="flex gap-2 sm:flex-wrap">
            <Button
              classNm={`!text-ev-white !border-0 appl-btn1`}
              onClick={() => {
                setShowDiv(!showDiv);
              }}
            >
              <Icon name="FilterIcon" />
            </Button>
            {currentTab === "Admin" ? (
              <div className="flex  gap-2">
                <Button
                  onClick={() => {
                    setBulkUploadModal(true);
                  }}
                >
                  Bulk Upload
                </Button>
                <Button
                  onClick={() => {
                    navigate(`${route}/create`);
                  }}
                >
                  Add Product
                </Button>
              </div>
            ) : null}
          </div>
        </div>
      ) : null}
      {showDiv && (
        <div className="cardcss mb-3">
          <form
            className="flex gap-2 items-end sm:flex-wrap "
            onSubmit={handleSubmit(onSubmit)}
          >
            {!hasVendorId?.id ? (
              <ControlledDropDown
                name="vendor_id"
                label="Vendor"
                placeholder="Select Vendor"
                options={prepareVendorOptions(productList?.vendorsList)}
                control={control}
              />
            ) : null}
            <ControlledDropDown
              name="main_category"
              label="Category"
              placeholder="Select Category"
              options={
                !watch("vendor_id")
                  ? prepareMainCategoryOptions1()
                  : prepareMainCategoryOptions(
                      productList?.vendorsList,
                      watch("vendor_id"),
                      productList?.categoryList
                    )
              }
              control={control}
            />
            <Select
              name="status"
              label="Status"
              placeholder="All"
              options={statusOptions}
              register={register}
            />
            <Button type="submit" className="!text-sm">
              Apply
            </Button>
            <Button
              variant="Transaparent"
              className="!text-sm"
              onClick={() => {
                reset();
                setFilter({ ...FILTER, isFilter: true, isClear: true });
              }}
            >
              Clear
            </Button>
          </form>
        </div>
      )}

      {role.includes("list") ? (
        <div className="cardcss">
          <div className="flex mb-3 gap-4 justify-between border-b-2 p-1 ">
            <div className="flex gap-2 pb-1 sm:flex-wrap">
              {ProductTab?.map((tab, index) => {
                return (
                  <div
                    className={` px-6 h-9 flex items-center justify-center cursor-pointer tab-transition ${
                      currentTab !== tab?.label ? " " : "active"
                    }`}
                    key={index}
                    onClick={() => {
                      setFilter({
                        ...FILTER,
                      });
                      reset();
                      dispatch(setCurrTab(tab?.label));
                    }}
                  >
                    {tab?.label}
                  </div>
                );
              })}
            </div>
          </div>
          <Table
            className="productTable"
            columns={columns}
            isLoading={isLoading}
            data={tableData || []}
            setData={setTableData}
            count={
              currentTab === "Admin"
                ? productList?.total_item
                : currentTab === "Vendor"
                ? productVendorList?.total_item
                : currentTab === "UnApproved"
                ? unApprovedProductList?.total_item
                : 0
            }
            pagination={filter}
            isExpendable={false}
            handlePaginationChange={(pagination) => {
              setFilter({
                ...filter,
                ...pagination,
                isFilter: false,
              });
            }}
          >
            {tableData?.map((item: any, index: number) => {
              return (
                <Fragment key={index}>
                  <TableRow
                    columns={columns}
                    item={item}
                    isExpendable={false}
                  ></TableRow>
                </Fragment>
              );
            })}
          </Table>
        </div>
      ) : null}
      <DeleteModal
        title="Delete Bank Proxy"
        content="Are you sure to delete this product ?"
        isOpen={!!deleteId}
        doneText="Delete"
        onDoneClick={() => onDelete()}
        onCancelClick={() => setDeleteId("")}
        onHide={() => setDeleteId("")}
        isButtonLoading={isDeleteLoading}
      />
      <BulkUploadModal
        currentTab={currentTab}
        isOpen={bulkUploadModal}
        onHide={() => {
          setBulkUploadModal(false);
        }}
        onGet={getProductList}
      />
    </div>
  );
};

export default Product;
