import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Form } from "reactstrap";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {
  Button,
  ControlledDatePicker,
  ControlledDropDown,
  ControlledInput,
  Input,
} from "../../ui";
import {
  useAddCouponMutation,
  useGetCouponByIdMutation,
  useUpdateCouponMutation,
} from "./couponApi";
import {
  dateFormatter,
  removeTags,
  responseToaster,
} from "../../helperFunctions";
import ControlledTextArea from "../../ui/forms/ControlledTextArea";
import { useCouponList } from "./couponSlice";
import { DROPDOWN_ALL_VALUE } from "../../constant";

const prepareDescountrOptions = [
  {
    value: "percent",
    label: "Percentage",
  },
  {
    value: "fixed",
    label: "Fixed",
  },
];

const CouponModal = (props: any) => {
  const { onHide, isOpen, onGet, currentTab, couponData } = props;
  const [addCpisLoading, setaddCpIsLoading] = useState<boolean>(false);
  const [isPer, setIsPer] = useState<any>("");
  const [percentage, setPercentage] = useState("");
  const [isAdmin, setIsAdmin] = useState<any>(false);
  const [productsOptions, setProductOptions] = useState([]);

  const couponList = useCouponList();
  const [addCoupon] = useAddCouponMutation();
  const [updateCoupon] = useUpdateCouponMutation();
  const [getCouponById] = useGetCouponByIdMutation();

  const schema: any = yup.object({
    code: yup.string().required("Please enter a code"),
    discount_type: yup.string().required("Please add a discount type"),
    coupon_amount: yup
      .number()
      .required(
        `Please add a coupon ${isPer === "percent" ? "percentage" : "amount"}`
      )
      .typeError("The value must be a number")
      .test(
        "max-validation",
        "must be less than or equal to 100",
        function (value, { parent }) {
          const { discount_type } = parent;
          if (discount_type === "percent") {
            return value <= 100;
          }
          return true;
        }
      )
      .test("min-validation", "must be greater than 0", function (value) {
        return value !== 0;
      }),
    coupon_expiry_date: yup
      .string()
      .required("Please select a coupon expiry date"),

    minimum_spend: yup.string().required("Please add a minimum spend"),
    maximum_spend: yup
      .string()
      .test(
        "is-greater",
        "Maximum spend amount must be more than minimum spend amount",
        function (value: any, context) {
          const { minimum_spend } = context.parent;
          return parseFloat(value) >= parseFloat(minimum_spend);
        }
      )
      .required("Please add a maximum spend"),
    products: yup.array().min(1, "Please select products"),
    product_categories: yup.array().min(1, "Please select product categories"),
  });
  const prepareProductsOptions = () => {
    const categoriesId = getValues().product_categories;
    if (categoriesId?.length) {
      const options = couponList?.productList
        ?.filter((product: any) => {
          return categoriesId.includes(product?.category_details?.id);
        })
        .map((product: any) => {
          return {
            value: product.id,
            label: product.title,
          };
        });
      return options;
    } else {
      const options = couponList?.productList?.map((product: any) => {
        return {
          value: product.id,
          label: product.title,
        };
      });
      return options;
    }
  };

  const prepareProductCategoryOptions = () => {
    const options: any = [];
    couponList?.categoryList?.forEach?.((category: any) => {
      prepareCategoryOptionsRecursive(category, options);
    });
    return options;
  };

  const prepareCategoryOptionsRecursive = (
    category: { title: any; children: any[]; id: any },
    options: { value: any; label: any }[],
    level = 1
  ) => {
    if (category.children && category.children.length > 0 && level !== 2) {
      category.children.forEach?.((child) => {
        prepareCategoryOptionsRecursive(child, options, level + 1);
      });
    } else {
      if (level === 2) {
        options.push({
          value: category?.id,
          label: category.title,
        });
      }
    }
  };

  const {
    control,
    handleSubmit,
    getValues,
    reset,
    setValue,
    trigger,
    watch,
    formState: { errors },
  }: any = useForm({
    resolver: yupResolver(schema),
  });

  const onClose = () => {
    reset();
    onHide();
    setPercentage("");
    setIsAdmin(currentTab === "Admin");
  };

  const getCoupon = async () => {
    try {
      const payload: any = {
        id: couponData?.id,
      };
      const res: any = await getCouponById(payload);
      if (res?.data?.status) {
        setFormData(res?.data?.data);
        setIsAdmin(res?.data?.data?.vendor_id === 0);
      }
    } catch (error) {}
  };

  const setFormData = (couponData: any) => {
    if (couponData?.id) {
      setPercentage(
        couponData?.discount_type === "percent"
          ? `${couponData?.coupon_amount}%`
          : ""
      );
      setValue("code", couponData?.code);
      setValue("discount_type", couponData?.discount_type);
      setValue("coupon_amount", couponData?.coupon_amount);
      setValue("coupon_expiry_date", new Date(couponData?.coupon_expiry_date));
      setValue("minimum_spend", couponData?.minimum_spend);
      setValue("maximum_spend", couponData?.maximum_spend);
      setValue("max_discount", couponData?.max_discount);
      setValue(
        "products",
        couponData?.products?.split(",")?.map?.((item: any) => +item) || []
      );
      setValue(
        "product_categories",
        couponData?.product_categories
          ?.split(",")
          ?.map?.((item: any) => +item) || []
      );
      setValue("description", removeTags(couponData?.description));
    } else {
      setValue("products", []);
      setValue("product_categories", []);
    }
  };

  useEffect(() => {
    if (isOpen) {
      if (currentTab === "Admin") {
        setIsAdmin(true);
      }
      if (couponData?.id) {
        getCoupon();
      } else {
        setIsAdmin(true);
      }
    }
    // eslint-disable-next-line
  }, [isOpen, couponData, couponData?.id]);

  useEffect(() => {
    setProductOptions(prepareProductsOptions());
    // eslint-disable-next-line
  }, [watch("product_categories")]);

  const onSubmit = async (values: any) => {
    try {
      setaddCpIsLoading(true);
      const payload = {
        ...values,
        ...(currentTab !== "Vendor" ? { vendor_id: 0 } : null),
        coupon_expiry_date: dateFormatter(values?.coupon_expiry_date),
      };
      const res: any = couponData?.id
        ? await updateCoupon({ id: couponData?.id, ...payload }).unwrap()
        : await addCoupon(payload).unwrap();
      if (res?.status) {
        onClose();
        onGet?.();
      }
      responseToaster(res);
      setaddCpIsLoading(false);
    } catch (error) {
      setaddCpIsLoading(false);
    }
  };

  useEffect(() => {
    setIsPer(watch("discount_type"));
    // eslint-disable-next-line
  }, [watch("discount_type")]);

  if (!isOpen) return null;

  const handleInputChange = (e: any) => {
    const inputValue: any = e.target.value;

    if (
      inputValue === "" ||
      inputValue === "%" ||
      (percentage.length > inputValue.length && inputValue.length === 1)
    ) {
      setPercentage("");
      setValue("coupon_amount", null);
    } else if (!inputValue.includes("%") && inputValue.length > 1) {
      const newValue: any = `${inputValue.slice(0, -1)}%`;
      setPercentage(newValue);
      setValue("coupon_amount", +inputValue.slice(0, -1));
    } else {
      const sanitizedValue: any = inputValue.replace("%", "");
      if ((+sanitizedValue || 0) >= 0) {
        const newValue =
          sanitizedValue + (sanitizedValue.includes("%") ? "" : "%");
        setPercentage(newValue);
        setValue("coupon_amount", +newValue.slice(0, -1));
      }
    }
  };

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center">
      <div
        className="modal-overlay absolute w-full h-full bg-gray-900 opacity-50"
        onClick={() => {
          if (!addCpisLoading) {
            onClose();
          }
        }}
      />

      <div className="modal-container bg-chatlook-white border w-8/12 2xl:w-6/12 xl:w-6/12 lg:w-8/12  md:w-9/12 sm:w-10/12  mx-auto rounded-lg shadow-lg z-50 overflow-y-auto">
        <div className="modal-content py-6 px-6 text-left  max-h-[84vh]">
          <Form onSubmit={handleSubmit(onSubmit)}>
            <div className="modal-header flex justify-between">
              <h4 className="modal-title mb-4 font-bold capitalize">
                {`${couponData?.id ? (!isAdmin ? "View" : "Update") : "Add"}`}{" "}
                Coupon
              </h4>
              {/*  {!isAdmin ? (
                <div onClick={() => onClose()} className="cursor-pointer">
                  <IoIosCloseCircle color="var(--ev-primary--)" size={22} />
                </div>
              ) : null} */}
            </div>
            <div className="modal-body">
              <div className="grid md:grid-cols-2 lg:grid-cols-2 2xl:grid-cols-2 xl:grid-cols-2 sm:grid-cols-1 items-start gap-3 pt-2 pb-3">
                <ControlledInput
                  name="code"
                  label="Code"
                  placeholder="Enter Code"
                  type="text"
                  autoFocus
                  errors={errors}
                  control={control}
                  disabled={!isAdmin}
                />
                <ControlledDropDown
                  name="discount_type"
                  label="Discount Type"
                  placeholder="Select discount"
                  options={prepareDescountrOptions}
                  control={control}
                  errors={errors}
                  isDisabled={!isAdmin}
                />
                <Input
                  placeholder={`Enter Coupon ${
                    watch("discount_type") === "percent"
                      ? "Percentage"
                      : "Amount"
                  } `}
                  className="!w-full"
                  type="text"
                  name="coupon_amount"
                  label={`Coupon ${
                    watch("discount_type") === "percent"
                      ? "Percentage"
                      : "Amount"
                  } `}
                  value={
                    watch("discount_type") === "percent"
                      ? percentage
                      : watch("coupon_amount") || ""
                  }
                  onChange={(e: any) => {
                    const discount_type = getValues()?.discount_type;
                    if (discount_type === "percent") {
                      if (
                        +e?.target?.value?.replace?.("%", "") > 100 ||
                        !/^\d+$/.test?.(e?.target?.value?.replace?.("%", ""))
                      ) {
                        return;
                      }
                      handleInputChange(e);
                      trigger("coupon_amount");
                    } else {
                      if (
                        !/^\d+$/.test?.(e?.target?.value?.replace?.("%", "")) &&
                        e?.target?.value !== ""
                      ) {
                        return;
                      }
                      setValue("coupon_amount", e.target.value);
                      trigger("coupon_amount");
                    }
                  }}
                  errors={errors}
                  disabled={!isAdmin}
                />
                {watch("discount_type") === "percent" ? (
                  <ControlledInput
                    placeholder={`Enter Maximum Coupon Discount`}
                    className="!w-full"
                    name="max_discount"
                    type="number"
                    label={`Maximum Coupon Discount`}
                    errors={errors}
                    control={control}
                    disabled={!isAdmin}
                  />
                ) : null}
                <ControlledDatePicker
                  inputClassName="!w-[100%]"
                  name="coupon_expiry_date"
                  label="Coupon Expiry date"
                  placeholder="Select Expiry Date"
                  minDate={new Date()}
                  control={control}
                  errors={errors}
                  disabled={!isAdmin}
                />
                <ControlledInput
                  name="minimum_spend"
                  label="Minimum Spend Amount"
                  placeholder="Enter Minimum Spend Amount"
                  type="number"
                  errors={errors}
                  control={control}
                  disabled={!isAdmin}
                />
                <ControlledInput
                  name="maximum_spend"
                  label="Maximum Spend Amount"
                  placeholder="Enter Maximum Spend Amount"
                  onChange={(e) => {
                    const value = e.target?.value;
                    setValue("maximum_spend", value);
                    trigger("maximum_spend");
                  }}
                  type="number"
                  errors={errors}
                  control={control}
                  disabled={!isAdmin}
                />
                <ControlledDropDown
                  name="product_categories"
                  label="Product Categories"
                  isMulti
                  closeMenuOnSelect={false}
                  placeholder="Select Product Categories"
                  options={prepareProductCategoryOptions()}
                  control={control}
                  errors={errors}
                  isDisabled={!isAdmin}
                  onChange={(e: any) => {
                    setValue("product_categories", e);
                    setValue("products", []);
                  }}
                />
                <ControlledDropDown
                  name="products"
                  label="Products"
                  closeMenuOnSelect={false}
                  isMulti
                  placeholder="Select Products"
                  isDefaultOption={DROPDOWN_ALL_VALUE}
                  options={productsOptions}
                  onChange={(e: any) => {
                    if (e?.includes(DROPDOWN_ALL_VALUE)) {
                      setValue(
                        "products",
                        productsOptions?.map((item: any) => item?.value)
                      );
                    } else {
                      setValue("products", e);
                    }
                  }}
                  control={control}
                  errors={errors}
                  isDisabled={!isAdmin}
                />

                <ControlledTextArea
                  name="description"
                  label="Description"
                  placeholder="Description..."
                  control={control}
                  hasPre={!isAdmin}
                  errors={errors}
                  className="textareacss"
                  disabled={!isAdmin}
                />
                {/* <ControlledInput
                  name="email_restrictions"
                  label="Email Restrictions"
                  placeholder="Enter Email Restrictions Id"
                  errors={errors}
                  control={control}
                /> */}
              </div>
            </div>

            <div className="modal-footer">
              <div className="flex items-center justify-end gap-2">
                {isAdmin ? (
                  <Button
                    type="submit"
                    className="mt-[10px] !h-9 !text-sm"
                    isLoading={addCpisLoading}
                    isLoader={addCpisLoading}
                  >
                    {couponData?.id ? "Update" : "Submit"}
                  </Button>
                ) : null}
                <Button
                  variant="Transaparent"
                  className="mt-[10px]  !text-sm !h-9"
                  disabled={addCpisLoading}
                  onClick={() => onClose()}
                >
                  Cancel
                </Button>
              </div>
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default CouponModal;
