import React from "react";
import ThreeDotsLoader from "../../../ui/elements/ThreeDotsLoader";
import confirmed from "../../../assets/images/confirmed.svg";
import pendingIcon from "../../../assets/images/pendingIcon.svg";
import declained from "../../../assets/images/declined.svg";

const images: any = {
  "Pending Order": pendingIcon,
  "Approved Order": confirmed,
  "Decline Order": declained,
};

const StatusCard = ({
  color,
  label,
  percentage,
  value,
  threeDotsLoader,
}: any) => {
  const radius = 18;
  const circumference = 2 * Math.PI * radius;
  const progress = (percentage / 100) * circumference;

  return (
    <div
      className={`flex justify-between items-center p-4 rounded-lg cardcss shadow-md ${color}`}
    >
      <div className="flex flex-col">
        <div className="flex items-center space-x-2">
          <span className="text-gray-700 font-medium">
            <img src={images?.[label]} alt={label} className="w-10 mr-2" />
          </span>
          <span className="text-chatlook-secondary font-semibold">{label}</span>
        </div>
        {threeDotsLoader ? (
          <ThreeDotsLoader className="mt-2" />
        ) : (
          <span className="text-ev-primary font-bold text-lg">{value}</span>
        )}
      </div>
      <div className="relative">
        <svg className="w-12 h-12">
          <circle
            className="text-gray-200"
            strokeWidth="4"
            stroke="currentColor"
            fill="transparent"
            r={radius}
            cx="24"
            cy="24"
          />
          <circle
            className="text-ev-primary"
            strokeWidth="4"
            strokeDasharray={circumference}
            strokeDashoffset={circumference - progress}
            stroke="currentColor"
            fill="transparent"
            r={radius}
            cx="24"
            cy="24"
            transform="rotate(-90 24 24)"
          />
        </svg>
        {threeDotsLoader ? (
          ""
        ) : (
          <span className="absolute inset-0 flex items-center justify-center text-gray-700 font-bold text-[12px]">{`${percentage}%`}</span>
        )}
      </div>
    </div>
  );
};

export default StatusCard;
