import { api } from "../../../utills/api";

export const salesReportApi = api.injectEndpoints({
  endpoints: (build) => ({
    getSalesReport: build.mutation<{ data: any }, void>({
      query: (params) => ({
        url: "/admin/report/get-sales-product-report",
        body: params,
        method: "POST",
      }),
    }),
  }),
});

export const { useGetSalesReportMutation } = salesReportApi;
export const {
  endpoints: { getSalesReport },
} = salesReportApi;
