export const statusOptionsForOrder = [
  {
    value: "new",
    label: "New",
  },
  {
    value: "processing",
    label: "Processing",
  },
  {
    value: "delivered",
    label: "Delivered",
  },
  {
    value: "out of delivery",
    label: "Out of delivery",
  },
  {
    value: "cancel",
    label: "Cancel",
  },
  {
    value: "assign to delivery",
    label: "Assign to delivery",
  }
];
