import React, { createRef, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Form } from "reactstrap";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Button, ControlledDropDown, ControlledInput } from "../../../ui";
import {
  useAddTagMutation,
  useGetTagByIdMutation,
  useUpdateTagMutation,
} from "./tagApi";
import {
  prepareVendorOptions,
  responseToaster,
  showToaster,
} from "../../../helperFunctions";
import { IoMdAdd, IoIosCloseCircle } from "react-icons/io";
import { useTagList } from "./tagSlice";
import { useVendorId } from "../../auth/authSlice";

const TagModal = (props: any) => {
  const { onHide, isOpen, onGet, tagData, currentTab } = props;
  const [addCpisLoading, setaddCpIsLoading] = useState<boolean>(false);
  const [isAdmin, setIsAdmin] = useState<any>(true);
  const [chipValues, setChipValues] = useState<string[]>([]);
  const [addTag] = useAddTagMutation();
  const [updateTag] = useUpdateTagMutation();
  const [getTagById] = useGetTagByIdMutation();
  const tagList = useTagList();
  const inputRef: any = createRef();
  const hasVendorId = useVendorId();

  const schema: any = yup.object({
    tag_name: yup.string().required("Please enter a tag name"),
    vendor_id: yup.string().required("Please select vendor"),
    tag:
      chipValues.length === 0
        ? yup.string().required("Please add at least one tag value")
        : yup.string().nullable(),
  });
  const {
    control,
    handleSubmit,
    reset,
    setError,
    clearErrors,
    setValue,
    watch,
    formState: { errors },
  }: any = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      vendor_id: hasVendorId?.id,
    },
  });

  const onClose = () => {
    reset();
    onHide();
    setIsAdmin(currentTab === "Admin");
    setChipValues([]);
  };

  const getTag = async () => {
    try {
      const payload: any = {
        id: tagData?.id,
      };
      const res: any = await getTagById(payload);
      if (res?.data?.status) {
        setFormData(res?.data?.data);
        setIsAdmin(res?.data?.data?.is_admin === 1);
      }
    } catch (error) {}
  };

  const setFormData = (tagData: any) => {
    if (tagData?.id) {
      setChipValues(tagData?.sub_tags);
      setValue("tag_name", tagData?.tag_name);
      setValue("vendor_id", tagData?.vendor_id);
    }
  };

  useEffect(() => {
    if (isOpen) {
      if (tagData?.id) {
        getTag();
      }
    }
    // eslint-disable-next-line
  }, [isOpen, tagData?.id]);

  const onSubmit = async (values: any) => {
    try {
      if (!chipValues?.length) {
        setError("tag", {
          type: "manual",
          message: "Please press the '+' button to add a tag.",
        });
        return;
      }
      const { tag, ...rest } = values;
      setaddCpIsLoading(true);
      const payload = {
        ...rest,
        sub_tags: chipValues,
      };
      const res: any = tagData?.id
        ? await updateTag({ id: tagData?.id, ...payload }).unwrap()
        : await addTag(payload).unwrap();
      if (res?.status && !res?.warning) {
        onClose();
        onGet?.();
      }
      responseToaster(res);
      setaddCpIsLoading(false);
    } catch (error) {
      setaddCpIsLoading(false);
    }
  };

  const handleChipAdd = () => {
    const value = watch("tag");

    if (value && value.trim() !== "" && chipValues.includes(value.trim())) {
      showToaster("Already exist", "Error");
      return;
    }
    if (value && value.trim() !== "" && !chipValues.includes(value.trim())) {
      setChipValues([...chipValues, value.trim()]);
      setValue("tag", "");
    }

    clearErrors("tag");
    inputRef.current?.focus();
  };

  const handleChipRemove = (index: number) => {
    const updatedChips = chipValues.filter((_, i) => i !== index);
    setChipValues(updatedChips);
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center">
      <div
        className="modal-overlay absolute w-full h-full bg-gray-900 opacity-50"
        onClick={() => {
          if (!addCpisLoading) {
            onClose();
          }
        }}
      />

      <div className="modal-container bg-chatlook-white border w-8/12 2xl:w-6/12 xl:w-6/12 lg:w-8/12  md:w-9/12 sm:w-10/12  mx-auto rounded-lg shadow-lg z-50 overflow-y-auto">
        <div className="modal-content p-6 text-left  max-h-[84vh]">
          <Form onSubmit={handleSubmit(onSubmit)}>
            <div className="modal-header flex justify-between">
              <h4 className="modal-title mb-4 font-bold capitalize">
                {`${
                  tagData?.id
                    ? currentTab === "Vendor"
                      ? "View"
                      : "Update"
                    : "Add"
                }`}{" "}
                Tag
              </h4>
              {/*  {!isAdmin ? (
                <div onClick={() => onClose()} className="cursor-pointer">
                  <IoIosCloseCircle color="var(--ev-primary--)" size={22} />
                </div>
              ) : null} */}
            </div>
            <div className="modal-body">
              <div className="grid md:grid-cols-2 lg:grid-cols-2 2xl:grid-cols-2 xl:grid-cols-2 sm:grid-cols-1 items-start gap-3 pt-2 pb-3">
                <ControlledInput
                  name="tag_name"
                  label="Name"
                  placeholder="Enter Tag Name"
                  type="text"
                  className="sm:col-span-2"
                  errors={errors}
                  control={control}
                  disabled={!isAdmin}
                />
                <ControlledDropDown
                  name="vendor_id"
                  label="Vendor"
                  className="sm:col-span-2"
                  placeholder="Select Vendor"
                  options={prepareVendorOptions(tagList?.vendorsList)}
                  control={control}
                  errors={errors}
                  isDisabled={!isAdmin}
                />

                {currentTab === "Admin" ? (
                  <div className="col-span-2 flex items-start gap-3">
                    <ControlledInput
                      name="tag"
                      refa={inputRef}
                      label="Tag"
                      placeholder="Enter Tag Value"
                      type="text"
                      errors={errors}
                      control={control}
                      disabled={!isAdmin}
                    />
                    <Button
                      onClick={handleChipAdd}
                      disabled={!isAdmin}
                      className="mt-5"
                    >
                      <IoMdAdd />
                    </Button>
                  </div>
                ) : null}
              </div>
              {chipValues?.length ? (
                <label className={`text-sm text-ev-secondary`}>Tags</label>
              ) : null}
              <div className="flex gap-2 items-center flex-wrap ">
                {chipValues?.map((value, index) => (
                  <span
                    key={index}
                    className="flex items-center gap-2 text-xs bg-[#00000014] px-2 py-1 rounded-full"
                  >
                    {value}
                    <button
                      type="button"
                      onClick={() => handleChipRemove(index)}
                      className="chip-remove"
                      disabled={!isAdmin}
                    >
                      <IoIosCloseCircle color="#00000042" />
                    </button>
                  </span>
                ))}
              </div>
            </div>

            <div className="modal-footer">
              <div className="flex items-center justify-end gap-2">
                {isAdmin ? (
                  <Button
                    type="submit"
                    className="mt-[10px] !h-9 !text-sm"
                    isLoading={addCpisLoading}
                    isLoader={addCpisLoading}
                  >
                    {tagData?.id ? "Update" : "Submit"}
                  </Button>
                ) : null}
                <Button
                  variant="Transaparent"
                  className="mt-[10px]  !text-sm !h-9"
                  disabled={addCpisLoading}
                  onClick={() => onClose()}
                >
                  Cancel
                </Button>
              </div>
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default TagModal;
