import { Fragment, useEffect, useState } from "react";
import { FILTER } from "../../constant";
import { Button, Icon, Input, Table, TableRow } from "../../ui";
import {
  setCurrTab,
  useCurrentTab,
  useSubscribeList,
  useSubscribeOtherCitiesList,
} from "./subscribeSlice";
import {
  useDeleteSubscribeMutation,
  useGetSubscribeMutation,
} from "./subscribeApi";
import { responseToaster } from "../../helperFunctions";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import CustomeBreadcrumbs from "../common/CustomeBreadcrumbs";
import Tooltip from "../../ui/elements/Tooltip";
import { MdDeleteOutline } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { useRole } from "../auth/authSlice";
import { Modal } from "../common";

const Subscribe = () => {
  const [getSubscribe, { isLoading }] = useGetSubscribeMutation();
  const subscribeList = useSubscribeList();
  const subscribeOtherCitiesList = useSubscribeOtherCitiesList();
  const [deleteSubscribe, { isLoading: isDeleteLoading }] =
    useDeleteSubscribeMutation();
  const currentTab = useCurrentTab();
  const dispatch = useDispatch();

  const [tableData, setTableData] = useState<any>([]);
  const [filter, setFilter] = useState<any>(FILTER);
  const [showDiv, setShowDiv] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [deleteData, setDeleteData] = useState<any>();
  const navigate = useNavigate();
  const role = useRole("subscribe");

  if (!role.includes("list")) {
    navigate("/");
  }
  const subscribeTab: any[] = [
    { label: "Subscribe", Component: Subscribe },
    { label: "Other Cities", Component: Subscribe },
  ];

  const breadcrumbs = [{ label: "Subscribe" }, { label: currentTab }];

  const columns = [
    {
      title: "Email",
      name: "email",
      sortable: true,
      sortableKey: "email",
      Cell: (data: any) => {
        return (
          <div className="flex gap-2 items-center">
            <div className="flex justify-center">
              <div className="flex items-center justify-center w-8 h-8 rounded-full bg-chatlook-Cyan text-white text-md font-bold uppercase">
                {data?.email?.charAt?.(0)}
              </div>
            </div>
            <span>{data?.email || "-"}</span>
          </div>
        );
      },
    },
    {
      title: "Date",
      name: "created_at_ist",
      sortable: true,
      sortableKey: "created_at_ist",
    },
    {
      title: "Action",
      name: "action",
      Cell: (item: any) => {
        return (
          <div className="flex items-center gap-2">
            <Tooltip text="Delete" className="right-0">
              <Button
                className="!h-8 !w-8 !p-0"
                onClick={() => {
                  setIsDeleteModalOpen(true);
                  setDeleteData(item);
                }}
                disabled={!role.includes("delete")}
              >
                <MdDeleteOutline />
              </Button>
            </Tooltip>
          </div>
        );
      },
    },
  ];

  const onSubscribeDelete = async () => {
    try {
      const payload: any = {
        id: deleteData?.id,
      };
      const res = await deleteSubscribe(payload).unwrap();
      if (res) {
        setIsDeleteModalOpen(false);
        setDeleteData(false);
        setFilter({ ...filter, isFilter: false });
      }
      responseToaster(res);
    } catch (err) {
      console.error("Error =-=>", err);
    }
  };

  const getSubscribeList = async () => {
    try {
      const { search, status, is_auto_approved, is_approved, ...rest } = filter;
      const payload: any = {
        ...rest,
        filter_data: {
          ...(currentTab === "Other Cities" ? { is_other_city: 1 } : null),
          search,
        },
      };
      delete payload["isFilter"];
      delete payload["isClear"];
      const res: any = await getSubscribe(payload);
      if (filter?.isFilter && !filter?.isClear) {
        responseToaster(res?.data);
      }
    } catch (error) {
      console.error("Error =-=>", error);
    }
  };

  const { register, handleSubmit, reset } = useForm<any>();

  useEffect(() => {
    if (currentTab === "Subscribe") {
      setTableData(subscribeList?.data?.length ? [...subscribeList?.data] : []);
    } else {
      setTableData(
        subscribeOtherCitiesList?.data?.length
        ? [...subscribeOtherCitiesList?.data]
          : []
      );
    }
  }, [subscribeList, subscribeOtherCitiesList, currentTab]);
  useEffect(() => {
    getSubscribeList();
    // eslint-disable-next-line
  }, [filter, currentTab]);

  const onSubmit = async (values: any) => {
    setFilter({ ...FILTER, ...values, isFilter: true });
  };

  return (
    <>
      <div className="flex mb-3 gap-2 justify-between sm:flex-wrap">
        <CustomeBreadcrumbs breadcrumbs={breadcrumbs} />

        {role.includes("list") ? (
          <Button
            classNm={`!text-ev-white !border-0 appl-btn1`}
            onClick={() => {
              setShowDiv(!showDiv);
            }}
            
          >
            <Icon name="FilterIcon" />
          </Button>
        ) : null}
      </div>
      {showDiv ? (
        <div className="cardcss mb-3 flex justify-between items-end">
          <form
            className="flex gap-2 items-end"
            onSubmit={handleSubmit(onSubmit)}
          >
            <Input
              name="search"
              label="Search By Email"
              placeholder="Search By Email"
              register={register}
            />

            <Button type="submit" className="!text-sm">
              Apply
            </Button>
            <Button
              variant="Transaparent"
              className="!text-sm"
              onClick={() => {
                reset();
                setFilter({ ...FILTER, isFilter: true, isClear: true });
              }}
            >
              Clear
            </Button>
          </form>
        </div>
      ) : null}
      {role.includes("list") ? (
        <div className="cardcss">
          <div className="flex mb-3 gap-4 justify-between border-b-2 p-1 ">
            <div className="flex gap-2 pb-1 sm:flex-wrap">
              {subscribeTab?.map((tab, index) => {
                return (
                  <div
                    key={index}
                    className={` px-6 h-9 flex items-center justify-center cursor-pointer tab-transition ${
                      currentTab !== tab?.label ? " " : "active"
                    }`}
                    onClick={() => {
                      setFilter({
                        ...FILTER,
                      });
                      reset();
                      dispatch(setCurrTab(tab?.label));
                    }}
                  >
                    {tab?.label}
                  </div>
                );
              })}
            </div>
          </div>
          <Table
            className="subscribeTable"
            columns={columns}
            isExpendable={false}
            isLoading={isLoading}
            data={tableData || []}
            setData={setTableData}
            count={
              currentTab === "Subscribe"
                ? subscribeList?.total_item
                : currentTab === "Other Cities"
                ? subscribeOtherCitiesList?.total_item
                : 0
            }
            pagination={filter}
            handlePaginationChange={(pagination) => {
              setFilter({
                ...filter,
                ...pagination,
                isFilter: false,
              });
            }}
          >
            {tableData?.map((item: any, index: number) => {
              return (
                <Fragment key={index}>
                  <TableRow
                    columns={columns}
                    isExpendable={false}
                    item={item}
                    onClick={(item: any) => {}}
                  ></TableRow>
                </Fragment>
              );
            })}
          </Table>
        </div>
      ) : null}
      <Modal
        isOpen={!!isDeleteModalOpen}
        isLoading={isDeleteLoading}
        doneText="Delete"
        content={
          <div className="flex flex-col gap-2">
            <p>Are you sure you want to delete this?</p>
          </div>
        }
        onDoneClick={onSubscribeDelete}
        onHide={() => {
          setIsDeleteModalOpen(false);
        }}
      />
    </>
  );
};

export default Subscribe;
