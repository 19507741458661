import React from "react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import ThreeDotsLoader from "../../../ui/elements/ThreeDotsLoader";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const ReportChart = ({ data, isLoading }: any) => {
  const chartData = {
    labels: data?.labels || [],
    datasets:
      data?.datasets?.map?.((dataset: any) => ({
        label: dataset?.label,
        data: dataset?.data,
        backgroundColor: dataset?.backgroundColor,
        borderColor: dataset?.backgroundColor,
        tension: 0.4,
        fill: true,
      })) || [],
  };
  const options: any = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: "top",
        labels: {
          font: {
            size: 14,
          },
          generateLabels: (chart: any) => {
            const labels =
              ChartJS.defaults.plugins.legend.labels.generateLabels(chart);
            return labels.map((label) => ({
              ...label,
              text: label.text.charAt(0).toUpperCase() + label.text.slice(1),
            }));
          },
        },
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: "Day of the Month",
          font: {
            size: 14,
          },
        },
        grid: {
          display: false,
        },
      },
      y: {
        title: {
          display: true,
          text: "Number of Orders",
          font: {
            size: 14,
          },
        },

        beginAtZero: true,
        suggestedMax: 50,
      },
    },
  };

  return (
    <div className="h-96">
      {isLoading ? (
        <ThreeDotsLoader />
      ) : (
        <Line data={chartData} options={options} />
      )}
    </div>
  );
};

export default ReportChart;
