import React from "react";
import { prepareImageUrl, simpleEncrypt } from "../../helperFunctions";
import dummyImage from "../../assets/images/dummy-image.svg";
import { Link } from "react-router-dom";
import ThreeDotsLoader from "../../ui/elements/ThreeDotsLoader";

const TopCustomers = ({ topCustomer, summaryLoading }: any) => {
  return (
    <div className="">
      <div className="flex justify-between items-center pb-4">
        <h2 className="text-lg font-semibold text-gray-800">Top Customers</h2>
        <Link
          to={`/user/get-user?keyId=${simpleEncrypt(
            "bestSelling",
            "bsproduct"
          )}`}
          className="text-ev-primary text-sm"
        >
          View All
        </Link>
      </div>

      <div className="space-y-4 h-[500px] overflow-y-scroll hidden_scroll">
        {summaryLoading ? (
          <ThreeDotsLoader />
        ) : topCustomer?.length ? (
          topCustomer?.map?.((customer: any, index: number) => (
            <div
              key={index}
              className="flex items-center justify-between p-3 bg-gray-50 rounded-lg shadow-sm"
            >
              <div className="flex items-center">
                <div className="h-12 w-12 rounded-lg overflow-hidden bg-gray-200 mr-4">
                  <img
                    src={
                      customer?.photo
                        ? prepareImageUrl(customer?.photo)?.url
                        : dummyImage
                    }
                    alt={customer.firstName}
                    className="h-full w-full object-cover"
                    onError={(e: any) => {
                      e.target.onerror = null;
                      e.target.src = dummyImage;
                      e.target.width = 40;
                      e.target.height = 40;
                    }}
                  />
                </div>
                <p className="text-gray-700">
                  <p className="font-semibold">
                    {customer?.firstName || customer?.lastName
                      ? `${customer.firstName} ${customer?.lastName}`
                      : customer?.guest}
                  </p>
                  <p className="text-xs text-gray-500">{customer?.contact}</p>
                </p>
              </div>
              <div className="flex items-center space-x-2">
                <div className="bg-green-100 text-green-600 font-semibold px-3 py-1 rounded-md">
                  Sales: {customer?.total_sales}
                </div>
              </div>
            </div>
          ))
        ) : (
          "No Record Found"
        )}
      </div>
    </div>
  );
};

export default TopCustomers;
