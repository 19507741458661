import React, { memo } from "react";
import { IconProps } from ".";

export interface SalesReportsIconProps extends IconProps {}
const SalesReports = (props: SalesReportsIconProps) => {
  const {
    className = "",
    pathClassNames = {},
    size = 20,
    stroke = "currentColor",
    fill = "none",
    strokeWidth = 1,
    ...rest
  } = props;
  return (
    <svg
      className={`${className}`}
      width={size}
      height={size}
      viewBox="0 0 48 48"
      fill={fill}
      stroke={stroke}
      strokeWidth={strokeWidth}
      {...rest}
    >
      <g fill="none" stroke={stroke} strokeLinejoin="round" strokeWidth="4">
        <path
          d="M41 14L24 4L7 14v20l17 10l17-10z"
          className={`${pathClassNames?.[0] || ""}`}
        />

        <path
          stroke-linecap="round"
          d="M24 22v8m8-12v12m-16-4v4"
          className={`${pathClassNames?.[1] || ""}`}
        />
      </g>
    </svg>
  );
};

const MemoSalesReportsIcon = memo(SalesReports);
export default MemoSalesReportsIcon;
