import React, { Fragment, useEffect, useState } from "react";
import { FILTER } from "../../../constant";
import {
  useGetSubscriptionsMutation,
  useUpdateSubscriptionsMutation,
} from "./subscriptionsApi";
import { setSubscriptions, useSubscriptionsList } from "./subscriptionsSlice";
import { Table, TableRow } from "../../../ui";
import Switch from "../../../ui/forms/Switch";
import { useDispatch } from "react-redux";
import { responseToaster } from "../../../helperFunctions";
import SubscriptionUpdate from "./SubscriptionUpdate";
import UpdateDD from "./UpdateDD";
import CustomeBreadcrumbs from "../../common/CustomeBreadcrumbs";
import { useNavigate } from "react-router-dom";
import { useRole } from "../../auth/authSlice";

const breadcrumbs = [{ label: "Subscriptions" }, { label: "Plan" }];

const Plan = () => {
  const [getSubscriptions, { isLoading }] = useGetSubscriptionsMutation();
  // eslint-disable-next-line
  const [filter, setFilter] = useState(FILTER);
  const [updateSubscriptions] = useUpdateSubscriptionsMutation();

  const [tableData, setTableData] = useState<any>([]);
  const [statusLoading, setStatusLoading] = useState(false);

  const [currKey, setCurrKey] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const role = useRole("plan");

  if (!role.includes("list")) {
    navigate("/");
  }
  const subscriptionsList = useSubscriptionsList();

  const onUpdateStatus = async (payload: any, key: string) => {
    try {
      setStatusLoading(payload?.id);
      setCurrKey(key);
      const res = await updateSubscriptions(payload).unwrap();

      const index = tableData?.findIndex(
        (item: { id: any }) => item?.id === payload?.id
      );
      if (index > -1) {
        const newData = [...tableData];
        newData[index] = {
          ...newData[index],
          [key]: payload?.[key],
        };
        dispatch(setSubscriptions({ ...subscriptionsList, data: newData }));
      }
      responseToaster(res);
      setStatusLoading(false);
      setCurrKey("");
    } catch (error) {
      setCurrKey("");
      setStatusLoading(false);
    }
  };

  const columns = [
    /* {
      title: "Package",
      name: "package",
      sortable: true,
      sortableKey: "package",
    }, */
    {
      title: "Plan",
      name: "plan",
      sortable: true,
      sortableKey: "plan",
      Cell: (data: any) => {
        return <span className="capitalize">{data?.plan}</span>
      }
    },
    {
      title: "Digital Store",
      name: "digital_store",
      sortable: true,
      sortableKey: "digital_store",
      Cell: (data: any) => (
        <Switch
          checked={data?.digital_store === 1}
          isLoading={statusLoading === data?.id && currKey === "digital_store"}
          isDisabled={!role.includes("edit") || statusLoading}
          onChange={async (value: any, e: any) => {
            e.stopPropagation();
            try {
              const payload = {
                digital_store: value ? 1 : 0,
                id: data?.id,
              };
              await onUpdateStatus(payload, "digital_store");
            } catch (err) {}
          }}
        />
      ),
    },
    {
      title: "Store Link QR Code",
      name: "store_link_qr_code",
      sortable: true,
      sortableKey: "store_link_qr_code",
      Cell: (data: any) => (
        <Switch
          checked={data?.store_link_qr_code === 1}
          isLoading={
            statusLoading === data?.id && currKey === "store_link_qr_code"
          }
          isDisabled={!role.includes("edit") || statusLoading}
          onChange={async (value: any, e: any) => {
            e.stopPropagation();
            try {
              const payload = {
                store_link_qr_code: value ? 1 : 0,
                id: data?.id,
              };
              await onUpdateStatus(payload, "store_link_qr_code");
            } catch (err) {}
          }}
        />
      ),
    },
    {
      title: "Social Media Integration",
      name: "social_media_integration",
      sortable: true,
      sortableKey: "social_media_integration",
      Cell: (data: any) => (
        <Switch
          checked={data?.social_media_integration === 1}
          isDisabled={!role.includes("edit") || statusLoading}
          isLoading={
            statusLoading === data?.id && currKey === "social_media_integration"
          }
          onChange={async (value: any, e: any) => {
            e.stopPropagation();
            try {
              const payload = {
                social_media_integration: value ? 1 : 0,
                id: data?.id,
              };
              await onUpdateStatus(payload, "social_media_integration");
            } catch (err) {}
          }}
        />
      ),
    },
    {
      title: "Coupon",
      name: "coupon",
      sortable: true,
      sortableKey: "coupon",
      Cell: (data: any) => (
        <Switch
          checked={data?.coupon === 1}
          isLoading={statusLoading === data?.id && currKey === "coupon"}
          isDisabled={!role.includes("edit") || statusLoading}
          onChange={async (value: any, e: any) => {
            e.stopPropagation();
            try {
              const payload = {
                coupon: value ? 1 : 0,
                id: data?.id,
              };
              await onUpdateStatus(payload, "coupon");
            } catch (err) {}
          }}
        />
      ),
    },
    {
      title: "Instore Billing",
      name: "instore_billing",
      sortable: true,
      sortableKey: "instore_billing",
      Cell: (data: any) => (
        <Switch
          checked={data?.instore_billing === 1}
          isDisabled={!role.includes("edit") || statusLoading}
          isLoading={
            statusLoading === data?.id && currKey === "instore_billing"
          }
          onChange={async (value: any, e: any) => {
            e.stopPropagation();
            try {
              const payload = {
                instore_billing: value ? 1 : 0,
                id: data?.id,
              };
              await onUpdateStatus(payload, "instore_billing");
            } catch (err) {}
          }}
        />
      ),
    },
    {
      title: "Customer Vendor Chat",
      name: "customer_vendor_chat",
      sortable: true,
      sortableKey: "customer_vendor_chat",
      Cell: (data: any) => (
        <Switch
          checked={data?.customer_vendor_chat === 1}
          isLoading={
            statusLoading === data?.id && currKey === "customer_vendor_chat"
          }
          isDisabled={!role.includes("edit") || statusLoading}
          onChange={async (value: any, e: any) => {
            e.stopPropagation();
            try {
              const payload = {
                customer_vendor_chat: value ? 1 : 0,
                id: data?.id,
              };
              await onUpdateStatus(payload, "customer_vendor_chat");
            } catch (err) {}
          }}
        />
      ),
    },
    {
      title: "Customer List",
      name: "customer_list",
      sortable: true,
      sortableKey: "customer_list",
      Cell: (data: any) => (
        <Switch
          checked={data?.customer_list === 1}
          isLoading={statusLoading === data?.id && currKey === "customer_list"}
          isDisabled={!role.includes("edit") || statusLoading}
          onChange={async (value: any, e: any) => {
            e.stopPropagation();
            try {
              const payload = {
                customer_list: value ? 1 : 0,
                id: data?.id,
              };
              await onUpdateStatus(payload, "customer_list");
            } catch (err) {}
          }}
        />
      ),
    },
    {
      title: "Quick Order",
      name: "quick_order",
      sortable: true,
      sortableKey: "quick_order",
      Cell: (data: any) => (
        <Switch
          checked={data?.quick_order === 1}
          isDisabled={!role.includes("edit") || statusLoading}
          isLoading={statusLoading === data?.id && currKey === "quick_order"}
          onChange={async (value: any, e: any) => {
            e.stopPropagation();
            try {
              const payload = {
                quick_order: value ? 1 : 0,
                id: data?.id,
              };
              await onUpdateStatus(payload, "quick_order");
            } catch (err) {}
          }}
        />
      ),
    },
    {
      title: "Direct UPI Payment",
      name: "direct_upi_payment",
      sortable: true,
      sortableKey: "direct_upi_payment",
      Cell: (data: any) => (
        <Switch
          checked={data?.direct_upi_payment === 1}
          isLoading={
            statusLoading === data?.id && currKey === "direct_upi_payment"
          }
          isDisabled={!role.includes("edit") || statusLoading}
          onChange={async (value: any, e: any) => {
            e.stopPropagation();
            try {
              const payload = {
                direct_upi_payment: value ? 1 : 0,
                id: data?.id,
              };
              await onUpdateStatus(payload, "direct_upi_payment");
            } catch (err) {}
          }}
        />
      ),
    },
    {
      title: "Commission",
      name: "commission",
      sortable: true,
      sortableKey: "commission",
      Cell: (data: any) => {
        return (
          <>
            <SubscriptionUpdate
              currKey={"commission"}
              tableData={tableData}
              data={data}
              isDisable={role.includes("edit")}
              unlimited={false}
              isDecimal={true}
            />
          </>
        );
      },
    },
    {
      title: "Product Limit",
      name: "product_limit",
      sortable: true,
      sortableKey: "product_limit",
      Cell: (data: any) => {
        return (
          <>
            <SubscriptionUpdate
              currKey={"product_limit"}
              tableData={tableData}
              data={data}
              isDisable={role.includes("edit")}
            />
          </>
        );
      },
    },
    {
      title: "Stock Management",
      name: "stock_management",
      sortable: true,
      sortableKey: "stock_management",
      Cell: (data: any) => (
        <UpdateDD
          currKey={"stock_management"}
          tableData={tableData}
          data={data}
          isDisable={role.includes("edit")}
          statusOptions={[
            {
              value: "Basic",
              label: "Basic",
            },
            {
              value: "Advanced",
              label: "Advanced",
            },
          ]}
        />
      ),
    },
    {
      title: "Store Analytics",
      name: "store_analytics",
      sortable: true,
      sortableKey: "store_analytics",
      Cell: (data: any) => (
        <UpdateDD
          currKey={"store_analytics"}
          tableData={tableData}
          data={data}
          isDisable={role.includes("edit")}
          statusOptions={[
            {
              value: "Basic",
              label: "Basic",
            },
            {
              value: "Advanced",
              label: "Advanced",
            },
          ]}
        />
      ),
    },
    {
      title: "Product Catalogue",
      name: "product_catalogue",
      sortable: true,
      sortableKey: "product_catalogue",
      Cell: (data: any) => (
        <Switch
          checked={data?.product_catalogue === 1}
          isDisabled={!role.includes("edit") || statusLoading}
          isLoading={
            statusLoading === data?.id && currKey === "product_catalogue"
          }
          onChange={async (value: any, e: any) => {
            e.stopPropagation();
            try {
              const payload = {
                product_catalogue: value ? 1 : 0,
                id: data?.id,
              };
              await onUpdateStatus(payload, "product_catalogue");
            } catch (err) {}
          }}
        />
      ),
    },
    {
      title: "Barcode Integration",
      name: "barcode_integration",
      sortable: true,
      sortableKey: "barcode_integration",
      Cell: (data: any) => (
        <Switch
          checked={data?.barcode_integration === 1}
          isLoading={
            statusLoading === data?.id && currKey === "barcode_integration"
          }
          isDisabled={!role.includes("edit") || statusLoading}
          onChange={async (value: any, e: any) => {
            e.stopPropagation();
            try {
              const payload = {
                barcode_integration: value ? 1 : 0,
                id: data?.id,
              };
              await onUpdateStatus(payload, "barcode_integration");
            } catch (err) {}
          }}
        />
      ),
    },
    {
      title: "Delivery App",
      name: "delivery_app",
      sortable: true,
      sortableKey: "delivery_app",
      Cell: (data: any) => (
        <Switch
          checked={data?.delivery_app === 1}
          isLoading={statusLoading === data?.id && currKey === "delivery_app"}
          isDisabled={!role.includes("edit") || statusLoading}
          onChange={async (value: any, e: any) => {
            e.stopPropagation();
            try {
              const payload = {
                delivery_app: value ? 1 : 0,
                id: data?.id,
              };
              await onUpdateStatus(payload, "delivery_app");
            } catch (err) {}
          }}
        />
      ),
    },
    {
      title: "Supplier List",
      name: "supplier_list",
      sortable: true,
      sortableKey: "supplier_list",
      Cell: (data: any) => (
        <Switch
          checked={data?.supplier_list === 1}
          isLoading={statusLoading === data?.id && currKey === "supplier_list"}
          isDisabled={!role.includes("edit") || statusLoading}
          onChange={async (value: any, e: any) => {
            e.stopPropagation();
            try {
              const payload = {
                supplier_list: value ? 1 : 0,
                id: data?.id,
              };
              await onUpdateStatus(payload, "supplier_list");
            } catch (err) {}
          }}
        />
      ),
    },
    {
      title: "Is Coming Soon",
      name: "is_coming_soon",
      sortable: true,
      sortableKey: "is_coming_soon",
      Cell: (data: any) => (
        <Switch
          checked={data?.is_coming_soon === 1}
          isDisabled={!role.includes("edit") || statusLoading}
          isLoading={statusLoading === data?.id && currKey === "is_coming_soon"}
          onChange={async (value: any, e: any) => {
            e.stopPropagation();
            try {
              const payload = {
                is_coming_soon: value ? 1 : 0,
                id: data?.id,
              };
              await onUpdateStatus(payload, "is_coming_soon");
            } catch (err) {}
          }}
        />
      ),
    },
  
   
   
  
 
   
 
    
  ];

  const getSubscriptionsList = async () => {
    try {
      await getSubscriptions();
    } catch (error) {
      console.error("Error =-=>", error);
    }
  };

  useEffect(() => {
    setTableData(
      subscriptionsList?.data?.length ? [...subscriptionsList?.data] : []
    );
  }, [subscriptionsList]);

  useEffect(() => {
    if (role.includes("list")) {
      getSubscriptionsList();
    }
    // eslint-disable-next-line
  }, [filter]);

  return (
    <>
      <CustomeBreadcrumbs breadcrumbs={breadcrumbs} />
      {role.includes("list") ? (
        <div className="cardcss">
          <Table
            containerClass="planTable"
            columns={columns}
            isExpendable={false}
            isLoading={isLoading}
            data={tableData || []}
            setData={setTableData}
          >
            {tableData?.map((item: any, index: number) => {
              return (
                <Fragment key={index}>
                  <TableRow
                    columns={columns}
                    isExpendable={false}
                    item={item}
                  ></TableRow>
                </Fragment>
              );
            })}
          </Table>
        </div>
      ) : null}
    </>
  );
};

export default Plan;
