import React from "react";

const ThreeDotsLoader = ({ className }: any) => {
  return (
    <div className={`loading-dots ${className}`}>
      <div className="dot"></div>
      <div className="dot"></div>
      <div className="dot"></div>
    </div>
  );
};

export default ThreeDotsLoader;
