import React, { useState } from "react";
import OTPInput from "../../ui/forms/OTPInput";
import { CiLock } from "react-icons/ci";
import { Button } from "../../ui";
import { useAuthOtpCheckMutation } from "./authApi";
import { useAppDispatch } from "../../utills/hooks";
import { verifyingUser } from "../../helperFunctions";
import { setAuthToken } from "./authSlice";

const AuthVerifyOtp = ({ isOpen, onHide }: any) => {
  const [otp, setOtp] = useState("");
  const [isValid, setIsValid] = useState(true);
  const [triggerVibrate, setTriggerVibrate] = useState(false);
  const [authOtpCheck, { isLoading }] = useAuthOtpCheckMutation();
  const dispatch = useAppDispatch();

  const handleOtpChange = (newOtp: string) => {
    setOtp(newOtp);
    if (newOtp.length === 6) {
      setIsValid(true);
      setTriggerVibrate(false);
    }
  };

  if (!isOpen) return null;

  const onSubmit = async (e: any) => {
    e.preventDefault();
    if (otp.length !== 6) {
      setIsValid(false);
      setTriggerVibrate(true);
      return;
    }

    try {
      const payload: any = {
        one_time_password: otp,
      };

      const res: any = await authOtpCheck(payload).unwrap();
      if (res?.status) {
        dispatch(setAuthToken(res?.token));
        verifyingUser(dispatch, false);
        window.location.reload();
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center">
      <div className="modal-overlay absolute w-full h-full bg-gray-900 opacity-60" />
      <div className="modal-container bg-chatlook-white border w-8/12 2xl:w-4/12 xl:w-4/12 lg:w-5/12 md:w-6/12 sm:w-10/12 mx-auto rounded-lg shadow-lg z-50 overflow-y-auto p-4">
        <div className="modal-header flex items-center justify-center">
          <h4 className="modal-title mb-4 font-bold capitalize flex gap-2">
            <CiLock size={24} />
            Authenticate your account
          </h4>
        </div>
        <form onSubmit={onSubmit}>
          <div className="modal-body">
            <div className="flex flex-col items-center">
              <OTPInput
                numInputs={6}
                onOtpChange={handleOtpChange}
                isValid={isValid}
                triggerVibrate={triggerVibrate}
              />
            </div>
          </div>
          <div className="modal-footer">
            <div className="flex items-center w-full gap-2 mt-2">
              <Button
                type="submit"
                className="mt-[10px] w-full !text-sm !h-9"
                isLoader={isLoading}
                isLoading={isLoading}
              >
                Submit
              </Button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AuthVerifyOtp;
