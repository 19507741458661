import React, { Fragment, useEffect, useState } from "react";
import { FILTER } from "../../constant";
import { Table, TableRow } from "../../ui";
import { useTransactionList } from "./settlementSlice";
import { useGetTransationMutation } from "./settlementApi";
import { seperator } from "../../helperFunctions";
import { useNavigate } from "react-router-dom";
import { useRole } from "../auth/authSlice";

const TransactionList = () => {
  const [getTransation, { isLoading }] = useGetTransationMutation();
  const transactionList = useTransactionList();
  const [tableData, setTableData] = useState<any>([]);
  const [filter, setFilter] = useState(FILTER);
  const navigate = useNavigate();
  const settlementRole = useRole("settlement");
  const vendorDetailsRole = useRole("vendor-details");

  const role = [...settlementRole, ...vendorDetailsRole];

  if (!role.includes("list")) {
    navigate("/");
  }

  const columns = [
    {
      title: "Settlement Id",
      name: "id",
      sortable: true,
      sortableKey: "id",
    },
    {
      title: "Vendor",
      name: "vendor_name",
      sortable: true,
      sortableKey: "vendor.name",
      Cell: (item: any) => {
        return <span>{item?.vendor?.name || "-"}</span>;
      },
    },
    {
      title: "Available Balance",
      name: "available_balance",
      sortable: true,
      sortableKey: "available_balance",
      Cell: (item: any) => {
        return <span>{seperator(item?.available_balance)}</span>;
      },
    },
    {
      title: "Settlement Amount",
      name: "settlement_amount",
      sortable: true,
      sortableKey: "settlement_amount",
      Cell: (item: any) => {
        return <span>{seperator(item?.settlement_amount)}</span>;
      },
    },
    {
      title: "UnSettlement Amount",
      name: "unsettlement_balance",
      sortable: true,
      sortableKey: "unsettlement_balance",
      Cell: (item: any) => {
        return <span>{seperator(item?.unsettlement_balance)}</span>;
      },
    },
    {
      title: "Ref Id",
      name: "ref_id",
      sortable: true,
      sortableKey: "ref_id",
    },
  ];

  const getTransactionList = async () => {
    try {
      const payload: any = {
        ...filter,
      };
      delete payload["isFilter"];
      await getTransation(payload);
    } catch (error) {
      console.error("Error =-=>", error);
    }
  };

  useEffect(() => {
    setTableData(
      transactionList?.data?.length ? [...transactionList?.data] : []
    );
  }, [transactionList]);

  useEffect(() => {
    if(role.includes("list")){
      getTransactionList();
    }
    // eslint-disable-next-line
  }, [filter]);

  return (
    <Table
      columns={columns}
      isExpendable={false}
      isLoading={isLoading}
      data={tableData || []}
      setData={setTableData}
      count={transactionList?.total_item || 0}
      pagination={filter}
      handlePaginationChange={(pagination) => {
        setFilter({
          ...filter,
          ...pagination,
          isFilter: false,
        });
      }}
    >
      {tableData?.map((item: any, index: number) => {
        return (
          <Fragment key={index}>
            <TableRow
              columns={columns}
              isExpendable={false}
              item={item}
            ></TableRow>
          </Fragment>
        );
      })}
    </Table>
  );
};

export default TransactionList;
