import { useForm } from "react-hook-form";
import { Form } from "reactstrap";
import { Button, ControlledInput, Loader } from "../../../ui";
import { useEffect, useState } from "react";
import { responseToaster } from "../../../helperFunctions";
import { useRole, useUser } from "../../auth/authSlice";
import ControlledSwitch from "../../../ui/forms/ControlledSwitch";
import {
  useGetSettingDetailsMutation,
  useUpdateSettingMutation,
} from "../../dashboard/dashboardApi";

const Settings = () => {
  const [dcLoading, setDcLoading] = useState(false);
  const [getSettingDetails] = useGetSettingDetailsMutation();
  const [isLoading, setIsLoading] = useState(false);
  const [updateSettingDetails] = useUpdateSettingMutation();
  const user = useUser();
  const role = useRole("settings");

  const {
    handleSubmit,
    setValue,
    control,
    formState: { errors },
  }: any = useForm({
    defaultValues: {
      is_under_maintenance: 0,
    },
    // resolver: yupResolver(schema),
  });

  const getSettings = async () => {
    try {
      setIsLoading(true);
      const res: any = await getSettingDetails().unwrap();
      if (res) {
        setValue("customer_domain", res?.data?.customer_domain);
        setValue("web_domain", res?.data?.web_domain);
        setValue("razorpay_secret", res?.data?.razorpay_secret);
        setValue("razorpay_key", res?.data?.razorpay_key);
        setValue("is_under_maintenance", res?.data?.is_under_maintenance);
        setValue("google_map_api_key", res?.data?.google_map_api_key);
        setValue("domain_key", res?.data?.domain_key);
        setValue("domain_secret", res?.data?.domain_secret);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log("error", error);
    }
  };

  useEffect(() => {
    getSettings();
    // eslint-disable-next-line
  }, []);

  const onSubmit = async (values: any) => {
    try {
      setDcLoading(true);
      const payload: any = {
        customer_domain: values?.customer_domain,
        web_domain: values?.web_domain,
        is_under_maintenance: values?.is_under_maintenance,
        razorpay_key: values?.razorpay_key,
        razorpay_secret: values?.razorpay_secret,
        google_map_api_key: values?.google_map_api_key,
        domain_key: values?.domain_key,
        domain_secret: values?.domain_secret,
      };
      const res: any = await updateSettingDetails(payload).unwrap();
      setDcLoading(false);
      responseToaster(res);
    } catch (error) {
      setDcLoading(false);
      console.log("error", error);
    }
  };

  return (
    <div className="cardcss">
      {isLoading ? (
        <div className="h-[25vh] z-[9999] flex items-center justify-center">
          <Loader />
        </div>
      ) : (
        <Form onSubmit={handleSubmit(onSubmit)}>
          <div className="grid md:grid-cols-2 lg:grid-cols-2 2xl:grid-cols-2 xl:grid-cols-2 sm:grid-cols-1 items-start gap-3 pt-2 pb-3">
            <ControlledInput
              name="customer_domain"
              label="Customer Domain"
              className="grid-cols-2"
              placeholder="Enter Customer Domain name"
              type="text"
              disabled={!role.includes("edit")}
              control={control}
              errors={errors}
            />
            <ControlledInput
              name="web_domain"
              label="Web Domain"
              className="grid-cols-2"
              placeholder="Enter Web Domain name"
              type="text"
              disabled={!role.includes("edit")}
              control={control}
              errors={errors}
            />
            <ControlledInput
              name="razorpay_secret"
              label="Razorpay Secret"
              className="grid-cols-2"
              placeholder="Enter Razorpay Secret"
              type="text"
              disabled={!role.includes("edit")}
              control={control}
              errors={errors}
            />
            <ControlledInput
              name="razorpay_key"
              label="Razorpay Key"
              className="grid-cols-2"
              placeholder="Enter Razorpay Key"
              type="text"
              disabled={!role.includes("edit")}
              control={control}
              errors={errors}
            />
            <ControlledInput
              name="google_map_api_key"
              label="Google Map API Key"
              className="grid-cols-2"
              placeholder="Enter Google Map API Key"
              type="text"
              disabled={!role.includes("edit")}
              control={control}
              errors={errors}
            />
            <ControlledInput
              name="domain_key"
              label="Domain Key"
              className="grid-cols-2"
              placeholder="Enter Domain Key"
              type="text"
              disabled={!role.includes("edit")}
              control={control}
              errors={errors}
            />
            <ControlledInput
              name="domain_secret"
              label="Domain Secret"
              className="grid-cols-2"
              placeholder="Enter Domain Secret"
              type="text"
              disabled={!role.includes("edit")}
              control={control}
              errors={errors}
            />
            {user?.data?.admin?.is_admin ? (
              <ControlledSwitch
                control={control}
                className="mt-2"
                name="is_under_maintenance"
                label="Under Maintenance"
                isDisabled={!role.includes("edit")}
              />
            ) : null}
          </div>
          <div className="flex items-center justify-end gap-2">
            {role.includes("edit") ? (
              <Button
                type="submit"
                className="mt-[10px] !h-9 !text-sm"
                isLoading={dcLoading}
                isLoader={dcLoading}
              >
                Update
              </Button>
            ) : null}
          </div>
        </Form>
      )}
    </div>
  );
};

export default Settings;
