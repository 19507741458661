import React, { Fragment, useEffect, useState } from "react";
import { FILTER, statusOptions, statusOptions1 } from "../../constant";
import { Button, Icon, Select, Table, TableRow } from "../../ui";
import { renderStarss, responseToaster } from "../../helperFunctions";
import { useDispatch } from "react-redux";
import Switch from "../../ui/forms/Switch";
import { useForm } from "react-hook-form";

import { useRole } from "../auth/authSlice";
import { useNavigate } from "react-router-dom";
import {
  setCurrTab,
  setRatingReview,
  useCurrentTab,
  useRatingReviewList,
  useRatingReviewListShop,
} from "./ratingReviewSlice";
import {
  useDeleteRatingReviewMutation,
  useGetRatingReviewMutation,
  useUpdateRatingReviewMutation,
} from "./ratingReviewApi";
import ReadMoreLess from "../common/ReadMoreLess";
import { useMedia } from "../../components";
import { MdDeleteOutline } from "react-icons/md";
import Tooltip from "../../ui/elements/Tooltip";
import { Modal } from "../common";

interface RatingReviewTabs {
  label: string;
  Component?: any;
}

const RatingReview = () => {
  const [getRatingReview, { isLoading }] = useGetRatingReviewMutation();
  const [updateRatingReview] = useUpdateRatingReviewMutation();
  const ratingReviewList = useRatingReviewList();
  const ratingShop = useRatingReviewListShop();
  const [deleteRatingReview, { isLoading: isDeleteLoading }] =
    useDeleteRatingReviewMutation();
  const [tableData, setTableData] = useState<any>([]);
  const [filter, setFilter] = useState<any>(FILTER);
  const [statusLoading, setStatusLoading] = useState<any>(false);
  const [showDiv, setShowDiv] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [deleteData, setDeleteData] = useState<any>();
  const dispatch = useDispatch();
  const currentTab = useCurrentTab();
  const navigate = useNavigate();
  const { media } = useMedia();
  // const role = useRole("rating");

  const ratingRole = useRole("rating");
  const vendorDetailsRole = useRole("vendor-details");

  const role = [...ratingRole, ...vendorDetailsRole];

  const ratingReviewTab: RatingReviewTabs[] = [
    { label: "Product", Component: RatingReview },
    { label: "Shop", Component: RatingReview },
  ];

  const onUpdateStatus = async (payload: any, key: any) => {
    try {
      setStatusLoading({ id: payload?.id, keyId: key });
      const res = await updateRatingReview(payload).unwrap();

      const index = tableData?.findIndex(
        (item: { id: any }) => item?.id === payload?.id
      );
      if (index > -1) {
        const newData = [...tableData];
        newData[index] = {
          ...newData[index],
          ...(payload?.is_block !== null
            ? { is_block: payload?.is_block }
            : {}),
          ...(payload?.status !== null ? { status: payload?.status } : {}),
        };
        dispatch(
          setRatingReview({ ...ratingReviewList, data: newData, currentTab })
        );
      }
      responseToaster(res);
      setStatusLoading(false);
    } catch (err) {
      setStatusLoading(false);
    }
  };
  if (!role.includes("list")) {
    navigate("/");
  }
  const columns = [
    {
      title: "ID",
      name: "id",
      sortable: true,
      sortableKey: "id",
    },
    {
      title: "Rate",
      name: "rate",
      Cell: (data: any) => (
        <span className="flex">{renderStarss(data?.rate)}</span>
      ),
      sortable: true,
      sortableKey: "rate",
    },
    {
      title: "User Name",
      name: "user_name",
      sortable: true,
      Cell: (data: any) => <span>{data?.user?.firstName || "-"}</span>,
      sortableKey: "user.firstName",
    },
    {
      title: "Product",
      name: "product_name",
      showColumn: currentTab === "Product",
      sortable: true,
      Cell: (data: any) => <span>{data?.product?.title || "-"}</span>,
      sortableKey: "product.title",
    },
    {
      title: "Store",
      name: "store_name",
      showColumn: currentTab === "Shop",
      sortable: true,
      Cell: (data: any) => <span>{data?.vendor?.store_name || "-"}</span>,
      sortableKey: "vendor.store_name",
    },
    {
      title: "Is Block",
      name: "is_block",
      Cell: (data: any) => (
        <Switch
          checked={!!data?.is_block}
          isLoading={
            statusLoading?.id === data?.id &&
            statusLoading?.keyId === "is_block"
          }
          isDisabled={!role.includes("edit") || statusLoading}
          onChange={async (value: any, e: any) => {
            e.stopPropagation();
            try {
              const payload = {
                is_block: value ? 1 : 0,
                id: data?.id,
              };
              await onUpdateStatus(payload, "is_block");
            } catch (err) {}
          }}
        />
      ),
      sortable: true,
      sortableKey: "is_block",
    },
    {
      title: "Status",
      name: "status",
      Cell: (data: any) => (
        <Switch
          checked={data?.status === "Active" || data?.status === "active"}
          isLoading={
            statusLoading?.id === data?.id && statusLoading?.keyId === "status"
          }
          isDisabled={!role.includes("edit") || statusLoading}
          onChange={async (value: any, e: any) => {
            e.stopPropagation();
            try {
              const payload = {
                status: value ? "active" : "inactive",
                id: data?.id,
              };
              await onUpdateStatus(payload, "status");
            } catch (err) {}
          }}
        />
      ),
      sortable: true,
      sortableKey: "status",
    },
    {
      title: "Review",
      name: "review",
      sortable: true,
      Cell: (data: any) => (
        <>
          {data?.review ? (
            <ReadMoreLess
              text={data?.review}
              maxLength={media < 768 ? 90 : 180}
            />
          ) : (
            "-"
          )}
        </>
      ),
      sortableKey: "review",
    },
    {
      title: "Created",
      name: "created_at_ist",
      type: "date",
      Cell: (item: any) => {
        return <span>{item?.created_at_ist || "-"}</span>;
      },
      sortable: true,
      sortableKey: "created_at_ist",
    },
    {
      title: "Action",
      name: "action",
      Cell: (data: any) => (
        <>
          <div className="flex gap-2">
            <Tooltip text="Delete">
              <Button
                className="!h-8 !w-8 !p-0"
                disabled={!role.includes("delete")}
                onClick={() => {
                  setDeleteData(data);
                  setIsDeleteModalOpen(true);
                }}
              >
                <MdDeleteOutline />
              </Button>
            </Tooltip>
          </div>
        </>
      ),
    },
  ];

  const onRatingReviewDelete = async () => {
    try {
      const payload: any = {
        id: deleteData?.id,
      };
      const res = await deleteRatingReview(payload).unwrap();
      if (res) {
        responseToaster(res);
        setIsDeleteModalOpen(false);
        setDeleteData(false);
        setFilter({ ...filter, isFilter: false });
      }
    } catch (err) {
      console.error("Error =-=>", err);
    }
  };

  const getRatingReviewList = async () => {
    try {
      const { status, is_block, ...rest } = filter;
      const payload: any = {
        ...rest,
        filter_data: {
          product_id: currentTab === "Product" ? "All" : null,
          shop_id: currentTab === "Shop" ? "All" : null,
          status:
            filter?.isFilter && !filter?.isClear ? status || "All" : "All",
          is_block:
            filter?.isFilter && !filter?.isClear ? is_block || "All" : "All",
        },
      };
      delete payload["isFilter"];
      delete payload["isClear"];
      const res: any = await getRatingReview(payload);
      if (filter?.isFilter && !filter?.isClear) {
        responseToaster(res?.data);
      }
    } catch (error) {
      console.error("Error =-=>", error);
    }
  };

  const { register, handleSubmit, reset } = useForm<any>();

  useEffect(() => {
    if (currentTab === "Product") {
      setTableData(
        ratingReviewList?.data?.length ? [...ratingReviewList?.data] : []
      );
    } else {
      setTableData(ratingShop?.data?.length ? [...ratingShop?.data] : []);
    }
  }, [ratingReviewList, ratingShop, currentTab]);

  useEffect(() => {
    if (role.includes("list")) {
      getRatingReviewList();
    }
    // eslint-disable-next-line
  }, [filter]);

  const onSubmit = async (values: any) => {
    setFilter({ ...FILTER, ...values, isFilter: true });
  };

  return (
    <>
      {role.includes("list") ? (
        <div className="flex mb-3 gap-2 justify-end">
          <Button
            classNm={`!text-ev-white !border-0 appl-btn1`}
            onClick={() => {
              setShowDiv(!showDiv);
            }}
          >
            <Icon name="FilterIcon" />
          </Button>
        </div>
      ) : null}
      {showDiv ? (
        <div className="cardcss mb-3 ">
          <form
            className="flex gap-2 items-end sm:flex-wrap"
            onSubmit={handleSubmit(onSubmit)}
          >
            <Select
              name="is_block"
              label="Is Block"
              placeholder="All"
              options={statusOptions1}
              register={register}
            />
            <Select
              name="status"
              label="Status"
              placeholder="All"
              options={statusOptions}
              register={register}
            />
            <Button type="submit" className="!text-sm">
              Apply
            </Button>
            <Button
              variant="Transaparent"
              className="!text-sm"
              onClick={() => {
                reset();
                setFilter({ ...FILTER, isFilter: true, isClear: true });
              }}
            >
              Clear
            </Button>
          </form>
        </div>
      ) : null}
      {role.includes("list") ? (
        <div className="cardcss">
          <div className="flex mb-3 gap-4 justify-between border-b-2 p-1 ">
            <div className="flex gap-2 pb-1">
              {ratingReviewTab?.map((tab, index) => {
                return (
                  <div
                    key={index}
                    className={` px-6 h-9 flex items-center justify-center cursor-pointer tab-transition ${
                      currentTab !== tab?.label ? " " : "active"
                    }`}
                    onClick={() => {
                      setFilter({
                        ...FILTER,
                      });
                      reset();
                      dispatch(setCurrTab(tab?.label));
                    }}
                  >
                    {tab?.label}
                  </div>
                );
              })}
            </div>
          </div>
          <Table
            className="ratingReviewTable"
            columns={columns}
            isExpendable={false}
            isLoading={isLoading}
            data={tableData || []}
            setData={setTableData}
            count={
              currentTab === "Product"
                ? ratingReviewList?.total_item
                : currentTab === "Vendor"
                ? ratingShop?.total_item
                : 0
            }
            pagination={filter}
            handlePaginationChange={(pagination) => {
              setFilter({
                ...filter,
                ...pagination,
                isFilter: false,
              });
            }}
          >
            {tableData?.map((item: any, index: number) => {
              return (
                <Fragment key={index}>
                  <TableRow
                    columns={columns}
                    isExpendable={false}
                    item={item}
                    onClick={(item: any) => {}}
                  ></TableRow>
                </Fragment>
              );
            })}
          </Table>
        </div>
      ) : null}
      <Modal
        isOpen={!!isDeleteModalOpen}
        isLoading={isDeleteLoading}
        doneText="Delete"
        content={
          <div className="flex flex-col gap-2">
            <p>Are you sure you want to delete this?</p>
          </div>
        }
        onDoneClick={onRatingReviewDelete}
        onHide={() => {
          setIsDeleteModalOpen(false);
        }}
      />
    </>
  );
};

export default RatingReview;
