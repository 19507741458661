import React, { memo } from "react";
import { IconProps } from ".";

export interface ReportsIconProps extends IconProps {}
const Reports = (props: ReportsIconProps) => {
  const {
    className = "",
    pathClassNames = {},
    size = 20,
    stroke = "currentColor",
    fill = "none",
    strokeWidth = 1,
    ...rest
  } = props;
  return (
    <svg
      className={`${className}`}
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill={fill}
      stroke={stroke}
      strokeWidth={strokeWidth}
      {...rest}
    >
      <path
        d="M20 16V4H8v12m14 0c0 1.1-.9 2-2 2H8c-1.1 0-2-.9-2-2V4c0-1.1.9-2 2-2h12c1.1 0 2 .9 2 2m-6 16v2H4c-1.1 0-2-.9-2-2V7h2v13m12-9h2v3h-2m-3-8h2v8h-2m-3-6h2v6h-2Z"
        className={`${pathClassNames?.[0] || ""}`}
        stroke={stroke}
        strokeWidth="1"
      />
    </svg>
  );
};

const MemoReportsIcon = memo(Reports);
export default MemoReportsIcon;
