import { Fragment, useEffect, useState } from "react";
import { FILTER } from "../../../constant";
import { Button, Icon, Input, Table, TableRow } from "../../../ui";
import { useVendorPlanList } from "./vendorPlanSlice";
import { useGetVendorPlanMutation } from "./vendorPlanApi";
import { responseToaster, seperator } from "../../../helperFunctions";
import { useForm } from "react-hook-form";

import CustomeBreadcrumbs from "../../common/CustomeBreadcrumbs";
import VendorPlanUpdate from "./VendorPlanUpdate";
import { useRole } from "../../auth/authSlice";
import { useNavigate } from "react-router-dom";

const breadcrumbs = [{ label: "Vendor" }, { label: "Vendor Plan" }];

const VendorPlan = () => {
  const [getVendorPlan, { isLoading }] = useGetVendorPlanMutation();
  const vendorPlanList = useVendorPlanList();

  const [tableData, setTableData] = useState<any>([]);
  const [filter, setFilter] = useState<any>(FILTER);
  const [showDiv, setShowDiv] = useState(false);
  const navigate = useNavigate();

  const vendorRole = useRole("vendor");
  const vendorDetailsRole = useRole("vendor-details");

  const role = [...vendorRole, ...vendorDetailsRole];

  if (!role.includes("list")) {
    navigate("/");
  }

  const columns = [
    {
      title: "ID",
      name: "id",
      sortable: true,
      sortableKey: "id",
    },
    {
      title: "Vendor Name",
      name: "vendor_name",
      sortable: true,
      sortableKey: "vendor.name",
      Cell: (data: any) => {
        return <span>{data?.vendor?.name || "-"}</span>;
      },
    },
    {
      title: "Product Limits",
      name: "product_limit",
      sortable: true,
      sortableKey: "product_limit",
      Cell: (data: any) => {
        return (
          <>
            {!role.includes("list") ? (
              data?.product_limit
            ) : (
              <VendorPlanUpdate
                currKey={"product_limit"}
                tableData={tableData}
                data={data}
                unlimited={true}
              />
            )}
          </>
        );
      },
    },
    {
      title: "Digital Store",
      name: "digital_store",
      sortable: true,
      sortableKey: "digital_store",
    },
    {
      title: "Store Link QR Code",
      name: "store_link_qr_code",
      sortable: true,
      sortableKey: "store_link_qr_code",
    },
    {
      title: "Social Media Integration",
      name: "social_media_integration",
      sortable: true,
      sortableKey: "social_media_integration",
    },
    {
      title: "Coupon",
      name: "coupon",
      sortable: true,
      sortableKey: "coupon",
    },
    {
      title: "Instore Billing",
      name: "instore_billing",
      sortable: true,
      sortableKey: "instore_billing",
    },
    {
      title: "Customer Vendor Chat",
      name: "customer_vendor_chat",
      sortable: true,
      sortableKey: "customer_vendor_chat",
    },
    {
      title: "Customer List",
      name: "customer_list",
      sortable: true,
      sortableKey: "customer_list",
    },
    {
      title: "Quick Order",
      name: "quick_order",
      sortable: true,
      sortableKey: "quick_order",
    },
    {
      title: "Direct UPI Payment",
      name: "direct_upi_payment",
      sortable: true,
      sortableKey: "direct_upi_payment",
    },
    {
      title: "Commission",
      name: "commission",
      sortable: true,
      sortableKey: "commission",
      Cell: (data: any) => {
        return <span>{seperator(data?.commission)}</span>;
      },
    },
    {
      title: "Stock Management",
      name: "stock_management",
      sortable: true,
      sortableKey: "stock_management",
    },
    {
      title: "Store Analytics",
      name: "store_analytics",
      sortable: true,
      sortableKey: "store_analytics",
    },
    {
      title: "Product Catalogue",
      name: "product_catalogue",
      sortable: true,
      sortableKey: "product_catalogue",
    },
    {
      title: "Barcode Integration",
      name: "barcode_integration",
      sortable: true,
      sortableKey: "barcode_integration",
    },
    {
      title: "Delivery App",
      name: "delivery_app",
      sortable: true,
      sortableKey: "delivery_app",
    },
    {
      title: "Supplier List",
      name: "supplier_list",
      sortable: true,
      sortableKey: "supplier_list",
    },
    {
      title: "Subscription Start Date",
      name: "subscription_start_ist",
      sortable: true,
      type: "date",
      sortableKey: "subscription_start_ist",
    },
    {
      title: "Subscription End Date",
      name: "subscription_expired_date",
      sortable: true,
      type: "date",
      sortableKey: "subscription_expired_date",
    },
  ];

  const getVendorPlanList = async () => {
    try {
      const { search, ...rest } = filter;
      const payload: any = {
        ...rest,
        filter_data: {
          search,
        },
      };
      delete payload["isFilter"];
      delete payload["isClear"];
      const res: any = await getVendorPlan(payload);
      if (filter?.isFilter && !filter?.isClear) {
        responseToaster(res?.data);
      }
    } catch (error) {
      console.error("Error =-=>", error);
    }
  };

  const { register, handleSubmit, reset } = useForm<any>();

  useEffect(() => {
    setTableData(vendorPlanList?.data?.length ? [...vendorPlanList?.data] : []);
  }, [vendorPlanList]);
  useEffect(() => {
    getVendorPlanList();
    // eslint-disable-next-line
  }, [filter]);

  const onSubmit = async (values: any) => {
    setFilter({ ...FILTER, ...values, isFilter: true });
  };

  return (
    <>
      <div className="flex mb-3 gap-2 justify-between sm:flex-wrap">
        <CustomeBreadcrumbs breadcrumbs={breadcrumbs} />

        <Button
          classNm={`!text-ev-white !border-0 appl-btn1`}
          onClick={() => {
            setShowDiv(!showDiv);
          }}
          
        >
          <Icon name="FilterIcon" />
        </Button>
      </div>
      {showDiv ? (
        <div className="cardcss mb-3 flex">
          <form
            className="flex gap-2 items-end sm:flex-wrap"
            onSubmit={handleSubmit(onSubmit)}
          >
            <Input
              name="search"
              label="Search"
              placeholder="Search by vendor name"
              register={register}
            />

            <Button type="submit" className="!text-sm">
              Apply
            </Button>
            <Button
              variant="Transaparent"
              className="!text-sm"
              onClick={() => {
                reset();
                setFilter({ ...FILTER, isFilter: true, isClear: true });
              }}
            >
              Clear
            </Button>
          </form>
        </div>
      ) : null}
      <div className="cardcss">
        <Table
          containerClass="planTable"
          columns={columns}
          isExpendable={false}
          isLoading={isLoading}
          data={tableData || []}
          setData={setTableData}
          count={vendorPlanList?.total_item || 0}
          pagination={filter}
          handlePaginationChange={(pagination) => {
            setFilter({
              ...filter,
              ...pagination,
              isFilter: false,
            });
          }}
        >
          {tableData?.map((item: any, index: number) => {
            return (
              <Fragment key={index}>
                <TableRow
                  columns={columns}
                  isExpendable={false}
                  item={item}
                  onClick={(item: any) => {}}
                ></TableRow>
              </Fragment>
            );
          })}
        </Table>
      </div>
    </>
  );
};

export default VendorPlan;
