import React, { Fragment, useEffect, useState } from "react";
import { Button, Icon, Input, Select, Table, TableRow } from "../../../ui";
import { FILTER, statusOptions } from "../../../constant";

import PinCodeModal from "./PinCodeModal";
import { MdEdit } from "react-icons/md";
import { MdDeleteOutline } from "react-icons/md";
import { Modal } from "../../common";
import Switch from "../../../ui/forms/Switch";
import { responseToaster } from "../../../helperFunctions";
import { useDispatch } from "react-redux";
import Tooltip from "../../../ui/elements/Tooltip";
import CustomeBreadcrumbs from "../../common/CustomeBreadcrumbs";
import { useNavigate } from "react-router-dom";
import { useRole } from "../../auth/authSlice";
import {
  useUpdatePinCodeMutation,
  useGetPinCodeMutation,
  useDeletePinCodeMutation,
} from "./pinCodeApi";
import { useForm } from "react-hook-form";
import { setPinCode, usePinCodeList } from "./pinCodeSlice";

const PinCode = () => {
  const [getPinCode, { isLoading }] = useGetPinCodeMutation();
  const pinCodeList = usePinCodeList();
  const [updatePinCode] = useUpdatePinCodeMutation();

  const [deletePinCode, { isLoading: isDeleteLoading }] =
    useDeletePinCodeMutation();
  const [isPinCodeModal, setIsPinCodeModal] = useState(false);
  const [tableData, setTableData] = useState<any>([]);
  const [filter, setFilter] = useState<any>(FILTER);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [deleteData, setDeleteData] = useState<any>();
  const [pinCodeData, setPinCodeData] = useState({});
  const [showDiv, setShowDiv] = useState(false);
  const [statusLoading, setStatusLoading] = useState(false);
  const navigate = useNavigate();
  const role = useRole("pin-code");

  if (!role.includes("list")) {
    navigate("/");
  }
  const breadcrumbs = [{ label: "Courier" }, { label: "Pincode" }];

  const dispatch = useDispatch();

  const handleAddPinCode = () => {
    setIsPinCodeModal(true);
  };

  const onUpdateStatus = async (payload: any) => {
    try {
      setStatusLoading(payload?.id);
      const res = await updatePinCode(payload).unwrap();

      const index = tableData?.findIndex(
        (item: { id: any }) => item?.id === payload?.id
      );
      if (index > -1) {
        const newData = [...tableData];
        newData[index] = {
          ...newData[index],
          status: payload?.status,
        };
        dispatch(
          setPinCode({
            ...pinCodeList,
            data: newData,
          })
        );
      }
      responseToaster(res);
      setStatusLoading(false);
    } catch (err) {
      setStatusLoading(false);
    }
  };

  const columns = [
    {
      title: "ID",
      name: "id",
      sortable: true,
      sortableKey: "id",
    },
    {
      title: "Pincode",
      name: "pin_code",
      sortable: true,
      sortableKey: "pin_code",
    },
    {
      title: "Status",
      name: "status",
      sortable: true,
      sortableKey: "status",
      Cell: (data: any) => (
        <Switch
          checked={data?.status === 1}
          // checked={data?.status === "active"}
          isLoading={statusLoading === data?.id}
          isDisabled={!role.includes("edit") || statusLoading}
          onChange={async (value: any, e: any) => {
            e.stopPropagation();
            try {
              const payload = {
                status: value ? 1 : 0,
                // status: value ? "active" : "inactive",
                id: data?.id,
              };
              await onUpdateStatus(payload);
            } catch (err) {}
          }}
        />
      ),
    },
    {
      title: "Date",
      name: "created_at_ist",
      sortable: true,
      sortableKey: "created_at_ist",
    },
    {
      title: "Action",
      name: "action",
      Cell: (data: any) => (
        <>
          <div className="flex gap-1">
            <Tooltip text="Edit">
              <Button
                className="!h-8 !w-8 !p-0"
                onClick={() => {
                  setIsPinCodeModal(true);
                  setPinCodeData(data);
                }}
                disabled={!role.includes("edit")}
              >
                <MdEdit />
              </Button>
            </Tooltip>
            <Tooltip text="Delete" className="right-0">
              <Button
                className="!h-8 !w-8 !p-0"
                onClick={() => {
                  setDeleteData(data);
                  setIsDeleteModalOpen(true);
                }}
                disabled={!role.includes("delete")}
              >
                <MdDeleteOutline />
              </Button>
            </Tooltip>
          </div>
        </>
      ),
    },
  ];

  const { register, handleSubmit, reset } = useForm<any>();

  const onPinCodeDelete = async () => {
    try {
      const payload: any = {
        id: deleteData?.id,
      };
      const res = await deletePinCode(payload).unwrap();
      if (res) {
        responseToaster(res);
        setIsDeleteModalOpen(false);
        setDeleteData(false);
        setFilter({ ...filter, isFilter: false });
      }
    } catch (err) {
      console.error("Error =-=>", err);
    }
  };

  const getPinCodeList = async () => {
    try {
      // eslint-disable-next-line
      const { status, search, ...rest } = filter;
      const payload: any = {
        ...rest,
        filter_data: {
          status: status === "active" ? 1 : status === "inactive" ? 0 : "all",
          search,
        },
      };
      delete payload["isFilter"];
      delete payload["isClear"];
      const res: any = await getPinCode(payload);
      if (filter?.isFilter && !filter?.isClear) {
        responseToaster(res?.data);
      }
    } catch (error) {
      console.error("Error =-=>", error);
    }
  };

  useEffect(() => {
    if (role.includes("list")) {
      getPinCodeList();
    }
    // eslint-disable-next-line
  }, [filter]);

  useEffect(() => {
    setTableData(pinCodeList?.data?.length ? [...pinCodeList?.data] : []);
    // eslint-disable-next-line
  }, [pinCodeList]);

  const onSubmit = async (values: any) => {
    setFilter({ ...FILTER, ...values, isFilter: true });
  };

  return (
    <>
      <div className="">
        <div className="flex mb-3 gap-2 justify-between sm:flex-wrap">
          <CustomeBreadcrumbs breadcrumbs={breadcrumbs} />
          <div className="flex gap-2 sm:flex-wrap">
            {role.includes("create") ? (
              <div className="flex gap-2 justify-between">
                <div className="flex justify-end">
                  <Button onClick={() => handleAddPinCode()}>
                    Add Pincode
                  </Button>
                </div>
              </div>
            ) : null}
            {role.includes("list") ? (
              <Button
                classNm={`!text-ev-white !border-0 appl-btn1`}
                onClick={() => {
                  setShowDiv(!showDiv);
                }}
                
              >
                <Icon name="FilterIcon" />
              </Button>
            ) : null}
          </div>
        </div>
        {showDiv ? (
          <div className="cardcss mb-3">
            <form
              className="flex gap-2 items-end sm:flex-wrap"
              onSubmit={handleSubmit(onSubmit)}
            >
              <Input
                name="search"
                label="Search"
                placeholder="Search by pincode"
                register={register}
              />

              <Select
                name="status"
                label="Status"
                placeholder="All"
                options={statusOptions}
                register={register}
              />

              <Button type="submit" className="!text-sm">
                Apply
              </Button>
              <Button
                variant="Transaparent"
                className="!text-sm"
                onClick={() => {
                  reset();
                  setFilter({ ...FILTER, isFilter: true, isClear: true });
                }}
              >
                Clear
              </Button>
            </form>
          </div>
        ) : null}
        {role.includes("list") ? (
          <div className="cardcss">
            <Table
              columns={columns}
              isExpendable={false}
              isLoading={isLoading}
              data={tableData || []}
              setData={setTableData}
              count={pinCodeList?.total_item}
              pagination={filter}
              handlePaginationChange={(pagination) => {
                setFilter({
                  ...filter,
                  ...pagination,
                  isFilter: false,
                });
              }}
            >
              {tableData?.map((item: any, index: number) => {
                return (
                  <Fragment key={index}>
                    <TableRow
                      columns={columns}
                      isExpendable={false}
                      item={item}
                    ></TableRow>
                  </Fragment>
                );
              })}
            </Table>
          </div>
        ) : null}
      </div>
      <PinCodeModal
        isOpen={isPinCodeModal}
        onGet={getPinCodeList}
        pinCodeData={pinCodeData}
        onHide={() => {
          setIsPinCodeModal(false);
          setPinCodeData({});
        }}
      />
      <Modal
        isOpen={!!isDeleteModalOpen}
        isLoading={isDeleteLoading}
        doneText="Delete"
        content={
          <div className="flex flex-col gap-2">
            <p>Are you sure you want to delete this?</p>
          </div>
        }
        onDoneClick={onPinCodeDelete}
        onHide={() => {
          setIsDeleteModalOpen(false);
        }}
      />
    </>
  );
};

export default PinCode;
