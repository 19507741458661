// THIRD - PARTY IMPORT
import psl from "psl";

export const LIMIT = 50;
export const FILTER = {
  page_no: 1,
  limit: LIMIT,
  isFilter: false,
};

// export const BASE_URL = `https://${window.location.hostname}`;

export const LOGOUT_TIME = 1000 * 60 * 30;

export const LOGOUT_EVENTS = [
  "mousedown",
  "mousemove",
  "wheel",
  "keydown",
  "touchstart",
  "scroll",
];

export const statusOptions = [
  {
    value: "active",
    label: "Active",
  },
  {
    value: "inactive",
    label: "Inactive",
  },
];

export const statusOptions1 = [
  {
    value: 1,
    label: "Yes",
  },
  {
    value: 0,
    label: "No",
  },
];
export const paymentMethodOptions:any = {
  0: "All",
  1: "Cash",
  2: "Online",
};

export const DOMAINS: any = psl.parse("merchant-new.devteamandro.co.in");
// export const DOMAINS:any = psl.parse(window.location.hostname)

export const SMS_LOCAL = "smsPagination";
export const USER_LOCAL = "userPagination";
export const BANK_LOCAL = "bankPagination";

export const DEFAULT_FONT_FAMILY = "Roboto, sans-serif";

export const DROPDOWN_ALL_VALUE = "All";
export const DROPDOWN_ALL_LABEL = "All";

export const DROPDOWN_SELECT_VALUE = "";
export const DROPDOWN_SELECT_LABEL = "Select";

export const DROPDOWN_SEARCH_VALUE = "searchdata";
export const DROPDOWN_SEARCH_LABEL = "Search Data";


export const COUPON_TAGS = [
  '<strong>', '</strong>', '<br>','<p>','</p>','<br>','</br>', '</b>', '<em>', '</em>', '<i>', '</i>', '<u>', '</u>', '<mark>', '</mark>', '<small>', '</small>', '<del>', '</del>',
  '<h1>', '</h1>', '<h2>', '</h2>', '<h3>', '</h3>', '<h4>', '</h4>', '<h5>', '</h5>', '<h6>', '</h6>', '<blockquote>', '</blockquote>', '<code>', '</code>', '<pre>', '</pre>',
  '<ul>', '</ul>', '<ol>', '</ol>', '<li>', '</li>',
  '<a>', '</a>',
  '<img>', '<video>', '</video>', '<audio>', '</audio>',
  '<table>', '</table>', '<tr>', '</tr>', '<td>', '</td>', '<th>', '</th>', '<thead>', '</thead>', '<tbody>', '</tbody>', '<tfoot>', '</tfoot>',
  '<form>', '</form>', '<input>', '<textarea>', '</textarea>', '<button>', '</button>', '<label>', '</label>',
  '<header>', '</header>', '<footer>', '</footer>', '<section>', '</section>', '<article>', '</article>', '<nav>', '</nav>', '<div>', '</div>', '<span>', '</span>'
];
