import React, { Fragment, useEffect, useState } from "react";
import {
  Button,
  ControlledDatePicker,
  Icon,
  Input,
  Select,
  Table,
  TableRow,
} from "../../../ui";
import { FILTER } from "../../../constant";
import { useGetSupportLogsMutation } from "./supportLogsApi";
import { useSupportLogsList } from "./supportLogsSlice";
import { dateFormatter, responseToaster } from "../../../helperFunctions";
import { useNavigate } from "react-router-dom";
import { useRole } from "../../auth/authSlice";
import ReadMoreLess from "../../common/ReadMoreLess";
import { useForm } from "react-hook-form";
import { typeOptionsForOrder } from "./mock";

const initialValues = {
  startDate: [null, null],
};

const SupportLogs = () => {
  const [getSupportLogs, { isLoading }] = useGetSupportLogsMutation();
  const supportLogsList = useSupportLogsList();
  const [tableData, setTableData] = useState<any>([]);
  const [filter, setFilter] = useState<any>(FILTER);
  const [showDiv, setShowDiv] = useState(false);

  const navigate = useNavigate();
  // const role = useRole("logs");

  const logsRole = useRole("logs");
  const vendorDetailsRole = useRole("vendor-details");

  const role = [...logsRole, ...vendorDetailsRole];

  if (!role.includes("list")) {
    navigate("/");
  }
  const columns = [
    {
      title: "ID",
      name: "id",
      sortable: true,
      sortableKey: "id",
    },
    {
      title: "Module",
      name: "module",
      sortable: true,
      sortableKey: "module",
    },
    {
      title: "Type",
      name: "type",
      sortable: true,
      sortableKey: "type",
    },
    {
      title: "Created By",
      name: "created_by",
      sortable: true,
      sortableKey: "created_by",
      Cell: (data: any) => {
        return <span>{data?.admin?.name || "-"}</span>;
      },
    },
    {
      title: "Request Data",
      name: "request_data",
      sortable: true,
      sortableKey: "request_data",
      Cell: (data: any) => {
        return data?.request_data ? (
          <ReadMoreLess text={data?.request_data} maxLength={50} />
        ) : (
          " -"
        );
      },
    },
    {
      title: "Table Name",
      name: "table_name",
      sortable: true,
      sortableKey: "table_name",
    },
    {
      title: "Date",
      name: "created_at_ist",
      sortable: true,
      sortableKey: "created_at_ist",
    },
  ];
  const getSupportLogsList = async () => {
    try {
      const { search, type, created_by, startDate, ...rest } = filter;

      const payload: any = {
        ...rest,
        filter_data: {
          search,
          created_by,
          type: type || "All",
          start_date: dateFormatter(startDate?.[0], "start"),
          end_date: dateFormatter(startDate?.[1], "end", startDate?.[0]),
        },
      };

      delete payload["isFilter"];
      delete payload["isClear"];
      const res: any = await getSupportLogs(payload);
      if (filter?.isFilter && !filter?.isClear) {
        responseToaster(res?.data);
      }
    } catch (error) {
      console.error("Error =-=>", error);
    }
  };

  const prepareCreatedByList = () => {
    const subCateOptions = supportLogsList?.admin
      ?.slice?.()
      ?.sort?.((a: any, b: any) => a?.name?.localeCompare?.(b?.name))
      ?.map?.((val: any) => ({
        value: val?.id,
        label: val?.name,
      }));
    return subCateOptions || [];
  };

  useEffect(() => {
    if (role.includes("list")) {
      getSupportLogsList();
    }
    // eslint-disable-next-line
  }, [filter]);

  const { register, handleSubmit, reset, control } = useForm<any>({
    defaultValues: {
      ...initialValues,
    },
  });

  useEffect(() => {
    setTableData(
      supportLogsList?.data?.length ? [...supportLogsList?.data] : []
    );
  }, [supportLogsList]);

  const onSubmit = async (values: any) => {
    setFilter({ ...FILTER, ...values, isFilter: true });
  };

  return (
    <>
      <div className="flex mb-3 gap-2 justify-end sm:flex-wrap">
        {role.includes("list") ? (
          <Button
            classNm={`!text-ev-white !border-0 appl-btn1`}
            onClick={() => {
              setShowDiv(!showDiv);
            }}
            
          >
            <Icon name="FilterIcon" />
          </Button>
        ) : null}
      </div>
      {showDiv ? (
        <div className="cardcss mb-3">
          <form
            className="flex gap-2 items-end sm:flex-wrap "
            onSubmit={handleSubmit(onSubmit)}
          >
            <Input
              name="search"
              label="Search By Module"
              placeholder="Search By Module"
              register={register}
            />
            <Select
              name="created_by"
              label="Created By"
              placeholder="All"
              options={prepareCreatedByList()}
              register={register}
            />
            <Select
              name="type"
              label="Type"
              placeholder="All"
              options={typeOptionsForOrder}
              register={register}
            />
            <ControlledDatePicker
              name="startDate"
              label="Order Date"
              placeholder="Select Order Date"
              selectsRange
              control={control}
              maxDate={new Date()}
            />
            <Button type="submit" className="!text-sm">
              Apply
            </Button>
            <Button
              variant="Transaparent"
              className="!text-sm"
              onClick={() => {
                reset();
                setFilter({ ...FILTER, isFilter: true, isClear: true });
              }}
            >
              Clear
            </Button>
          </form>
        </div>
      ) : null}
      <div>
        {role.includes("list") ? (
          <div className="cardcss">
            <Table
              columns={columns}
              className="supportLogsTable"
              isExpendable={false}
              isLoading={isLoading}
              data={tableData || []}
              setData={setTableData}
              count={supportLogsList?.total_item}
              pagination={filter}
              handlePaginationChange={(pagination) => {
                setFilter({
                  ...filter,
                  ...pagination,
                  isFilter: false,
                });
              }}
            >
              {tableData?.map((item: any, index: number) => {
                return (
                  <Fragment key={index}>
                    <TableRow
                      columns={columns}
                      isExpendable={false}
                      item={item}
                      onClick={(item: any) => {}}
                    ></TableRow>
                  </Fragment>
                );
              })}
            </Table>
          </div>
        ) : null}
      </div>
    </>
  );
};

export default SupportLogs;
