import React, { Fragment, useEffect, useState } from "react";
import { Button, Icon, Select, Table, TableRow } from "../../../ui";
import { FILTER } from "../../../constant";
import {
  useDeleteSubCategoryMutation,
  useGetSubCategoryMutation,
} from "./subCategoryApi";
import {
  setCurrTab,
  useSubCategoryList,
  useCurrentTab,
  useVendorSubCategoryList,
} from "./subCategorySlice";
import SubCategoryModal from "./SubCategoryModal";
import { MdEdit } from "react-icons/md";
import { MdDeleteOutline } from "react-icons/md";
import { Modal } from "../../common";
import {
  prepareVendorOptions,
  responseToaster,
} from "../../../helperFunctions";
import Tooltip from "../../../ui/elements/Tooltip";
import { useForm } from "react-hook-form";
import { IoEye } from "react-icons/io5";
import { useDispatch } from "react-redux";
import CustomeBreadcrumbs from "../../common/CustomeBreadcrumbs";
import { useNavigate } from "react-router-dom";
import { useRole } from "../../auth/authSlice";

interface SubCategoryTabs {
  label: string;
  Component?: any;
}

const initialValues = {
  startDate: [null, null],
};
const SubCategory = () => {
  const [getSubCategory, { isLoading }] = useGetSubCategoryMutation();
  const subCategoryList = useSubCategoryList();
  const subCategoryVendorList = useVendorSubCategoryList();
  const [deleteSubCategory, { isLoading: isDeleteLoading }] =
    useDeleteSubCategoryMutation();
  const currentTab = useCurrentTab();
  const [isSubCategoryModal, setIsSubCategoryModal] = useState(false);
  const [tableData, setTableData] = useState<any>([]);
  const [filter, setFilter] = useState<any>(FILTER);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [deleteData, setDeleteData] = useState<any>();
  const [subCategoryData, setSubCategoryData] = useState({});
  const [showDiv, setShowDiv] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // const role = useRole("sub-category");

  const subCategoryRole = useRole("sub-category");
  const vendorDetailsRole = useRole("vendor-details");

  const role = [...subCategoryRole, ...vendorDetailsRole];

  if (!role.includes("list")) {
    navigate("/");
  }
  const breadcrumbs = [
    { label: "Master" },
    { label: "SubCategory" },
    { label: currentTab },
  ];
  const handleAddSubCategory = () => {
    setIsSubCategoryModal(true);
  };
 
  const columns = [
    {
      title: "ID",
      name: "id",
      sortable: true,
      sortableKey: "id",
    },
    {
      title: "Name",
      name: "name",
      sortable: true,
      sortableKey: "name",
    },
    {
      title: "Vendor Name",
      name: "vendor_name",
      sortable: true,
      sortableKey: "vendor.name",
      Cell: (data: any) => {
        return <span>{data?.vendor?.name || "-"}</span>;
      },
    },
    {
      title: "Created",
      name: "created_at_ist",
      sortable: true,
      type: "date",
      sortableKey: "created_at_ist",
    },
    {
      title: "Action",
      name: "action",
      Cell: (item: any) => {
        return (
          <div className="flex items-center gap-2">
            {currentTab !== "Vendor" ? (
              <Tooltip text="Edit">
                <Button
                  className="!h-8 w-8 !p-0"
                  onClick={() => {
                    setIsSubCategoryModal(true);
                    setSubCategoryData(item);
                  }}
                  disabled={!role.includes("edit")}
                >
                  <MdEdit />
                </Button>
              </Tooltip>
            ) : (
              <Tooltip text="Preview">
                <Button
                  className="!h-8 !w-8 !p-0"
                  onClick={() => {
                    setIsSubCategoryModal(true);
                    setSubCategoryData(item);
                  }}
                >
                  <IoEye />
                </Button>
              </Tooltip>
            )}
            <Tooltip text="Delete">
              <Button
                className="!h-8 w-8 !p-0"
                onClick={() => {
                  setIsDeleteModalOpen(true);
                  setDeleteData(item);
                }}
                disabled={!role.includes("delete")}
              >
                <MdDeleteOutline />
              </Button>
            </Tooltip>
          </div>
        );
      },
    },
  ];
  const subCategoryTab: SubCategoryTabs[] = [
    { label: "Admin", Component: SubCategory },
    { label: "Vendor", Component: SubCategory },
  ];

  const onSubCategoryDelete = async () => {
    try {
      const payload: any = {
        id: deleteData?.id,
      };
      const res = await deleteSubCategory(payload).unwrap();
      if (res) {
        setIsDeleteModalOpen(false);
        setDeleteData(false);
        setFilter({ ...filter, isFilter: false });
      }
      responseToaster(res);
    } catch (err) {
      console.error("Error =-=>", err);
    }
  };

  const getSubCategoryList = async () => {
    try {
      const payload: any = {
        ...filter,
        filter_data: {
          vendor_id: filter?.vendor_id,
          is_admin: currentTab === "Admin" ? 1 : 0,
        },
      };

      delete payload["isFilter"];
      delete payload["isClear"];
      const res: any = await getSubCategory(payload);
      if (filter?.isFilter && !filter?.isClear) {
        responseToaster(res?.data);
      }
    } catch (error) {
      console.error("Error =-=>", error);
    }
  };

  const { register, handleSubmit, reset } = useForm<any>({
    defaultValues: {
      ...initialValues,
    },
  });

  useEffect(() => {
    if (role.includes("list")) {
      getSubCategoryList();
    }
    // eslint-disable-next-line
  }, [filter, currentTab]);

  useEffect(() => {
    if (currentTab === "Admin") {
      setTableData(
        subCategoryList?.data?.length ? [...subCategoryList?.data] : []
      );
    } else {
      setTableData(
        subCategoryVendorList?.data?.length
          ? [...subCategoryVendorList?.data]
          : []
      );
    }
  }, [subCategoryList, subCategoryVendorList, currentTab]);

  const onSubmit = async (values: any) => {
    setFilter({ ...FILTER, ...values, isFilter: true });
  };

  return (
    <>
      <div>
        <div className="flex mb-3 gap-2 justify-between sm:flex-wrap">
          <CustomeBreadcrumbs breadcrumbs={breadcrumbs} />
          <div className="flex gap-2 justify-end ">
            <Button
              classNm={`!text-ev-white !border-0 appl-btn1`}
              onClick={() => {
                setShowDiv(!showDiv);
              }}
              
            >
              <Icon name="FilterIcon" />
            </Button>
            {currentTab !== "Vendor" && role.includes("create") ? (
              <div className="flex justify-end">
                <Button onClick={() => handleAddSubCategory()}>
                  Add SubCategory
                </Button>
              </div>
            ) : null}
          </div>
        </div>

        {showDiv && (
          <div className="cardcss mb-3 flex">
            <form
              className="flex gap-2 items-end"
              onSubmit={handleSubmit(onSubmit)}
            >
              <Select
                name="vendor_id"
                label="Vendor"
                placeholder="Select Vendor"
                options={prepareVendorOptions(subCategoryList?.vendorsList)}
                register={register}
              />

              <Button type="submit" className="!text-sm">
                Apply
              </Button>
              <Button
                variant="Transaparent"
                className="!text-sm"
                onClick={() => {
                  reset();
                  setFilter({ ...FILTER, isFilter: true, isClear: true });
                }}
              >
                Clear
              </Button>
            </form>
          </div>
        )}

        {role.includes("list") ? (
          <div className="cardcss">
            <div className="flex mb-3 gap-4 justify-between border-b-2 p-1 ">
              <div className="flex gap-2 pb-1">
                {subCategoryTab?.map((tab, index) => {
                  return (
                    <div
                      key={index}
                      className={` px-6 h-9 flex items-center justify-center cursor-pointer tab-transition ${
                        currentTab !== tab?.label ? " " : "active"
                      }`}
                      onClick={() => {
                        setFilter({
                          ...FILTER,
                        });
                        reset();
                        dispatch(setCurrTab(tab?.label));
                      }}
                    >
                      {tab?.label}
                    </div>
                  );
                })}
              </div>
            </div>

            <Table
              columns={columns}
              className="subCategoryTable"
              isExpendable={false}
              isLoading={isLoading}
              data={tableData || []}
              setData={setTableData}
              count={
                currentTab === "Admin"
                  ? subCategoryList?.total_item
                  : currentTab === "Vendor"
                  ? subCategoryVendorList?.total_item
                  : 0
              }
              pagination={filter}
              handlePaginationChange={(pagination) => {
                setFilter({
                  ...filter,
                  ...pagination,
                  isFilter: false,
                });
              }}
            >
              {tableData?.map((item: any, index: number) => {
                return (
                  <Fragment key={index}>
                    <TableRow
                      columns={columns}
                      isExpendable={false}
                      item={item}
                      onClick={(item: any) => {}}
                    ></TableRow>
                  </Fragment>
                );
              })}
            </Table>
          </div>
        ) : null}
      </div>
      <SubCategoryModal
        currentTab={currentTab}
        isOpen={isSubCategoryModal}
        onGet={getSubCategoryList}
        subCategoryData={subCategoryData}
        onHide={() => {
          setIsSubCategoryModal(false);
          setSubCategoryData({});
        }}
      />
      <Modal
        isOpen={!!isDeleteModalOpen}
        isLoading={isDeleteLoading}
        doneText="Delete"
        content={
          <div className="flex flex-col gap-2">
            <p>Are you sure you want to delete this?</p>
          </div>
        }
        onDoneClick={onSubCategoryDelete}
        onHide={() => {
          setIsDeleteModalOpen(false);
        }}
      />
    </>
  );
};

export default SubCategory;
