import { Link, useLocation } from "react-router-dom";
import { Icon } from "../../ui";
import { toggleDrawer, useIsDrawerOpen } from "../common/commonSlice";
import { useMedia } from "../../components";
import { useAppDispatch } from "../../utills/hooks";
import { useState } from "react";
import { FaChevronDown, FaChevronRight } from "react-icons/fa";

const SideBarMenu = (props: any) => {
  const { name, route, icon_class, onClick, hasSubmenu, submenu, ...rest } =
    props;
  const location = useLocation();
  const isDrawerOpen = useIsDrawerOpen();
  const { media } = useMedia();
  const dispatch = useAppDispatch();
  const [submenuOpen, setSubmenuOpen] = useState(false);
  const isActive = () => {
    const fLocation = `/${location?.pathname?.split("/")?.[1]}`;
    return (
      location?.pathname === route ||
      route === fLocation ||
      decodeURIComponent(location?.pathname).startsWith(
        decodeURIComponent(route)
      )
    );
  };
  const subMenuisActive = (submenuRoute: string) => {
    const segments = location?.pathname?.split?.("/");
    /* const pathnameSegments = location?.pathname?.split("/")?.slice(1, 3);
    const submenuRouteSegments = submenuRoute?.split("/")?.slice(1, 3); */
    return (
      location?.pathname === `/${submenuRoute}` ||
      submenuRoute ===
        `${segments?.[1]}/${segments?.[2]}/${segments?.[3]}` /* ||
      pathnameSegments?.join?.("/") === submenuRouteSegments?.join?.("/") */
    );
  };

  const toggleSubmenu = (e: any) => {
    e.stopPropagation();
    setSubmenuOpen(!submenuOpen);
  };
  return (
    <>
      <ul
        className={`mainMenu ${rest?.parentRoute && "mainSubMenu"}`}
        key={name}
      >
        <li
          onClick={onClick}
          className={`${
            rest?.parentRoute && isDrawerOpen && "activeSubMainMenu relative"
          } ${
            !rest?.parentRoute ||
            (!isDrawerOpen && "activeSubMainMenu1 relative")
          } `}
        >
          <div title={name}>
            <Link
              to={{ pathname: !hasSubmenu ? route : null }}
              className={`${isActive() && "activeMenu "} ${
                subMenuisActive(route) && "activeSubMenu"
              } py-2 ${
                !hasSubmenu && isDrawerOpen
                  ? "justify-start"
                  : !isDrawerOpen
                  ? "justify-center "
                  : "justify-between "
              } ${
                isDrawerOpen ? "avtivemenu_position" : "avtiveicon_position"
              } ${
                !!rest?.parentRoute && subMenuisActive(route)
                  ? "activeSubMenuu"
                  : ""
              }`}
              onClick={hasSubmenu ? toggleSubmenu : undefined}
            >
              <div className={`flex items-center px-2`}>
                {!rest?.parentRoute || !isDrawerOpen ? (
                  <Icon
                    name={
                      icon_class === "Role-ManagementIcon"
                        ? "RoleManagementIcon"
                        : icon_class
                    }
                    className={`${isDrawerOpen ? "me-4" : ""}  `}
                    stroke={`${
                      isActive() ? "var(--ev-primary--)" : "currentColor"
                    }`}
                  />
                ) : null}
                {isDrawerOpen ? (
                  <span className="text-capitalize text-base">
                    {name === "Role-Management" ? "Role" : name}
                  </span>
                ) : null}
              </div>
              <div className={`${isDrawerOpen && "pr-5"}`}>
                {hasSubmenu && isDrawerOpen ? (
                  submenuOpen ? (
                    <FaChevronDown />
                  ) : (
                    <FaChevronRight />
                  )
                ) : null}
              </div>
              {props?.children && <i className="fa-solid fa-angle-right"></i>}
            </Link>
          </div>
        </li>
        {hasSubmenu && submenuOpen
          ? submenu?.map((item: any, index: number) => {
              return (
                <div className={`${isDrawerOpen ? "ml-7" : ""}`} key={index}>
                  <SideBarMenu
                    name={item?.name}
                    route={`${item.group.toLowerCase()}${item?.route}`}
                    icon_class={item?.icon_class}
                    onClick={() => {
                      if (media.width < 768) {
                        dispatch(toggleDrawer(false));
                      }
                    }}
                    hasSubMenu={!!item?.length}
                    submenu={item}
                    parentRoute={`${item?.group?.toLowerCase()}`}
                  />
                </div>
              );
            })
          : null}
      </ul>
    </>
  );
};

export default SideBarMenu;
