import { useDispatch } from "react-redux";
import React, { useState } from "react";
import { Button } from "reactstrap";
import { responseToaster, seperator } from "../../../helperFunctions";
import { useUpdateSubscriptionsMutation } from "./subscriptionsApi";
import { setSubscriptions } from "./subscriptionsSlice";
import { Input, Loader } from "../../../ui";
import { IoMdCheckmark } from "react-icons/io";
import { RxCross2 } from "react-icons/rx";

const SubscriptionUpdate = (props: any) => {
  const { data, tableData, currKey, unlimited = true, rupee = false,isDisable, isDecimal = false } = props;
  const dispatch = useDispatch();
  const [updateSubscriptions] = useUpdateSubscriptionsMutation();
  const [isOpen, setIsOpen] = useState(false);
  const [updateDetails, setUpdateDetails] = useState<any>();
  const [statusLoading, setStatusLoading] = useState(false);
  const [error, setError] = useState("");

  const onUpdate = async () => {
    try {
      setStatusLoading(data?.id);
      const payload: any = {
        id: data?.id,
        [currKey]: updateDetails?.value,
      };
      const res = await updateSubscriptions(payload).unwrap();

      const index = tableData?.findIndex(
        (item: { id: any }) => item?.id === data?.id
      );
      if (index > -1) {
        const newData = [...tableData];
        newData[index] = {
          ...newData[index],
          [currKey]: updateDetails?.value || 0,
        };
        dispatch(setSubscriptions({ ...tableData, data: newData }));
      }
      responseToaster(res);
      setStatusLoading(false); 
    } catch (error) {
      setStatusLoading(false);
    }
  };
  return (
    <>
      <div className={`${isOpen ? "cell-order-white" : "cell-order"}`}>
        <div>
          {isOpen ? (
            <form className="flex tableinput">
              <Input
                name={currKey}
                autoFocus
                type={!unlimited ? "number" : "text"}
                value={updateDetails?.value}
                onChange={(e) => {
                  const newValue = e.target.value;
                  setUpdateDetails({
                    ...updateDetails,
                    value: newValue,
                  });
                }}
              />

              {statusLoading ? (
                <Loader loaderClassName="w-[15px] ml-2" />
              ) : (
                <div className="flex gap-1 ml-1">
                  <Button
                    type="submit"
                    color="primary"
                    className="btn-smallsize"
                    onClick={(e) => {
                      e.preventDefault();
                      if (!/^\d+$/.test(updateDetails?.value) && !unlimited && !isDecimal) {
                        setError("must be number");
                        return;
                      }
                      
                      if (!/^(\d{0,2}(\.\d{1,2})?|100(\.00?)?)$/.test(updateDetails?.value) && !unlimited && isDecimal) {
                        setError("Neither a string nor exceeding 100");
                        return;
                      }
                      if (
                        !/^\d+$|^Unlimited$/.test(updateDetails?.value) &&
                        unlimited
                      ) {
                        setError("value either number or 'Unlimited'");
                        return;
                      }
                      onUpdate();
                    }}
                  >
                    <IoMdCheckmark color="var(--chatlook-primary--)" size={18} />
                  </Button>
                  <Button
                    color="danger"
                    className="btn-smallsize"
                    onClick={() => {
                      setIsOpen(false);
                      setUpdateDetails("");
                      setError("");
                    }}
                  >
                    <RxCross2 color="red" size={18}/>
                  </Button>
                </div>
              )}
            </form>
          ) : (
            <span
              className="cursor-pointer"
              style={{ color: "var(--ev-primary--)" }}
              onClick={() => {
                if(isDisable){
                  setIsOpen(true);
                  setUpdateDetails({
                    ...updateDetails,
                    value: data?.[currKey] || 0,
                  });
                }
              }}
            >
              {/^\d+$/.test(data?.[currKey])
                ? seperator(data?.[currKey], rupee)
                : data?.[currKey]}
            </span>
          )}
          {error ? <span className="text-red-500 text-sm">{error}</span> : ""}
        </div>
      </div>
    </>
  );
};

export default SubscriptionUpdate;
