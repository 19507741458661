import { Fragment, useEffect, useState } from "react";
import { FILTER, statusOptions, statusOptions1 } from "../../../constant";
import { Button, Icon, Input, Select, Table, TableRow } from "../../../ui";
import { setVendors, useVendorList } from "./vendorSlice";
import {
  useDeleteVendorMutation,
  useGetVendorMutation,
  useUpdateVendorMutation,
  useVendorMenuMutation,
} from "./vendorApi";
import { useDispatch } from "react-redux";
import { responseToaster, simpleDecrypt } from "../../../helperFunctions";
import Switch from "../../../ui/forms/Switch";
import { useForm } from "react-hook-form";

import CustomeBreadcrumbs from "../../common/CustomeBreadcrumbs";
import Tooltip from "../../../ui/elements/Tooltip";
import { MdDeleteOutline, MdEdit, MdOutlineNoteAlt } from "react-icons/md";
import VendorModal from "./VendorModal";
import { Modal } from "../../common";
import { setUser, setVendorId, useRole, useUser } from "../../auth/authSlice";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import NoteModal from "./NoteModal";

const breadcrumbs = [{ label: "Vendor" }, { label: "Vendor List" }];

const VendorList = () => {
  const [getVendor, { isLoading }] = useGetVendorMutation();
  const vendorList = useVendorList();
  const user = useUser();
  const [updateVendor] = useUpdateVendorMutation();
  const [vendorMenu] = useVendorMenuMutation();

  const [tableData, setTableData] = useState<any>([]);
  const [isVendorModal, setIsVendorModal] = useState<any>(false);
  const [vendorData, setVendorData] = useState<any>({});
  const [filter, setFilter] = useState<any>(FILTER);
  const [statusLoading, setStatusLoading] = useState(false);
  const [currKey, setCurrKey] = useState("");
  const [showDiv, setShowDiv] = useState(false);
  const [noteModal, setNoteModal] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [deleteVendor, { isLoading: isDeleteLoading }] =
    useDeleteVendorMutation();
  const [deleteData, setDeleteData] = useState<any>();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const decryptedKey = simpleDecrypt(searchParams?.get("keyId"), "bsproduct");
  const route = useLocation()?.pathname;

  const role = useRole("vendor");
  const vendorDetail = useRole("vendor-details");

  if (!role.includes("list")) {
    navigate("/");
  }

  const dispatch = useDispatch();

  const onUpdateStatus = async (payload: any, key?: any) => {
    try {
      setStatusLoading(payload?.id);
      setCurrKey(key);
      const res = await updateVendor(payload).unwrap();
      const index = tableData?.findIndex(
        (item: { id: any }) => item?.id === payload?.id
      );
      if (index > -1) {
        const newData = [...tableData];
        newData[index] = {
          ...newData[index],
          [key]: payload?.[key],
        };
        dispatch(setVendors({ ...vendorList, data: newData }));
      }
      responseToaster(res);
      setStatusLoading(false);
      setCurrKey("");
    } catch (err) {
      setStatusLoading(false);
      setCurrKey("");
    }
  };
  const onGetVendorMenu = async () => {
    try {
      const res: any = await vendorMenu().unwrap();
      const { data: originalData, ...rest } = user;
      if (res?.status) {
        const newData = {
          ...originalData,
          permission: res?.data?.permission,
          sidebar: res?.data?.sidebar,
        };

        dispatch(setUser({ ...rest, data: newData }));
        /*  setTimeout(() => {
          window.location.reload();
        }, 1000); */
      }
    } catch (err) {
      console.log("err", err);
    }
  };

  const columns: any = [
    {
      title: "Name",
      name: "name",
      sortable: true,
      sortableKey: "name",
    },
    {
      title: "Email",
      name: "email",
      sortable: true,
      sortableKey: "email",
    },
    {
      title: "Status",
      name: "status",
      Cell: (data: any) => (
        <Switch
          checked={data?.status === "active"}
          isLoading={statusLoading === data?.id && currKey === "status"}
          isDisabled={!role.includes("edit") || statusLoading}
          onChange={async (value: any, e: any) => {
            e.stopPropagation();
            try {
              const payload = {
                status: value ? "active" : "inactive",
                id: data?.id,
              };
              await onUpdateStatus(payload, "status");
            } catch (err) {}
          }}
        />
      ),
      sortable: true,
      sortableKey: "status",
    },
    {
      title: "Approved",
      name: "is_approved",
      Cell: (data: any) => (
        <Switch
          checked={data?.is_approved}
          isLoading={statusLoading === data?.id && currKey === "is_approved"}
          isDisabled={!role.includes("edit") || statusLoading}
          onChange={async (value: any, e: any) => {
            e.stopPropagation();
            try {
              const payload = {
                is_approved: value ? 1 : 0,
                id: data?.id,
              };
              await onUpdateStatus(payload, "is_approved");
            } catch (err) {}
          }}
        />
      ),
      sortable: true,
      sortableKey: "is_approved",
    },
    {
      title: "Auto Approved",
      name: "is_auto_approved",
      Cell: (data: any) => (
        <Switch
          checked={data?.is_auto_approved}
          isLoading={
            statusLoading === data?.id && currKey === "is_auto_approved"
          }
          isDisabled={!role.includes("edit") || statusLoading}
          onChange={async (value: any, e: any) => {
            e.stopPropagation();
            try {
              const payload = {
                is_auto_approved: value ? 1 : 0,
                id: data?.id,
              };
              await onUpdateStatus(payload, "is_auto_approved");
            } catch (err) {}
          }}
        />
      ),
      sortable: true,
      sortableKey: "is_auto_approved",
    },
    {
      title: "Store Name",
      name: "store_name",
      sortable: true,
      sortableKey: "store_name",
    },
    {
      title: "Pincode",
      name: "pin_code",
      sortable: true,
      sortableKey: "pin_code",
    },
    {
      title: "City",
      name: "city",
      sortable: true,
      sortableKey: "city.name",
      Cell: (data: any) => {
        return <span>{data?.city?.name || "-"}</span>;
      },
    },
    {
      title: "Action",
      name: "action",
      Cell: (data: any) => (
        <>
          <div className="flex gap-1">
            <Tooltip text="Note">
              <Button
                className="!h-8 !w-8 !p-0"
                onClick={(e: { stopPropagation: () => void }) => {
                  e.stopPropagation();
                  setNoteModal(data?.id);
                }}
                disabled={!role.includes("edit")}
              >
                <MdOutlineNoteAlt />
              </Button>
            </Tooltip>
            <Tooltip text="Edit">
              <Button
                className="!h-8 !w-8 !p-0"
                onClick={(e: { stopPropagation: () => void }) => {
                  e.stopPropagation();
                  navigate(`${route}/${data?.id}`);
                  // setIsVendorModal(true);
                  // setVendorData(data);
                }}
                disabled={!role.includes("edit")}
              >
                <MdEdit />
              </Button>
            </Tooltip>
            <Tooltip text="Delete" className="right-0">
              <Button
                className="!h-8 !w-8 !p-0"
                onClick={() => {
                  setIsDeleteModalOpen(true);
                  setDeleteData(data);
                }}
                disabled={!role.includes("delete")}
              >
                <MdDeleteOutline />
              </Button>
            </Tooltip>
          </div>
        </>
      ),
    },
  ];

  if (vendorDetail.includes("list")) {
    columns.unshift({
      title: "#",
      sortableKey: "id",
      Cell: (data: any) => {
        return (
          <div
            onClick={async () => {
              dispatch(
                setVendorId({
                  id: data?.id,
                  name: data?.name,
                  mail: data?.email,
                })
              );
              await onGetVendorMenu();
            }}
            className="cursor-pointer"
          >
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7.99995 10.8866C6.40661 10.8866 5.11328 9.59328 5.11328 7.99995C5.11328 6.40661 6.40661 5.11328 7.99995 5.11328C9.59328 5.11328 10.8866 6.40661 10.8866 7.99995C10.8866 9.59328 9.59328 10.8866 7.99995 10.8866ZM7.99995 6.11328C6.95995 6.11328 6.11328 6.95995 6.11328 7.99995C6.11328 9.03995 6.95995 9.88661 7.99995 9.88661C9.03995 9.88661 9.88661 9.03995 9.88661 7.99995C9.88661 6.95995 9.03995 6.11328 7.99995 6.11328Z"
                fill="black"
              />
              <path
                d="M8.00022 14.0134C5.49355 14.0134 3.12688 12.5467 1.50021 10C0.793548 8.90003 0.793548 7.10669 1.50021 6.00003C3.13355 3.45336 5.50022 1.98669 8.00022 1.98669C10.5002 1.98669 12.8669 3.45336 14.4935 6.00003C15.2002 7.10003 15.2002 8.89336 14.4935 10C12.8669 12.5467 10.5002 14.0134 8.00022 14.0134ZM8.00022 2.98669C5.84688 2.98669 3.78688 4.28003 2.34688 6.54003C1.84688 7.32003 1.84688 8.68003 2.34688 9.46003C3.78688 11.72 5.84688 13.0134 8.00022 13.0134C10.1535 13.0134 12.2135 11.72 13.6535 9.46003C14.1535 8.68003 14.1535 7.32003 13.6535 6.54003C12.2135 4.28003 10.1535 2.98669 8.00022 2.98669Z"
                fill="black"
              />
            </svg>
          </div>
        );
      },
    });
  }
  const onVendorDelete = async () => {
    try {
      const payload: any = {
        id: deleteData?.id,
      };
      const res = await deleteVendor(payload).unwrap();
      if (res) {
        setIsDeleteModalOpen(false);
        setDeleteData(false);
        setFilter({ ...filter, isFilter: false });
      }
      responseToaster(res);
    } catch (err) {
      console.error("Error =-=>", err);
    }
  };

  const getVendorList = async () => {
    try {
      const { search, status, is_auto_approved, is_approved, ...rest } = filter;
      const payload: any = {
        ...rest,
        filter_data: {
          search,
          status,
          is_approved,
          is_auto_approved,
          ...(decryptedKey === "bestSelling" && { type: "bestselling" }),
        },
      };
      delete payload["isFilter"];
      delete payload["isClear"];
      const res: any = await getVendor(payload);
      if (filter?.isFilter && !filter?.isClear) {
        responseToaster(res?.data);
      }
    } catch (error) {
      console.error("Error =-=>", error);
    }
  };

  const { register, handleSubmit, reset } = useForm<any>();

  useEffect(() => {
    setTableData(vendorList?.data?.length ? [...vendorList?.data] : []);
  }, [vendorList]);

  useEffect(() => {
    if (role.includes("list")) {
      getVendorList();
    }
    // eslint-disable-next-line
  }, [filter]);

  const onSubmit = async (values: any) => {
    setFilter({ ...FILTER, ...values, isFilter: true });
  };

  return (
    <>
      <div className="flex mb-3 gap-2 justify-between sm:flex-wrap">
        <CustomeBreadcrumbs breadcrumbs={breadcrumbs} />
        {role.includes("list") ? (
          <Button
            classNm={`!text-ev-white !border-0 appl-btn1`}
            onClick={() => {
              setShowDiv(!showDiv);
            }}
          >
            <Icon name="FilterIcon" />
          </Button>
        ) : null}
      </div>
      {showDiv ? (
        <div className="cardcss mb-3">
          <form
            className="flex gap-2 items-end sm:flex-wrap"
            onSubmit={handleSubmit(onSubmit)}
          >
            <Input
              name="search"
              label="Search"
              placeholder="Search by name or email"
              register={register}
            />

            <Select
              name="status"
              label="Status"
              placeholder="All"
              options={statusOptions}
              register={register}
            />

            <Select
              name="is_approved"
              label="Approved"
              placeholder="All"
              options={statusOptions1}
              register={register}
            />

            <Select
              name="is_auto_approved"
              label="Auto Approved"
              placeholder="All"
              options={statusOptions1}
              register={register}
            />

            <Button type="submit" className="!text-sm">
              Apply
            </Button>
            <Button
              variant="Transaparent"
              className="!text-sm"
              onClick={() => {
                reset();
                setFilter({ ...FILTER, isFilter: true, isClear: true });
              }}
            >
              Clear
            </Button>
          </form>
        </div>
      ) : null}
      {role.includes("list") ? (
        <div className="cardcss">
          <Table
            columns={columns}
            isExpendable={false}
            isLoading={isLoading}
            data={tableData || []}
            setData={setTableData}
            count={vendorList?.total_item || 0}
            pagination={filter}
            handlePaginationChange={(pagination) => {
              setFilter({
                ...filter,
                ...pagination,
                isFilter: false,
              });
            }}
          >
            {tableData?.map((item: any, index: number) => {
              return (
                <Fragment key={index}>
                  <TableRow
                    columns={columns}
                    isExpendable={false}
                    item={item}
                    onClick={(item: any) => {}}
                  ></TableRow>
                </Fragment>
              );
            })}
          </Table>
        </div>
      ) : null}
      <VendorModal
        isOpen={isVendorModal}
        onHide={() => {
          setIsVendorModal(false);
          setVendorData({});
        }}
        vendorData={vendorData}
        onGet={getVendorList}
      />
      <NoteModal
        isOpen={noteModal}
        onHide={() => {
          setNoteModal(false);
        }}
      />
      <Modal
        isOpen={!!isDeleteModalOpen}
        isLoading={isDeleteLoading}
        doneText="Delete"
        content={
          <div className="flex flex-col gap-2">
            <p>Are you sure you want to delete this?</p>
          </div>
        }
        onDoneClick={onVendorDelete}
        onHide={() => {
          setIsDeleteModalOpen(false);
        }}
      />
    </>
  );
};

export default VendorList;
