import React from "react";
import ThreeDotsLoader from "../../ui/elements/ThreeDotsLoader";
import { useNavigate } from "react-router-dom";
import { useVendorId } from "../auth/authSlice";

const StatCard = ({
  label,
  value,
  icon,
  bgColor,
  summaryLoading,
  dataKey,
}: any) => {
  const hasVendorId = useVendorId();
  const redirectPath: any = {
    ...(!hasVendorId?.id && { total_vendor: "/vendor/vendor/get-vendor" }),
    ...(!hasVendorId?.id && { total_user: "/user/get-user" }),
    total_orders: "/order/get-order",
  };
  const navigate = useNavigate();
  return (
    <div
      className={`flex items-center cursor-pointer justify-between bg-white border border-gray-200 rounded-lg p-4 shadow-sm w-full hover:shadow-md transition-shadow duration-300 ease-in-out`}
      onClick={() => {
        navigate(redirectPath?.[dataKey]);
      }}
    >
      <div className="flex flex-col">
        <span className="text-gray-500 text-sm font-medium">{label}</span>
        {summaryLoading ? (
          <ThreeDotsLoader className="mt-4" />
        ) : (
          <span className="text-3xl font-bold">{value}</span>
        )}
      </div>

      <div
        className={`p-2 rounded-full flex items-center justify-center ${bgColor}`}
      >
        <img src={icon} alt={label} className="h-10 w-10" />
      </div>
    </div>
  );
};

export default StatCard;
