import React from "react";
import {
  prepareImageUrl,
  renderStarss,
  simpleEncrypt,
} from "../../helperFunctions";
import dummyImage from "../../assets/images/dummy-image.svg";
import { Link, useNavigate } from "react-router-dom";
import ThreeDotsLoader from "../../ui/elements/ThreeDotsLoader";

const TopSellingProducts = ({ topSellingProducts, summaryLoading }: any) => {
  const navigate = useNavigate();
  return (
    <>
      <div className="flex justify-between items-center pb-4">
        <h2 className="text-lg font-semibold text-gray-800">
          Top Selling Products
        </h2>
        <Link
          to={`/master/product/get-product?keyId=${simpleEncrypt(
            "bestSelling",
            "bsproduct"
          )}`}
          className="text-ev-primary text-sm"
        >
          View All
        </Link>
      </div>

      <div className="space-y-4 h-[500px] overflow-y-scroll hidden_scroll">
        {summaryLoading ? (
          <ThreeDotsLoader />
        ) : topSellingProducts?.length ? (
          topSellingProducts?.map?.((product: any, index: number) => (
            <div
              key={index}
              className="flex items-center justify-between p-3 bg-gray-50 rounded-lg shadow-sm cursor-pointer"
              onClick={()=>{
                navigate(`/master/product/get-product/${product?.id}`)
              }}
            >
              <div className="flex items-center">
                <div className="h-12 w-12 rounded-lg overflow-hidden bg-gray-200 mr-4">
                  <img
                    src={
                      product?.photo
                        ? prepareImageUrl(product?.photo?.split?.(",")?.[0])
                            ?.url
                        : dummyImage
                    }
                    alt={product.title}
                    className="h-full w-full object-cover"
                    onError={(e: any) => {
                      e.target.onerror = null;
                      e.target.src = dummyImage;
                      e.target.width = 40;
                      e.target.height = 40;
                    }}
                  />
                </div>
                <p className="text-gray-700">{product?.title}</p>
              </div>
              <div className="font-bold px-3 py-1 rounded-md">
                <span className="flex">
                  {renderStarss(
                    product?.rating_count,
                    undefined,
                    "var(--ev-GrayishBlue--)"
                  )}
                </span>
              </div>
            </div>
          ))
        ) : (
          "No Record Found"
        )}
      </div>
    </>
  );
};

export default TopSellingProducts;
