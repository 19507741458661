import { api } from "../../../utills/api";

export const salesProductReportApi = api.injectEndpoints({
  endpoints: (build) => ({
    getSalesProductReport: build.mutation<{ data: any }, void>({
      query: (params) => ({
        url: "/admin/report/get-sales-report",
        body: params,
        method: "POST",
      }),
    }),
    getSalesProductReportForChart: build.mutation<{ data: any }, void>({
      query: (params) => ({
        url: "/admin/report/get-sales-report",
        body: params,
        method: "POST",
      }),
    }),
  }),
});

export const {
  useGetSalesProductReportMutation,
  useGetSalesProductReportForChartMutation,
} = salesProductReportApi;
export const {
  endpoints: { getSalesProductReport, getSalesProductReportForChart },
} = salesProductReportApi;
