import React, { Fragment, useEffect, useState } from "react";
import {
  Button,
  ControlledDatePicker,
  Icon,
  Select,
  Table,
  TableRow,
} from "../../ui";
import { FILTER } from "../../constant";
import { useDeleteCouponMutation, useGetCouponMutation } from "./couponApi";
import {
  setCurrTab,
  useCouponList,
  useCurrentTab,
  useVendorCouponList,
} from "./couponSlice";
import CouponModal from "./CouponModal";
import { MdEdit } from "react-icons/md";
import { MdDeleteOutline } from "react-icons/md";
import { Modal } from "../common";
import {
  dateFormatter,
  prepareVendorOptions,
  responseToaster,
  seperator,
} from "../../helperFunctions";
import Tooltip from "../../ui/elements/Tooltip";
import { useForm } from "react-hook-form";
import { IoEye } from "react-icons/io5";
import { useDispatch } from "react-redux";
import CustomeBreadcrumbs from "../common/CustomeBreadcrumbs";
import { useNavigate } from "react-router-dom";
import { useRole } from "../auth/authSlice";

interface CouponTabs {
  label: string;
  Component?: any;
}

const initialValues = {
  startDate: [null, null],
};
const Coupon = () => {
  const [getCoupon, { isLoading }] = useGetCouponMutation();
  const couponList = useCouponList();
  const couponVendorList = useVendorCouponList();
  const [deleteCoupon, { isLoading: isDeleteLoading }] =
    useDeleteCouponMutation();
  const currentTab = useCurrentTab();
  const [isCouponModal, setIsCouponModal] = useState(false);
  const [tableData, setTableData] = useState<any>([]);
  const [filter, setFilter] = useState<any>(FILTER);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [deleteData, setDeleteData] = useState<any>();
  const [couponData, setCouponData] = useState({});
  const [showDiv, setShowDiv] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // const role = useRole("coupon");

  const couponRole = useRole("coupon");
  const vendorDetailsRole = useRole("vendor-details");

  const role = [...couponRole, ...vendorDetailsRole];

  if (!role.includes("list")) {
    navigate("/");
  }
  const breadcrumbs = [
    { label: "Master" },
    { label: "Coupon" },
    { label: currentTab },
  ];
  const handleAddCoupon = () => {
    setIsCouponModal(true);
  };

  const columns = [
    {
      title: "ID",
      name: "id",
      sortable: true,
      sortableKey: "id",
    },
    {
      title: "Vendor Name",
      name: "vendor_name",
      sortable: true,
      sortableKey: "vendor.name",
      showColumn: currentTab === "Vendor",
      Cell: (data: any) => {
        return <span>{data?.vendor?.name || "-"}</span>;
      },
    },
    {
      title: "Code",
      name: "code",
      sortable: true,
      sortableKey: "code",
    },
    {
      title: "Coupon Amount",
      name: "coupon_amount",
      sortable: true,
      sortableKey: "coupon_amount",
      Cell: (data: any) => {
        return (
          <span>
            {data?.discount_type === "fixed"
              ? seperator(data?.coupon_amount)
              : `${data?.coupon_amount}%`}
          </span>
        );
      },
    },
    {
      title: "Discount Type",
      name: "discount_type",
      sortable: true,
      sortableKey: "discount_type",
    },
    {
      title: "Amount",
      name: "minimum_spend",
      sortable: true,
      sortableKey: "minimum_spend",
      Cell: (data: any) => {
        return (
          <div className="flex flex-col">
            <span>Minimum : {seperator(data?.minimum_spend)}</span>
            <span>Maximum : {seperator(data?.maximum_spend)}</span>
          </div>
        );
      },
    },
    {
      title: "Date",
      name: "created_at_ist",
      sortable: true,
      sortableKey: "created_at_ist",
      type: "date",
    },
    {
      title: "Coupon Expiry Date",
      name: "coupon_expiry_date",
      type: "date",
      sortable: true,
      sortableKey: "coupon_expiry_date",
      Cell: (item: any) => {
        return <span>{dateFormatter(item?.coupon_expiry_date)}</span>;
      },
    },
    {
      title: "Action",
      name: "action",
      Cell: (item: any) => {
        return (
          <div className="flex items-center gap-2">
            {currentTab !== "Vendor" ? (
              <Tooltip text="Edit">
                <Button
                  className="!h-8 !w-8 !p-0"
                  onClick={() => {
                    setIsCouponModal(true);
                    setCouponData(item);
                  }}
                  disabled={!role.includes("edit")}
                >
                  <MdEdit />
                </Button>
              </Tooltip>
            ) : (
              <Tooltip text="Preview">
                <Button
                  className="!h-8 !w-8 !p-0"
                  onClick={() => {
                    setIsCouponModal(true);
                    setCouponData(item);
                  }}
                >
                  <IoEye />
                </Button>
              </Tooltip>
            )}
            <Tooltip text="Delete" className="right-0">
              <Button
                className="!h-8 !w-8 !p-0"
                onClick={() => {
                  setIsDeleteModalOpen(true);
                  setDeleteData(item);
                }}
                disabled={!role.includes("delete")}
              >
                <MdDeleteOutline />
              </Button>
            </Tooltip>
          </div>
        );
      },
    },
  ];
  const couponTab: CouponTabs[] = [
    { label: "Admin", Component: Coupon },
    { label: "Vendor", Component: Coupon },
  ];

  const onCouponDelete = async () => {
    try {
      const payload: any = {
        id: deleteData?.id,
      };
      const res = await deleteCoupon(payload).unwrap();
      if (res) {
        setIsDeleteModalOpen(false);
        setDeleteData(false);
        setFilter({ ...filter, isFilter: false });
      }
      responseToaster(res);
    } catch (err) {
      console.error("Error =-=>", err);
    }
  };

  const getCouponList = async () => {
    try {
      const { startDate, vendor_id = 0, ...rest } = filter;
      const payload: any = {
        ...rest,
        filter_data: {
          start_date: dateFormatter(startDate?.[0], "start"),
          end_date: dateFormatter(startDate?.[1], "end", startDate?.[0]),
        },
      };
      if (currentTab !== "Vendor") {
        payload.vendor_id = 0;
      } else if (currentTab === "Vendor" && vendor_id) {
        payload.vendor_id = vendor_id;
      }
      delete payload["isFilter"];
      delete payload["isClear"];
      const res: any = await getCoupon(payload);
      if (filter?.isFilter && !filter?.isClear) {
        responseToaster(res?.data);
      }
    } catch (error) {
      console.error("Error =-=>", error);
    }
  };

  const { register, handleSubmit, reset, control } = useForm<any>({
    defaultValues: {
      ...initialValues,
    },
  });

  useEffect(() => {
    if (role.includes("list")) {
      getCouponList();
    }
    // eslint-disable-next-line
  }, [filter, currentTab]);

  useEffect(() => {
    if (currentTab === "Admin") {
      setTableData(couponList?.data?.length ? [...couponList?.data] : []);
    } else {
      setTableData(
        couponVendorList?.data?.length ? [...couponVendorList?.data] : []
      );
    }
  }, [couponList, couponVendorList, currentTab]);

  const onSubmit = async (values: any) => {
    setFilter({ ...FILTER, ...values, isFilter: true });
  };

  return (
    <>
      <div>
        <div className="flex mb-3 gap-2 justify-between sm:flex-wrap">
          <CustomeBreadcrumbs breadcrumbs={breadcrumbs} />
          <div className="flex gap-2 justify-end">
            {role.includes("list") ? (
              <Button
                classNm={`!text-ev-white !border-0 appl-btn1`}
                onClick={() => {
                  setShowDiv(!showDiv);
                }}
                
              >
                <Icon name="FilterIcon" />
              </Button>
            ) : null}
            {currentTab !== "Vendor" && role.includes("create") ? (
              <div className="flex justify-end">
                <Button onClick={() => handleAddCoupon()}>Add Coupon</Button>
              </div>
            ) : null}
          </div>
        </div>

        {showDiv && (
          <div className="cardcss mb-3 flex">
            <form
              className="flex gap-2 items-end"
              onSubmit={handleSubmit(onSubmit)}
            >
              {currentTab === "Vendor" ? (
                <Select
                  name="vendor_id"
                  label="Vendor"
                  placeholder="Select Vendor"
                  options={prepareVendorOptions(couponList?.vendorsList)}
                  register={register}
                />
              ) : null}
              <ControlledDatePicker
                name="startDate"
                label="Coupon Expiry Date"
                placeholder="Select Coupon Expiry Date"
                selectsRange
                control={control}
              />
              <Button type="submit" className="!text-sm">
                Apply
              </Button>
              <Button
                variant="Transaparent"
                className="!text-sm"
                onClick={() => {
                  reset();
                  setFilter({ ...FILTER, isFilter: true, isClear: true });
                }}
              >
                Clear
              </Button>
            </form>
          </div>
        )}

        {role.includes("list") ? (
          <div className="cardcss">
            <div className="flex mb-3 gap-4 justify-between border-b-2 p-1 ">
              <div className="flex gap-2 pb-1 sm:flex-wrap">
                {couponTab?.map((tab, index) => {
                  return (
                    <div
                      key={index}
                      className={` px-6 h-9 flex items-center justify-center cursor-pointer tab-transition ${
                        currentTab !== tab?.label ? " " : "active"
                      }`}
                      onClick={() => {
                        setFilter({
                          ...FILTER,
                        });
                        reset();
                        dispatch(setCurrTab(tab?.label));
                      }}
                    >
                      {tab?.label}
                    </div>
                  );
                })}
              </div>
            </div>

            <Table
              columns={columns}
              isExpendable={false}
              isLoading={isLoading}
              data={tableData || []}
              setData={setTableData}
              count={
                currentTab === "Admin"
                  ? couponList?.total_item
                  : currentTab === "Vendor"
                  ? couponVendorList?.total_item
                  : 0
              }
              pagination={filter}
              handlePaginationChange={(pagination) => {
                setFilter({
                  ...filter,
                  ...pagination,
                  isFilter: false,
                });
              }}
            >
              {tableData?.map((item: any, index: number) => {
                return (
                  <Fragment key={index}>
                    <TableRow
                      columns={columns}
                      isExpendable={false}
                      item={item}
                      onClick={(item: any) => {}}
                    ></TableRow>
                  </Fragment>
                );
              })}
            </Table>
          </div>
        ) : null}
      </div>
      <CouponModal
        currentTab={currentTab}
        isOpen={isCouponModal}
        onGet={getCouponList}
        couponData={couponData}
        onHide={() => {
          setIsCouponModal(false);
          setCouponData({});
        }}
      />
      <Modal
        isOpen={!!isDeleteModalOpen}
        isLoading={isDeleteLoading}
        doneText="Delete"
        content={
          <div className="flex flex-col gap-2">
            <p>Are you sure you want to delete this?</p>
          </div>
        }
        onDoneClick={onCouponDelete}
        onHide={() => {
          setIsDeleteModalOpen(false);
        }}
      />
    </>
  );
};

export default Coupon;
