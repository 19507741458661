import ReactPaginate from "react-paginate";
import { Select } from "../forms";
import { limitOptions } from "../../features/common/mock";
import { LIMIT } from "../../constant";

interface PaginationProps {
  className?: string;
  page_no?: number;
  count?: number;
  limit?: number;
  data?: any[];
  handlePageChange?: (page: number) => void;
  handleLimitChange?: (limit: number) => void;
  hasInTable?: boolean;
  showLimit?: boolean;
}
const Pagination = (props: PaginationProps) => {
  const {
    className = "",
    page_no = 1,
    count = 0,
    limit = LIMIT,
    data,
    handlePageChange,
    handleLimitChange,
    hasInTable = true,
    showLimit = true,
  } = props;

  const totalPageCount = Math.ceil(count / limit);

  return (
    <div
      className={`${
        !hasInTable
          ? "z-49 flex bottom-4 right-0 left-4 items-center justify-end bg-white px-2"
          : "z-49 flex absolute bottom-4 right-4 left-0 items-center justify-between bg-white px-2"
      }`}
    >
      {showLimit ? (
        <div>
          <Select
            className="h-8 w-16 text-sm"
            name="limit"
            options={limitOptions}
            disabled={!data?.length}
            isDefaultOption={true}
            value={limit}
            onChange={(e: any) => {
              handleLimitChange?.(+e.target.value);
            }}
          />
        </div>
      ) : null}
      <div className="pagination-ul">
        <ReactPaginate
          className={`${className} flex whitespace-nowrap gap-3 text-xs`}
          activeClassName="h-8 w-8 flex items-center justify-center rounded-full text-ev-Tertiary bg-chatlook-gray text-xs"
          previousClassName="border border-1 border-lightblue rounded-lg p-2 text-ev-secondary text-xs"
          nextClassName="border border-1 border-lightblue rounded-lg p-2 text-ev-secondary text-xs"
          breakLabel="..."
          nextLabel="Next >"
          forcePage={+page_no - 1}
          onPageChange={(e) => {
            handlePageChange?.(+e.selected + 1);
          }}
          pageRangeDisplayed={0}
          pageCount={totalPageCount}
          previousLabel="< Previous"
          renderOnZeroPageCount={null}
        />
      </div>
    </div>
  );
};

export default Pagination;
