import { Fragment, useEffect } from "react";

// UI IMPORT
import { Icon } from "../../ui";

// PROJECT IMPORT
import { toggleDrawer, useIsDrawerOpen } from "../common/commonSlice";
import { useAppDispatch } from "../../utills/hooks";
import { useUser } from "../auth/authSlice";
import { useMedia } from "../../components";
import SideBarMenu from "./SideBarMenu";

const innerLogo: any = process.env.REACT_APP_INNER_LOGO;

const Sidebar = () => {
  const user = useUser();

  const isDrawerOpen = useIsDrawerOpen();
  const { media } = useMedia();

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(toggleDrawer(media.width < 992 ? false : true));
    // eslint-disable-next-line
  }, [media]);
  return (
    <>
      <div className="mainSidebar">
        <div
          className={`sideBar whitespace-nowrap ${
            isDrawerOpen ? "w-[220px]" : "w-[75px]"
          } ${media.width < 768 && !isDrawerOpen ? "hidden" : ""}`}
        >
          <div className={`${isDrawerOpen ? "sideBarLogo" : "sideBarLogo1"}`}>
            {isDrawerOpen ? (
              <div className="flex justify-between">
                {/* <Image name="Logo" className="w-[170px]"  /> */}
                <img
                  src={`data:image/svg+xml;utf8,${encodeURIComponent(
                    innerLogo
                  )}`}
                  alt="Logo"
                />
                <div className="menuposition">
                  <Icon
                    name="MenuBarIcon"
                    onClick={() => {
                      dispatch(toggleDrawer());
                    }}
                  />
                </div>
              </div>
            ) : (
              <div className="flex justify-center">
                <div className="bg-chatlook-white rounded-full p-[5px]">
                  <Icon
                    name="MenuBarIcon"
                    onClick={() => {
                      dispatch(toggleDrawer());
                    }}
                  />
                </div>
              </div>
            )}
          </div>

          {/* ======= Navigation ======= */}
          <div className="navigation mt-2">
            <nav className="px-3 pb-8">
              {Object.entries(user?.data?.sidebar || {})?.map?.(
                ([k, item]: any, index: number) => {
                  /* if(item?.route === "/rating-review-list"){
                    return <></>
                  } */
                  return (
                    <Fragment key={index}>
                      {Array.isArray(item) ? (
                        <SideBarMenu
                          name={k?.split?.("_")?.[1]}
                          route={`/${k?.split?.("_")?.[1]?.toLowerCase()}`}
                          icon_class={`${k?.split?.("_")?.[1]}Icon`}
                          onClick={() => {
                            if (media.width < 768) {
                              dispatch(toggleDrawer(false));
                            }
                          }}
                          hasSubmenu={!!item?.length}
                          submenu={item}
                          // parentRoute={`${k.toLowerCase()}`}
                        />
                      ) : (
                        <SideBarMenu
                          name={item?.name}
                          route={`${item?.route?.toLowerCase()}`}
                          icon_class={item?.icon_class}
                          onClick={() => {
                            if (media.width < 768) {
                              dispatch(toggleDrawer(false));
                            }
                          }}
                          hasSubmenu={!!item?.length}
                          submenu={item}
                          // parentRoute={`${k.toLowerCase()}`}
                        />
                      )}
                    </Fragment>
                  );
                }
              )}
            </nav>
          </div>
        </div>
      </div>
    </>
  );
};

export default Sidebar;
