import SettlementList from "./SettlementList";
import TransactionList from "./TransactionList";
import { setCurrTab, useCurrentTab } from "./settlementSlice";
import { useDispatch } from "react-redux";
import CustomeBreadcrumbs from "../common/CustomeBreadcrumbs";
import { useNavigate } from "react-router-dom";
import { useRole } from "../auth/authSlice";

const Settlement = () => {
  const currentTab = useCurrentTab();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const settlementRole = useRole("settlement");
  const vendorDetailsRole = useRole("vendor-details");

  const role = [...settlementRole, ...vendorDetailsRole];

  const settlementTab: any = [
    { label: "Settlement", Component: SettlementList },
    { label: "TransactionList", Component: TransactionList },
  ];
  const breadcrumbs = [
    { label: "Settlement" },
    { label: settlementTab?.[currentTab]?.label },
  ];
  const Component = settlementTab?.[currentTab]?.Component;

  if (!role.includes("list")) {
    navigate("/");
  }

  return (
    <>
      <div className="flex mb-3 gap-2 justify-between items-center">
        <CustomeBreadcrumbs breadcrumbs={breadcrumbs} />
      </div>
      {Component ? (
        <div className="cardcss">
          <div className="flex mb-3 justify-between border-b-2 p-1 ">
            <div className="flex gap-2 pb-1">
              {settlementTab?.map((tab: any, index: number) => {
                return (
                  <div
                    key={index}
                    className={` px-6 h-9 flex items-center justify-center cursor-pointer tab-transition ${
                      currentTab !== index ? " " : "active"
                    }`}
                    onClick={() => {
                      dispatch(setCurrTab(index));
                    }}
                  >
                    {tab?.label}
                  </div>
                );
              })}
            </div>
          </div>
          <Component />
        </div>
      ) : null}
    </>
  );
};

export default Settlement;
