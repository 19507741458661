import React, { Fragment, useEffect, useState } from "react";
import { FILTER } from "../../../constant";
import {
  Button,
  ControlledDatePicker,
  Icon,
  Select,
  Table,
  TableRow,
} from "../../../ui";
import { useGetSalesReportMutation } from "./salesReportApi";
import {
  dateFormatter,
  prepareImageUrl,
  prepareVendorOptions,
  responseToaster,
  seperator,
} from "../../../helperFunctions";
import CustomeBreadcrumbs from "../../common/CustomeBreadcrumbs";
import dummyImage from "../../../assets/images/dummy-image.svg";
import { useForm } from "react-hook-form";
import { useRole } from "../../auth/authSlice";
import { useNavigate } from "react-router-dom";
import { useSalesReportList } from "./salesReportSlice";

const breadcrumbs = [{ label: "Report" }, { label: "Sales Product Report" }];

const initialDate = () => {
  const startDate = new Date();
  startDate.setDate(1);
  return {
    startDate: [startDate, new Date()],
  };
};

const SalesReport = () => {
  const [getSalesReport, { isLoading }] = useGetSalesReportMutation();
  const salesReportList = useSalesReportList();
  const [tableData, setTableData] = useState<any>([]);
  const startDate = new Date();
  startDate.setDate(1);
  const initialFilterState = {
    ...FILTER,
    startDate: [startDate, new Date()],
  };
  const [filter, setFilter] = useState<any>(initialFilterState);
  const [showDiv, setShowDiv] = useState(false);
  const initialValues = {
    ...initialDate(),
  };
  const navigate = useNavigate();

  const settlementRole = useRole("sales-product");
  const vendorDetailsRole = useRole("vendor-details");

  const role = [...settlementRole, ...vendorDetailsRole];

  if (!role.includes("list") && !role.includes("report")) {
    navigate("/");
  }

  const columns = [
    {
      title: "Id",
      name: "product_id",
      showIndex: true,
    },
    {
      title: "Order Id",
      name: "ord_id",
      sortable: true,
      sortableKey: "order.ord_id",
      Cell: (item: any, index: any) => {
        return item?.order?.ord_id || "-";
      },
    },
    {
      title: "Product Info",
      name: "productInfo",
      sortable: true,
      sortableKey: "product.title",
      Cell: (product: any) => {
        return (
          <div
            className="flex items-center cursor-pointer"
            onClick={() => {
              navigate(`/master/product/get-product/${product?.product_id}`);
            }}
          >
            <div className="h-12 w-12 rounded-lg overflow-hidden bg-gray-200 mr-4">
              <img
                src={
                  product?.product?.photo
                    ? prepareImageUrl(
                        product?.product?.photo?.split?.(",")?.[0]
                      )?.url
                    : dummyImage
                }
                alt={product?.product?.title}
                className="h-full w-full object-cover"
                onError={(e: any) => {
                  e.target.onerror = null;
                  e.target.src = dummyImage;
                  e.target.width = 40;
                  e.target.height = 40;
                }}
              />
            </div>
            <p className="text-ev-primary">{product?.product?.title}</p>
          </div>
        );
      },
    },
    {
      title: "Quantity",
      name: "quantity",
      sortable: true,
      sortableKey: "quantity",
    },
    {
      title: "price",
      name: "price",
      sortable: true,
      sortableKey: "product.price",
      Cell: (item: any) => {
        return <div>{seperator(item?.product?.price, true)}</div>;
      },
    },
    {
      title: "Discount Price",
      name: "discount_price",
      sortable: true,
      sortableKey: "discount_price",
      Cell: (item: any) => {
        return (
          <div>
            {item?.discount_price === 0 || item?.discount_price === null
              ? "-"
              : seperator(item?.discount_price, true)}
          </div>
        );
      },
    },
    {
      title: "Total",
      name: "total",
      sortable: true,
      sortableKey: "total",
      Cell: (item: any) => {
        return <div>{seperator(item?.total, true)}</div>;
      },
    },
  ];

  const getSalesReportList = async () => {
    try {
      const { startDate, vendor_id, order_id, status, ...rest } = filter;

      const payload: any = {
        ...rest,
        filter_data: {
          start_date: dateFormatter(startDate?.[0], "start"),
          end_date: dateFormatter(startDate?.[1], "end", startDate?.[0]),
          vendor_id,
          status,
          order_id,
        },
      };
      delete payload["isFilter"];
      delete payload["isClear"];
      const res: any = await getSalesReport(payload);
      if (filter?.isFilter && !filter?.isClear) {
        responseToaster(res?.data);
      }
    } catch (error) {
      console.error("Error =-=>", error);
    }
  };
  const { register, handleSubmit, reset, control } = useForm<any>({
    defaultValues: {
      ...initialValues,
    },
  });

  useEffect(() => {
    setTableData(
      salesReportList?.data?.length ? [...salesReportList?.data] : []
    );
  }, [salesReportList]);

  useEffect(() => {
    if (role.includes("list") || role.includes("report")) {
      getSalesReportList();
    }
    // eslint-disable-next-line
  }, [filter]);

  const onSubmit = async (values: any) => {
    setFilter({ ...FILTER, ...values, isFilter: true });
  };
  return (
    <>
      <div className="flex mb-3 gap-2 justify-between sm:flex-wrap">
        <CustomeBreadcrumbs breadcrumbs={breadcrumbs} />
        <Button
          classNm={`!text-ev-white !border-0 appl-btn1`}
          onClick={() => {
            setShowDiv(!showDiv);
          }}
        >
          <Icon name="FilterIcon" />
        </Button>
      </div>
      {showDiv ? (
        <div className="cardcss mb-3 flex">
          <form
            className="flex gap-2 sm:flex-wrap items-end"
            onSubmit={handleSubmit(onSubmit)}
          >
            <Select
              name="vendor_id"
              label="Vendor"
              placeholder="Select Vendor"
              options={prepareVendorOptions(salesReportList?.vendorsList)}
              register={register}
            />

            <ControlledDatePicker
              name="startDate"
              label="Sales Product Report Date"
              placeholder="Sales Product Report Date"
              selectsRange
              control={control}
              maxDate={new Date()}
            />
            <Button type="submit" className="!text-sm">
              Apply
            </Button>
            <Button
              variant="Transaparent"
              className="!text-sm"
              onClick={() => {
                reset();
                setFilter({
                  ...initialFilterState,
                  isFilter: true,
                  isClear: true,
                });
              }}
            >
              Clear
            </Button>
          </form>
        </div>
      ) : null}
      <div className="grid grid-cols-3 gap-4 sm:grid-cols-1 lg:grid-cols-3 mb-3">
        {[
          {
            label: "Total orders",
            value: salesReportList?.summary?.total_orders || 0,
          },
          {
            label: "Total Quantity",
            value: salesReportList?.summary?.total_quantity || 0,
          },
          {
            label: "Total Amount",
            value: salesReportList?.summary?.total_amount || 0,
          },
        ].map((item, index) => (
          <div
            key={index}
            className="flex justify-between items-center bg-chatlook-white p-4 rounded-lg shadow-md"
          >
            <span className="text-chatlook-secondary font-medium">
              {item.label}
            </span>
            <span className="text-ev-primary font-bold text-lg">
              {seperator(item?.value, item?.label === "Total Amount")}
            </span>
          </div>
        ))}
      </div>

      <div className="cardcss">
        <Table
          columns={columns}
          isExpendable={false}
          isLoading={isLoading}
          data={tableData || []}
          setData={setTableData}
          count={salesReportList?.total_item || 0}
          pagination={filter}
          handlePaginationChange={(pagination) => {
            setFilter({
              ...filter,
              ...pagination,
              isFilter: false,
            });
          }}
        >
          {tableData?.map((item: any, index: number) => {
            return (
              <Fragment key={index}>
                <TableRow
                  columns={columns}
                  isExpendable={false}
                  item={item}
                  showInd={index}
                  onClick={(item: any) => {}}
                ></TableRow>
              </Fragment>
            );
          })}
        </Table>
      </div>
    </>
  );
};

export default SalesReport;
