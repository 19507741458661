import React, { useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { useOnChartDataMutation } from "./dashboardApi";
import { dateFormatter, getCurrentYearDates } from "../../helperFunctions";
import DatePicker1 from "../../ui/forms/DatePicker1";
import ThreeDotsLoader from "../../ui/elements/ThreeDotsLoader";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const EarningsChart = ({ filter }: any) => {
  const { startOfYear, endOfYear } = getCurrentYearDates();
  const [onChartData, { isLoading }] = useOnChartDataMutation();
  const [selectedRange, setSelectedRange] = useState<any>([
    startOfYear,
    endOfYear,
  ]);
  const [chartData, setChartData] = useState<any>(null);

  const data = {
    labels: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ],
    datasets: [],
  };

  const options: any = {
    responsive: true,
    plugins: {
      legend: {
        labels: {
          generateLabels: (chart: any) => {
            const labels =
              ChartJS.defaults.plugins.legend.labels.generateLabels(chart);
            return labels.map((label) => ({
              ...label,
              text: label.text.charAt(0).toUpperCase() + label.text.slice(1),
            }));
          },
        },
        position: "top",
        maintainAspectRatio: false,
      },
      tooltip: {
        callbacks: {
          label: function (tooltipItem: any) {
            return `${tooltipItem.raw} ${tooltipItem.dataset?.label}`;
          },
        },
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
      },
      y: {
        grid: {
          display: false,
        },
      },
    },
  };

  useEffect(() => {
    getOnChartData();
    // eslint-disable-next-line
  }, [selectedRange, filter]);

  const getOnChartData = async () => {
    try {
      const { vendor_id } = filter;
      const payload: any = {
        start_date: dateFormatter(selectedRange?.[0], "start"),
        end_date: dateFormatter(selectedRange?.[1], "end", selectedRange?.[0]),
        vendor_id,
      };

      const res: any = await onChartData(payload);
      if (res?.data?.data?.data?.labels && res?.data?.data?.data?.datasets) {
        const updatedData = {
          labels: res.data?.data?.data?.labels || [],
          datasets:
            res.data?.data?.data?.datasets.map((dataset: any) => ({
              ...dataset,
              borderColor: dataset?.backgroundColor,
              borderWidth: 2,
              fill: false,
              tension: 0.5,
            })) || [],
        };
        setChartData(updatedData);
      }
    } catch (error) {
      console.error("Error =-=>", error);
    }
  };

  const handleDateChange = ([newStartDate, newEndDate]: any) => {
    if (!newStartDate && !newEndDate) {
      setSelectedRange([null, null]);
      return;
    }

    const clonesNewSdate = newStartDate || new Date();

    const startOfYear = new Date(clonesNewSdate.getFullYear(), 0, 1);

    let endOfYear = new Date(clonesNewSdate.getFullYear(), 11, 31);

    const customEndDate = new Date();

    if (endOfYear > customEndDate) {
      endOfYear = customEndDate;
    }

    setSelectedRange([startOfYear, endOfYear]);
  };

  return (
    <>
      <div className="flex justify-end">
        <DatePicker1
          selected={selectedRange[0]}
          startDate={selectedRange[0]}
          endDate={selectedRange[1]}
          maxDate={new Date()}
          selectsRange
          isKeyDown={false}
          onChange={handleDateChange}
          showYearPicker
          // dateFormat="yyyy"
          showIcon
        />
      </div>
      <div className="mt-4 w-100 chartContainer flex justify-center items-center">
        {isLoading ? (
          <ThreeDotsLoader />
        ) : (
          <Line data={chartData || data} options={options} />
        )}
      </div>
    </>
  );
};

export default EarningsChart;
