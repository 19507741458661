import React, { useEffect, useState } from "react";
import StatCard from "./StatCard";
import EarningsChart from "./EarningsChart";
import TopSellingProducts from "./TopSellingProducts";
import { useDashboardList } from "./dashboardSlice";
import { useGetDashboardMutation } from "./dashboardApi";
import { seperator } from "../../helperFunctions";
import StatCardCompact from "./StatCardCompact";
import delivery from "../../assets/images/delivery.svg";
import delivered from "../../assets/images/delivered.svg";
import confirmed from "../../assets/images/confirmed.svg";
import pendingIcon from "../../assets/images/pendingIcon.svg";
import declained from "../../assets/images/declined.svg";
import amount from "../../assets/images/amount.svg";
import orders from "../../assets/images/orders.svg";
import users from "../../assets/images/userss.svg";
import vendors from "../../assets/images/vendors.svg";
import { DropDown } from "../../ui";
import TopVendor from "./TopVendor";
import TopCustomers from "./TopCustomer";
import { useRole, useVendorId } from "../auth/authSlice";
import { useNavigate } from "react-router-dom";
import RecentOrder from "./RecentProduct";

const images: any = {
  new_delivery: delivery,
  total_orders: orders,
  total_user: users,
  total_vendor: vendors,
  total_amount: amount,
  pending_order: pendingIcon,
  approved_order: confirmed,
  today_delivery_orders: delivered,
  decline_order: declained,
};

const Dashboard = () => {
  const dashboardList = useDashboardList();
  const hasVendorId = useVendorId();
  const navigate = useNavigate();

  const [getDashboard, { isLoading: summaryLoading }] =
    useGetDashboardMutation();
  const [filter, setFilter] = useState({ vendor_id: null });
  const dashboardRole = useRole("dashboard");
  const vendorDetailsRole = useRole("vendor-details");

  const role = [...dashboardRole, ...vendorDetailsRole];

  if (!role?.includes?.("list")) {
    navigate("/");
  }

  const onGetDashboardSummay = async () => {
    const { vendor_id } = filter;
    const payload: any = { vendor_id };
    await getDashboard(payload);
  };

  useEffect(() => {
    if (role.includes("list")) {
      onGetDashboardSummay();
    }
    // eslint-disable-next-line
  }, [filter]);

  const prepareOptions = () => {
    const options = dashboardList?.data?.vendorsList
      ?.filter?.((pincode: any) => pincode?.name !== null)
      ?.slice?.()
      ?.sort((a: any, b: any) => a?.name?.localeCompare?.(b?.name))
      ?.map((val: any) => ({
        value: val?.id,
        label: val?.name,
      }));
    return options || [];
  };

  const onVendorChange = (value: any) => {
    setFilter({ vendor_id: value || null });
  };

  return (
    <div>
      {role.includes("list") ? (
        <>
          <div className="cardcss mb-4">
            <div className="flex justify-between mb-4">
              <h2 className="text-lg font-semibold ">Analytics</h2>
              {!hasVendorId?.id ? (
                <DropDown
                  isDefaultOption="Select Vendor"
                  options={prepareOptions()}
                  value={filter?.vendor_id}
                  className="min-w-[220px] max-w-[220px]"
                  onChange={(e: any) => {
                    onVendorChange(e);
                  }}
                />
              ) : null}
            </div>
            <div className="grid grid-cols-4 lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-1 gap-4">
              {Object.entries({
                // "New Delivery": "new_delivery",
                "Total Vendors": "total_vendor",
                "Total Customers": "total_user",
                "Today's Total Orders": "total_orders",
                "Total Amount": "total_amount",
              }).map(([heading, dataKey]) => (
                <StatCard
                  label={heading}
                  summaryLoading={summaryLoading}
                  value={
                    seperator(
                      dashboardList?.data?.[dataKey],
                      dataKey === "total_amount"
                    ) ?? "N/A"
                  }
                  icon={images?.[dataKey]}
                  dataKey={dataKey}
                  // bgColor="bg-blue-100"
                />
              ))}
              {Object.entries({
                Pending: "pending_order",
                Confirmed: "approved_order",
                "Today's Delivered": "today_delivery_orders",
                Declain: "decline_order",
              }).map(([heading, dataKey]) => (
                <StatCardCompact
                  label={heading}
                  summaryLoading={summaryLoading}
                  value={
                    seperator(dashboardList?.data?.[dataKey], false) ?? "N/A"
                  }
                  icon={images?.[dataKey]}
                  dataKey={dataKey}
                  valueColor="text-green-500"
                />
              ))}
            </div>
          </div>
          <div className="grid grid-cols-3 sm:grid-cols-1 md:grid-cols-1 mb-4 gap-4 sm:gap-x-0 md:gap-x-0">
            <div
              className={`col-span-2 sm:cols-span-1 md:cols-span-1 bg-white p-4 rounded-lg shadow-md`}
            >
              <EarningsChart filter={filter} />
            </div>

            {!hasVendorId?.id ? (
              <div className="h-full bg-white p-4 rounded-lg shadow-md">
                <TopVendor topVendors={dashboardList?.data?.top_vendor || []} />
              </div>
            ) : (
              <div className="h-full bg-white p-4 rounded-lg shadow-md">
                <RecentOrder
                  recentOrder={dashboardList?.data?.recent_order || []}
                />
              </div>
            )}
          </div>
          <div className="grid grid-cols-2 gap-4 sm:grid-cols-1">
            <div className="bg-white p-6 rounded-lg shadow-md">
              <TopSellingProducts
                topSellingProducts={
                  dashboardList?.data?.best_selling_products || []
                }
                summaryLoading={summaryLoading}
              />
            </div>
            <div className="bg-white p-6 rounded-lg shadow-md ">
              <TopCustomers
                topCustomer={dashboardList?.data?.top_customer || []}
                summaryLoading={summaryLoading}
              />
            </div>
          </div>
        </>
      ) : null}
    </div>
  );
};

export default Dashboard;
