import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Form } from "reactstrap";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Button, ControlledInput } from "../../ui";
import {
  useAddVideoMutation,
  useGetVideoByIdMutation,
  useUpdateVideoMutation,
} from "./videoApi";
import { responseToaster } from "../../helperFunctions";
import { IoIosCloseCircle } from "react-icons/io";
import ControlledTextArea from "../../ui/forms/ControlledTextArea";
import SingleImagePicker from "../../ui/forms/SingleImagePicker";

const VideoModal = (props: any) => {
  const { onHide, isOpen, onGet, videoData, currentTab } = props;
  const [addCpisLoading, setaddCpIsLoading] = useState<boolean>(false);
  const [isAdmin, setIsAdmin] = useState<any>(true);
  const [image, setImage] = useState<any>();
  const [image1, setImage1] = useState<any>();
  const [addVideo] = useAddVideoMutation();
  const [updateVideo] = useUpdateVideoMutation();
  const [getVideoById] = useGetVideoByIdMutation();

  const schema: any = yup.object({
    title: yup.string().required("Please enter a title"),
    description: yup.string().required("Please add description"),
    link: yup.string().url().required("Please add link"),
    photo: yup.string().required("Please add photo"),
  });
  const {
    control,
    handleSubmit,
    reset,
    setValue,
    watch,
    clearErrors,
    formState: { errors },
  }: any = useForm({
    resolver: yupResolver(schema),
  });

  const onClose = () => {
    reset();
    onHide();
    setImage("");
    setIsAdmin(true);
  };

  const getVideo = async () => {
    try {
      const payload: any = {
        id: videoData?.id,
      };
      const res: any = await getVideoById(payload);
      if (res?.data?.status) {
        setFormData(res?.data?.data);
      }
    } catch (error) {}
  };

  const setFormData = (videoData: any) => {
    if (videoData?.id) {
      setValue("title", videoData?.title);
      setValue("link", videoData?.link);
      setValue("description", videoData?.description);
      setValue("photo", videoData?.photo);
      if (videoData?.photo) {
        setImage(`${process.env.REACT_APP_BASE_IMAGE_URL}${videoData?.photo}`);
      }
    }
  };

  useEffect(() => {
    if (isOpen) {
      if (videoData?.id) {
        getVideo();
      }
    }
    // eslint-disable-next-line
  }, [isOpen, videoData?.id]);

  const onSubmit = async (values: any) => {
    try {
      setaddCpIsLoading(true);
      const formData: any = new FormData();
      formData.append("description", values.description);
      formData.append("link", values.link);
      formData.append("title", values.title);
      if (image1) {
        formData.append("photo", image1);
      }
      if (videoData?.id) {
        formData.append("id", videoData?.id);
      }

      const res: any = videoData?.id
        ? await updateVideo(formData).unwrap()
        : await addVideo(formData).unwrap();
      if (res?.status) {
        onClose();
        onGet?.();
      }
      responseToaster(res);
      setaddCpIsLoading(false);
    } catch (error) {
      setaddCpIsLoading(false);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center">
      <div
        className="modal-overlay absolute w-full h-full bg-gray-900 opacity-50"
        onClick={() => {
          if (!addCpisLoading) {
            onClose();
          }
        }}
      />

      <div className="modal-container bg-chatlook-white border w-8/12 2xl:w-6/12 xl:w-6/12 lg:w-8/12  md:w-9/12 sm:w-10/12  mx-auto rounded-lg shadow-lg z-50 overflow-y-auto">
        <div className="modal-content p-6 text-left  max-h-[84vh]">
          <Form onSubmit={handleSubmit(onSubmit)}>
            <div className="modal-header flex justify-between">
              <h4 className="modal-title mb-4 font-bold capitalize">
                {`${
                  videoData?.id
                    ? currentTab === "Vendor"
                      ? "View"
                      : "Update"
                    : "Add"
                }`}{" "}
                Video
              </h4>
              {!isAdmin ? (
                <div onClick={() => onClose()} className="cursor-pointer">
                  <IoIosCloseCircle color="var(--ev-primary--)" size={22} />
                </div>
              ) : null}
            </div>
            <div className="modal-body">
              <div className="grid md:grid-cols-2 lg:grid-cols-2 2xl:grid-cols-2 xl:grid-cols-2 sm:grid-cols-1 items-start gap-3 pt-2 pb-3">
                <ControlledInput
                  name="title"
                  label="Title"
                  placeholder="Enter Title"
                  type="text"
                  className="sm:col-span-2"
                  errors={errors}
                  control={control}
                />
                <ControlledInput
                  name="link"
                  label="Link"
                  placeholder="Enter Link"
                  type="text"
                  className="sm:col-span-2"
                  errors={errors}
                  control={control}
                />
                <SingleImagePicker
                  setImage={setImage}
                  setValue={setValue}
                  setImage1={setImage1}
                  clearErrors={clearErrors}
                  image={image}
                  errors={errors}
                  name={"photo"}
                  className={"col-span-2"}
                  watch={watch}
                  label={"Photo"}
                />
                <ControlledTextArea
                  name="description"
                  label="Description"
                  className="col-span-2 textareacss"
                  placeholder="Add Description..."
                  control={control}
                  errors={errors}
                />
              </div>
            </div>
            <div className="modal-footer pb-2">
              <div className="flex items-center justify-end gap-2">
                <Button
                  type="submit"
                  className="mt-[10px] !h-9 !text-sm"
                  isLoading={addCpisLoading}
                  isLoader={addCpisLoading}
                >
                  {videoData?.id ? "Update" : "Submit"}
                </Button>
                <Button
                  variant="Transaparent"
                  className="mt-[10px]  !text-sm !h-9"
                  disabled={addCpisLoading}
                  onClick={() => onClose()}
                >
                  Cancel
                </Button>
              </div>
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default VideoModal;
