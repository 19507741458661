import React, { Fragment, useEffect, useState } from "react";
import { FILTER } from "../../constant";
import { Button, Table, TableRow } from "../../ui";
import { setCurrentSettlement, useSettlementList } from "./settlementSlice";
import { useGetSettlementMutation } from "./settlementApi";
import { seperator } from "../../helperFunctions";
import { useDispatch } from "react-redux";
import { MdEdit } from "react-icons/md";
import SettlementModal from "./SettlementModal";
import Tooltip from "../../ui/elements/Tooltip";
import { useNavigate } from "react-router-dom";
import { useRole } from "../auth/authSlice";

const SettlementList = () => {
  const [getSettlement, { isLoading }] = useGetSettlementMutation();
  const settlementList = useSettlementList();
  const [tableData, setTableData] = useState<any>([]);
  // eslint-disable-next-line
  const [filter, setFilter] = useState(FILTER);
  const [settlementModal, setSettlementModal] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const settlementRole = useRole("settlement");
  const vendorDetailsRole = useRole("vendor-details");

  const role = [...settlementRole, ...vendorDetailsRole];

  if (!role.includes("list")) {
    navigate("/");
  }

  const columns = [
    {
      title: "ID",
      name: "vendorId",
      sortable: true,
      sortableKey: "vendorId",
    },
    {
      title: "Vendor Name",
      name: "vendorName",
      sortable: true,
      sortableKey: "vendor.name",
      Cell: (item: any) => {
        return <span>{item?.vendor?.name || "-"}</span>;
      },
    },
    {
      title: "Total Payment",
      name: "total_payment",
      sortable: true,
      sortableKey: "total_payment",
      Cell: (item: any) => {
        return <span>{seperator(item?.total_payment)}</span>;
      },
    },
    {
      title: "Shipping Charge",
      name: "shipping_charge",
      sortable: true,
      sortableKey: "shipping_charge",
      Cell: (item: any) => {
        return <span>{seperator(item?.shipping_charge)}</span>;
      },
    },
    {
      title: "Action",
      name: "action",
      showColumn: role.includes("edit"),
      Cell: (data: any) => {
        return (
          <div className="flex items-center gap-2">
            <Tooltip text="Edit">
              <Button
                className="!h-7 w-8 !p-0"
                onClick={() => {
                  dispatch(setCurrentSettlement(data));
                  setSettlementModal(true);
                }}
              >
                <MdEdit />
              </Button>
            </Tooltip>
          </div>
        );
      },
    },
  ];

  const getSettlementList = async () => {
    try {
      await getSettlement();
    } catch (error) {
      console.error("Error =-=>", error);
    }
  };

  useEffect(() => {
    setTableData(settlementList?.data?.length ? [...settlementList?.data] : []);
  }, [settlementList]);

  useEffect(() => {
    if(role.includes("list")){
      getSettlementList();
    }
    // eslint-disable-next-line
  }, [filter]);

  return (
    <>
      <Table
        columns={columns}
        isExpendable={false}
        isLoading={isLoading}
        data={tableData || []}
        setData={setTableData}
      >
        {tableData?.map((item: any, index: number) => {
          return (
            <Fragment key={index}>
              <TableRow
                columns={columns}
                isExpendable={false}
                item={item}
                onClick={(item: any) => {}}
              ></TableRow>
            </Fragment>
          );
        })}
      </Table>
      <SettlementModal
        isOpen={settlementModal}
        onGet={getSettlementList}
        //  Data={promotionData}
        onHide={() => {
          setSettlementModal(false);
          //  setPromotionData({});
        }}
      />
    </>
  );
};

export default SettlementList;
