import { Fragment, useEffect, useState } from "react";
import { FILTER } from "../../constant";
import { Button, Icon, Select, Table, TableRow } from "../../ui";
import { useTicketList } from "./ticketSlice";
import { useGetTicketMutation } from "./ticketApi";
import { responseToaster } from "../../helperFunctions";
import { useForm } from "react-hook-form";
import Tooltip from "../../ui/elements/Tooltip";
import { MdEdit, MdOutlineReply } from "react-icons/md";
import ReplyModal from "./ReplyModal";
import { useNavigate } from "react-router-dom";
import { useRole } from "../auth/authSlice";
import ReadMoreLess from "../common/ReadMoreLess";
import { useMedia } from "../../components";
import StatusStyle from "../common/StatusStyle";
import StatusModal from "./StatusModal";

export const ticketStatusOptions = [
  {
    value: "Pending",
    label: "Pending",
  },
  {
    value: "Running",
    label: "Running",
  },
  {
    value: "Solved",
    label: "Solved",
  },
];

const Ticket = () => {
  const [getTicket, { isLoading }] = useGetTicketMutation();
  const ticketList = useTicketList();

  const [tableData, setTableData] = useState<any>([]);
  const [isReplyModalOpen, setIsReplyModalOpen] = useState<any>(false);
  const [statusModal, setIsstatusModal] = useState<any>(false);
  const [replyData, setReplyData] = useState({});
  const [filter, setFilter] = useState<any>(FILTER);
  const [showDiv, setShowDiv] = useState(false);
  const { media } = useMedia();

  const navigate = useNavigate();
  // const role = useRole("ticket");

  const ticketRole = useRole("ticket");
  const vendorDetailsRole = useRole("vendor-details");

  const role = [...ticketRole, ...vendorDetailsRole];

  if (!role.includes("list")) {
    navigate("/");
  }
  const columns = [
    {
      title: "ID",
      name: "id",
      sortable: true,
      sortableKey: "id",
    },
    {
      title: "Ticket Id",
      name: "ticket_id",
      sortable: true,
      sortableKey: "ticket_id",
      Cell: (data: any) => {
        return <span>{data?.ticket_id || "-"}</span>;
      },
    },
    {
      title: "Title",
      name: "title",
      sortable: true,
      sortableKey: "title",
    },
    {
      title: "Vendor Name",
      name: "vendor_name",
      sortable: true,
      sortableKey: "vendor.name",
      Cell: (data: any) => {
        return <span>{data?.vendor?.name || "-"}</span>;
      },
    },

    {
      title: "Created",
      name: "created_at_ist",
      sortable: true,
      sortableKey: "created_at_ist",
    },
    {
      title: "Description",
      name: "description",
      sortable: true,
      sortableKey: "description",
      Cell: (data: any) => {
        return data?.description ? (
          <ReadMoreLess
            text={data?.description}
            maxLength={media < 768 ? 90 : 180}
            hasPre
          />
        ) : (
          "-"
        );
      },
    },
    {
      title: "Status",
      name: "status",
      Cell: (data: any) => {
        return (
          <div
            className="cursor-pointer"
            onClick={() => setIsstatusModal(data)}
          >
            <StatusStyle status={data?.status} />
            {/*  <UpdateStatus
              currKey={"status"}
              tableData={tableData}
              data={data}
              disable={!role.includes("edit")}
            /> */}
          </div>
        );
      },
      sortable: true,
      sortableKey: "status",
    },
    {
      title: "Action",
      name: "action",
      Cell: (item: any) => {
        return (
          <div className="flex items-center gap-2">
            <Tooltip text="Edit">
              <Button
                className="!h-8 !w-8 !p-0"
                onClick={(e: any) => {
                  e.stopPropagation();
                  navigate(
                    `/vendor/vendor/get-vendor/${item?.vendor?.id}?ticket`
                  );
                }}
                disabled={!role.includes("edit")}
              >
                <MdEdit />
              </Button>
            </Tooltip>
            <Tooltip text="Reply">
              <Button
                className="!h-7 w-8 !p-0"
                onClick={() => {
                  setIsReplyModalOpen(true);
                  setReplyData(item);
                }}
                disabled={!role.includes("edit")}
              >
                <MdOutlineReply />
              </Button>
            </Tooltip>
          </div>
        );
      },
    },
  ];

  const getTicketList = async () => {
    try {
      const { search, status, is_auto_approved, is_approved, ...rest } = filter;
      const payload: any = {
        ...rest,
        filter_data: {
          search,
          status,
          is_approved,
          is_auto_approved,
        },
      };
      delete payload["isFilter"];
      delete payload["isClear"];
      const res: any = await getTicket(payload);
      if (filter?.isFilter && !filter?.isClear) {
        responseToaster(res?.data);
      }
    } catch (error) {
      console.error("Error =-=>", error);
    }
  };

  const { register, handleSubmit, reset } = useForm<any>();

  useEffect(() => {
    setTableData(ticketList?.data?.length ? [...ticketList?.data] : []);
  }, [ticketList]);

  useEffect(() => {
    if (role.includes("list")) {
      getTicketList();
    }
    // eslint-disable-next-line
  }, [filter]);

  const onSubmit = async (values: any) => {
    setFilter({ ...FILTER, ...values, isFilter: true });
  };

  return (
    <>
      <div className="flex mb-3 gap-2 justify-end">
        <Button
          classNm={`!text-ev-white !border-0 appl-btn1`}
          onClick={() => {
            setShowDiv(!showDiv);
          }}
        >
          <Icon name="FilterIcon" />
        </Button>
      </div>
      {showDiv ? (
        <div className="cardcss mb-3 flex justify-between items-end">
          <form
            className="flex gap-2 items-end"
            onSubmit={handleSubmit(onSubmit)}
          >
            <Select
              name="status"
              label="Status"
              placeholder="All"
              options={ticketStatusOptions}
              register={register}
              className="w-10"
            />

            <Button type="submit" className="!text-sm">
              Apply
            </Button>
            <Button
              variant="Transaparent"
              className="!text-sm"
              onClick={() => {
                reset();
                setFilter({ ...FILTER, isFilter: true, isClear: true });
              }}
            >
              Clear
            </Button>
          </form>
        </div>
      ) : null}
      {role.includes("list") ? (
        <div className="cardcss">
          <Table
            className="ticketTable"
            columns={columns}
            isExpendable={false}
            isLoading={isLoading}
            data={tableData || []}
            setData={setTableData}
            count={ticketList?.total_item || 0}
            pagination={filter}
            handlePaginationChange={(pagination) => {
              setFilter({
                ...filter,
                ...pagination,
                isFilter: false,
              });
            }}
          >
            {tableData?.map((item: any, index: number) => {
              return (
                <Fragment key={index}>
                  <TableRow
                    columns={columns}
                    isExpendable={false}
                    item={item}
                    onClick={(item: any) => {}}
                  ></TableRow>
                </Fragment>
              );
            })}
          </Table>
        </div>
      ) : null}
      <ReplyModal
        isOpen={!!isReplyModalOpen}
        onHide={() => {
          setIsReplyModalOpen(false);
        }}
        replyData={replyData}
      />
      <StatusModal
        isOpen={statusModal}
        onHide={() => {
          setIsstatusModal(false);
        }}
        onGet={() => setFilter({ ...filter })}
      />
    </>
  );
};

export default Ticket;
